import { MeasureView } from '../../atoms/MeasureView';
import BaseLayout from '../../molecules/BaseLayout';
import { TitleStyleProps } from '../../molecules/TitleRow';
import DragShape, { DragShapeWithState } from '../representations/DragShape';
import ItemsAgainstRuler from '../representations/Measurement/ItemsAgainstRuler';
import { useContext, useMemo } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { SvgName, getSvgInfo } from '../../../assets/svg';
import { CompleteTheSentenceWithState } from '../../molecules/CompleteTheSentence';
import { isEqual } from '../../../utils/matchers';
import UserInput, { ExtraSymbols } from '../../molecules/UserInput';
import { StyleProp, TextStyle } from 'react-native';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle?: string;
  rulerKind: 'cm' | 'mm';
  rulerLength: number;
  extraSymbol?: ExtraSymbols;
  shapeIconName: SvgName;
  /** Where the shape should begin along the ruler */
  offsetX?: number;
  offsetY?: number;
  /** Where the shape should begin (PDF-specific) */
  pdfOffsetX?: number;
  /** Length of the shape */
  length: number;
  sentence: string;
  sentenceStyle?: StyleProp<TextStyle>;
  /** Can provide an array of correct answers, or a function for finer control. */
  testCorrect: string[] | ((userAnswer: string[]) => boolean);
  /** Optional custom mark scheme answer */
  markSchemeAnswer?: string[];
  /**
   * Expands the clamp of the draggable shape to be off end of the ruler by 0.5 * Length of Shape in pixels.
   * Allows shapes like hexagons to be aligned with the 0cm line on the ruler.
   */
  expandClamp?: boolean;
};

export default function QF42bDraggableShapeOnRuler({
  title,
  pdfTitle,
  extraSymbol,
  rulerKind,
  rulerLength,
  shapeIconName,
  offsetX = 0,
  offsetY = 0,
  length,
  sentence,
  sentenceStyle,
  testCorrect: testCorrectProp,
  markSchemeAnswer,
  expandClamp,
  pdfOffsetX
}: Props) {
  const displayMode = useContext(DisplayMode);
  const iconAspectRatio = getSvgInfo(shapeIconName).aspectRatio;

  // Handle testCorrect
  const testCorrect = useMemo(
    () => (typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <ItemsAgainstRuler
                  width={dimens.width}
                  height={dimens.height}
                  rulerKind={rulerKind}
                  rulerLength={rulerLength}
                  hideMillimetres
                  items={[
                    {
                      length: length,
                      imageInfo: (pixelLength, pixelsPerUnit) => {
                        return {
                          crossAxisLength: pixelLength / iconAspectRatio,
                          image: (
                            <DragShape
                              shapeIconName={shapeIconName}
                              lengthInPixels={pixelLength}
                              offset={{
                                x: (pdfOffsetX ?? 0) * pixelsPerUnit,
                                y: 0
                              }}
                              maxOffset={rulerLength * pixelsPerUnit - pixelLength}
                              heightInPixels={pixelLength / iconAspectRatio}
                            />
                          )
                        };
                      }
                    }
                  ]}
                />
              )}
            </MeasureView>
            <CompleteTheSentenceWithState
              sentence={sentence ?? ''}
              id={'sentence'}
              testCorrect={testCorrect}
              style={[sentenceStyle, { paddingTop: 32 }]}
              defaultState={
                displayMode === 'markscheme' && markSchemeAnswer ? markSchemeAnswer : undefined
              }
            />
          </>
        }
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="endWide"
      actionPanelContents={<UserInput inputType="numpad" extraSymbol={extraSymbol} />}
      mainPanelContents={
        <>
          <MeasureView>
            {dimens => (
              <ItemsAgainstRuler
                width={dimens.width}
                height={dimens.height}
                rulerKind={rulerKind}
                rulerLength={rulerLength}
                hideMillimetres
                items={[
                  {
                    length: length,
                    imageInfo: (pixelLength, pixelsPerUnit) => ({
                      crossAxisLength: pixelLength / iconAspectRatio + 150,
                      image: (
                        <DragShapeWithState
                          shapeIconName={shapeIconName}
                          lengthInPixels={pixelLength}
                          id={'shape'}
                          maxOffset={rulerLength * pixelsPerUnit - pixelLength}
                          expandClamp={expandClamp}
                          heightInPixels={pixelLength / iconAspectRatio}
                          defaultState={{
                            x: offsetX * pixelsPerUnit,
                            y: offsetY * 20
                          }}
                        />
                      )
                    })
                  }
                ]}
              />
            )}
          </MeasureView>
          <CompleteTheSentenceWithState
            sentence={sentence ?? ''}
            id={'sentence'}
            testCorrect={testCorrect}
            style={[sentenceStyle, { paddingTop: 32 }]}
          />
        </>
      }
    />
  );
}
