import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  BlockBarChart,
  BlockBarChartWithState
} from '../../../../components/question/representations/Coordinates/BlockBarChart';
import { z } from 'zod';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { barColorsNamesArray, barColorNames, barColorsNamesSchema } from '../../../../theme/colors';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  countRange,
  filledArray
} from '../../../../utils/collections';
import { petSchema, getRandomUniquePets, petAsWord } from '../../../../utils/pets';
import {
  rejectionSample,
  randomIntegerInclusive,
  getRandomFromArray,
  seededRandom,
  getRandomSubArrayFromArray,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import TableWithLeftHeaders from '../../../../components/question/representations/TableWithLeftHeaders';
import { colors as themeColors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bli',
  description: 'bli',
  keywords: ['Block diagram', 'Least', 'Most'],
  schema: z.object({
    pets: petSchema.array().length(4).refine(arrayHasNoDuplicates, 'Must be 4 unique pets'),
    data: z
      .number()
      .int()
      .min(0)
      .max(7)
      .array()
      .length(4)
      .refine(data => {
        const min = Math.min(...data);
        const max = Math.max(...data);
        return (
          data.filter(it => it === min).length === 1 && data.filter(it => it === max).length === 1
        );
      }, 'Min and max must be unique'),
    lookingForMostOrLeast: z.enum(['most', 'least'])
  }),
  simpleGenerator: () => {
    const pets = getRandomUniquePets(4);

    const data = rejectionSample(
      () => countRange(4).map(() => randomIntegerInclusive(1, 7)),
      data => {
        const min = Math.min(...data);
        const max = Math.max(...data);
        return (
          data.filter(it => it === min).length === 1 && data.filter(it => it === max).length === 1
        );
      }
    );

    return { pets, data, lookingForMostOrLeast: getRandomFromArray(['most', 'least'] as const) };
  },
  Component: ({ question: { pets, data, lookingForMostOrLeast }, translate }) => {
    const random = seededRandom({ pets, data });
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 4, { random });
    const min = Math.min(...data);
    const max = Math.max(...data);
    const leastPopular = pets.filter((_, i) => data[i] === min)[0];
    const mostPopular = pets.filter((_, i) => data[i] === max)[0];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.ks1Instructions[
          lookingForMostOrLeast === 'most' ? 'selectThePopularPetMost' : 'selectThePopularPetLeast'
        ]()}
        pdfTitle={translate.ks1PDFInstructions[
          lookingForMostOrLeast === 'most' ? 'tickThePopularPetMost' : 'tickThePopularPetLeast'
        ]()}
        selectables={Object.fromEntries(pets.map(key => [key, petAsWord(key, translate)]))}
        correctAnswer={[lookingForMostOrLeast === 'most' ? mostPopular : (leastPopular as string)]}
        leftContent={
          <MeasureView>
            {dimens => (
              <BlockBarChart
                dimens={dimens}
                userAnswer={data.map(col => [
                  ...filledArray(true, col),
                  ...filledArray(false, 7 - col)
                ])}
                columnProps={pets.map((pet, index) => ({
                  color: barColorNames[colors[index]],
                  label: petAsWord(pet, translate)
                }))}
                yAxisLabel={translate.misc.numberOfChildren()}
                xAxisLabel={translate.misc.typeOfPet()}
                numberOfRows={7}
                numberOfCols={data.length}
                yAxisLabels={countRange(8).map(num => num.toLocaleString())}
              />
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'blj',
  description: 'blj',
  keywords: ['Block diagram', 'More', 'Fewer', 'Total', 'Altogether'],
  schema: z
    .object({
      pets: petSchema.array().length(5).refine(arrayHasNoDuplicates, 'Must be 5 unique pets'),
      data: z.number().int().min(0).max(7).array().length(5),
      answerIndexA: numberEnum([0, 1, 2, 3, 4]),
      answerIndexB: numberEnum([0, 1, 2, 3, 4]),
      fewerOrMore: z.enum(['Fewer', 'More']),
      multiplier: numberEnum([2, 5, 10]),
      variation: numberEnum([1, 2, 3])
    })
    .refine(
      val => val.data[val.answerIndexA] !== val.data[val.answerIndexB],
      'values of childen that have pets used in question must not equal the same'
    ),
  simpleGenerator: () => {
    const pets = getRandomUniquePets(5);
    const [answerIndexA, answerIndexB] = getRandomSubArrayFromArray([0, 1, 2, 3, 4] as const, 2);
    const fewerOrMore = getRandomFromArray(['Fewer', 'More'] as const);
    const multiplier = getRandomFromArray([2, 5, 10] as const);
    const variation = getRandomFromArray([1, 2, 3] as const);

    const data = rejectionSample(
      () => countRange(5).map(i => randomIntegerInclusive(i === 2 ? 0 : 1, 7)),
      data => {
        return data[answerIndexA] !== data[answerIndexB];
      }
    );

    return { pets, data, answerIndexA, answerIndexB, fewerOrMore, multiplier, variation };
  },
  Component: props => {
    const {
      question: { pets, data, answerIndexA, answerIndexB, fewerOrMore, multiplier, variation },
      translate
    } = props;

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 5, {
      random: seededRandom(props.question)
    });

    const [petA, petB] =
      data[answerIndexA] > data[answerIndexB]
        ? [petAsWord(pets[answerIndexA], translate), petAsWord(pets[answerIndexB], translate)]
        : [petAsWord(pets[answerIndexB], translate), petAsWord(pets[answerIndexA], translate)];

    const answer =
      variation === 1
        ? data[answerIndexA]
        : variation === 2
        ? data[answerIndexA] > data[answerIndexB]
          ? data[answerIndexA] - data[answerIndexB]
          : data[answerIndexB] - data[answerIndexA]
        : // Variation 3
          data[answerIndexA] * multiplier + data[answerIndexB] * multiplier;

    return (
      <QF1ContentAndSentence
        title={
          variation === 1
            ? translate.ks1Instructions.howManyChildrenHaveAX(
                petAsWord(pets[answerIndexA], translate)
              )
            : variation === 2
            ? fewerOrMore === 'Fewer'
              ? translate.ks1Instructions.howManyFewerChildrenHaveAXThanAY(petB, petA)
              : translate.ks1Instructions.howManyMoreChildrenHaveAXThanAY(petA, petB)
            : // Variation 3
              translate.ks1Instructions.howManyChildrenHaveAXorAY(
                petAsWord(pets[answerIndexA], translate),
                petAsWord(pets[answerIndexB], translate)
              )
        }
        testCorrect={[answer.toString()]}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <BlockBarChart
            dimens={dimens}
            userAnswer={data.map(col => [
              ...filledArray(true, col),
              ...filledArray(false, 7 - col)
            ])}
            columnProps={pets.map((pet, index) => ({
              color: barColorNames[colors[index]],
              label: petAsWord(pet, translate)
            }))}
            yAxisLabel={translate.misc.numberOfChildren()}
            xAxisLabel={translate.misc.typeOfPet()}
            numberOfRows={7}
            numberOfCols={data.length}
            yAxisLabels={
              variation === 3
                ? countRange(8).map(num => (num * multiplier).toLocaleString())
                : countRange(8).map(num => num.toLocaleString())
            }
          />
        )}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'blk',
  description: 'blk',
  keywords: ['Block diagram'],
  schema: z
    .object({
      maxNumberOfPets: z.number().int().min(5).max(7),
      pets: petSchema.array().length(4).refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
      colors: barColorsNamesSchema
        .array()
        .length(4)
        .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
      data: z.number().int().min(1).max(7).array().length(4),
      preFilledColumns: z.array(z.number().int().min(0).max(3))
    })
    .refine(
      ({ maxNumberOfPets, data }) => data.every(it => it <= maxNumberOfPets),
      'all entris in the data must be <= maxNumberOfPets'
    ),
  simpleGenerator: () => {
    const maxNumberOfPets = randomIntegerInclusive(5, 7);
    const pets = getRandomUniquePets(4);
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 4);
    const data = countRange(4).map(() => randomIntegerInclusive(1, maxNumberOfPets));
    const preFilledAmount = randomIntegerInclusive(1, 3);
    const preFilledColumns = randomUniqueIntegersInclusive(0, 3, preFilledAmount);

    return { maxNumberOfPets, colors, data, pets, preFilledColumns };
  },
  Component: ({
    question: { maxNumberOfPets, colors, data, pets, preFilledColumns },
    translate,
    displayMode
  }) => {
    const answer = data.map(col => [
      ...filledArray(true, col),
      ...filledArray(false, maxNumberOfPets - col)
    ]);

    return (
      <QF3Content
        inputType="numpad"
        questionHeight={1000}
        title={translate.ks1Instructions.tapToCompleteTheBlockDiagram()}
        pdfTitle={translate.ks1PDFInstructions.completeTheBlockDiagram()}
        Content={({ dimens }) => (
          <>
            <TableWithLeftHeaders
              headers={[translate.misc.pet(), translate.misc.numberOfPets()]}
              items={[
                pets.map(name => petAsWord(name, translate)),
                data.map(it => it.toLocaleString())
              ]}
              style={{
                width: '100%',
                alignSelf: 'center',
                marginBottom: displayMode === 'digital' ? 20 : 60
              }}
              headerCellStyle={{ backgroundColor: themeColors.tableHeaderBackground }}
              textStyle={displayMode === 'digital' && { fontSize: 21.667, lineHeight: 35 }}
            />
            <BlockBarChartWithState
              dimens={dimens}
              columnProps={pets.map((pet, index) => ({
                color: barColorNames[colors[index]],
                label: petAsWord(pet, translate)
              }))}
              yAxisLabel={translate.misc.numberOfPets()}
              xAxisLabel={translate.misc.pet()}
              numberOfRows={maxNumberOfPets}
              numberOfCols={data.length}
              yAxisLabels={countRange(maxNumberOfPets + 1).map(num => num.toLocaleString())}
              id={'pets block bar chart'}
              nonInteractiveIndices={preFilledColumns}
              gridWidth={dimens.width * 0.8}
              gridHeight={dimens.height * 0.8}
              defaultState={
                displayMode === 'markscheme'
                  ? answer
                  : data.map((col, idx) => [
                      ...(preFilledColumns.includes(idx)
                        ? filledArray(true, col)
                        : filledArray(false, col)),
                      ...filledArray(false, maxNumberOfPets - col)
                    ])
              }
              testCorrect={userAnswer => {
                return filledArray(0, userAnswer.length).every((_, index) =>
                  arraysHaveSameContents(userAnswer[index], answer[index])
                );
              }}
            />
          </>
        )}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'BlockDiagrams',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
