import { Pressable, View } from 'react-native';
import { SetState } from 'common/src/utils/react';
import { Dimens } from 'common/src/theme/scaling';
import Table from 'common/src/components/molecules/Table';
import { useCallback } from 'react';
import { colors } from '../../../theme/colors';
import { withStateHOC } from '../../../stateTree';
import Svg, { Polygon } from 'react-native-svg';

type Shape = 'Circle' | 'Square' | 'Rectangle' | 'Triangle' | 'RATriangle';

type Props = {
  /**
   * The user answer, i.e. which cells are highlighted.
   */
  userAnswer?: { shape?: Shape; value: boolean }[][];
  /**
   * Set user answer
   */
  setUserAnswer?: SetState<{ shape?: Shape; value: boolean }[][]>;
  /**
   * Dimensions
   */
  dimens: Dimens;
  /**
   * Array of shape objects to display the shape also allows for rotation and scale to randomize the question.
   */
  shapesArray: { shape: Shape; rotation?: number; scale?: number }[];
  /**
   * Array of shape objects or false. This is to allow the randomization of shapes.
   */
  arrangement: (
    | false
    | {
        shape: Shape;
        rotation: number;
        scale: number;
      }
  )[][];
  pdfCircleAnswer?: boolean;
};

const SelectRandomShapes = ({
  userAnswer,
  setUserAnswer,
  dimens,
  shapesArray,
  arrangement,
  pdfCircleAnswer = false
}: Props) => {
  const handleOnPress = useCallback(
    (rowIdx: number, colIdx: number) => {
      if (setUserAnswer) {
        setUserAnswer(userAnswer => {
          return userAnswer.map((row, rowIndex) =>
            rowIndex !== rowIdx
              ? row
              : row.map((item, columnIndex) =>
                  columnIndex !== colIdx ? item : { value: !item.value, shape: item.shape }
                )
          );
        });
      }
    },
    [setUserAnswer]
  );

  const numberOfRows = arrangement.length;
  const numberOfCols = shapesArray.length / numberOfRows;

  // Firstly, calculate various layout dimensions.
  const tableWidth = dimens.width;
  const tableHeight = dimens.height;
  const shapeDimens = Math.min(tableHeight / numberOfRows, tableWidth / numberOfCols) * 0.5;

  // Now return a table
  return (
    <Table
      style={{ width: tableWidth, height: tableHeight, rowGap: pdfCircleAnswer ? 110 : 30 }}
      rowStyle={{ flex: 1, columnGap: 30 }} // Rows share the table height
      cellStyle={{ flex: 1 }} // Cells share the row width
      borderWidthAmount={0}
      items={arrangement.map((row, rowIndex) =>
        row.map((cell, colIndex) =>
          cell && userAnswer ? (
            <Pressable
              key={`${rowIndex}-${colIndex}`}
              style={{
                alignSelf: 'stretch',
                alignItems: 'center',
                gap: 30
              }}
              onPress={() => handleOnPress(rowIndex, colIndex)}
            >
              <View
                style={
                  pdfCircleAnswer && userAnswer[rowIndex][colIndex].value
                    ? {
                        borderColor: 'black',
                        borderWidth: 2,
                        padding: 50,
                        borderRadius: 200,
                        width: 320,
                        height: 320,
                        alignItems: 'center',
                        justifyContent: 'center'
                      }
                    : {}
                }
              >
                {cell.shape === 'Circle' ? (
                  <View
                    style={{
                      backgroundColor: userAnswer[rowIndex][colIndex].value
                        ? colors.burntSienna
                        : 'white',
                      borderColor: 'black',
                      borderWidth: 3,
                      width: shapeDimens * cell.scale,
                      height: shapeDimens * cell.scale,
                      borderRadius: 200
                    }}
                  />
                ) : cell.shape === 'Rectangle' ? (
                  <View
                    style={{
                      backgroundColor: userAnswer[rowIndex][colIndex].value
                        ? colors.burntSienna
                        : 'white',
                      borderColor: 'black',
                      borderWidth: 3,
                      width: shapeDimens * 0.5 * cell.scale,
                      height: shapeDimens * cell.scale,
                      transform: [
                        {
                          rotate: `${cell.rotation}deg`
                        }
                      ]
                    }}
                  />
                ) : cell.shape === 'Triangle' ? (
                  <Svg
                    width={shapeDimens * cell.scale}
                    height={shapeDimens * cell.scale}
                    style={{
                      transform: [
                        {
                          rotate: `${cell.rotation}deg`
                        }
                      ]
                    }}
                  >
                    <Polygon
                      points={`${(shapeDimens * cell.scale) / 2},0 ${shapeDimens * cell.scale},${
                        shapeDimens * cell.scale
                      } 0,${shapeDimens * cell.scale}`}
                      fill={userAnswer[rowIndex][colIndex].value ? colors.burntSienna : 'white'}
                      stroke="black"
                      strokeWidth="3"
                    />
                  </Svg>
                ) : cell.shape === 'RATriangle' ? (
                  <Svg
                    width={shapeDimens * cell.scale}
                    height={shapeDimens * cell.scale}
                    style={{
                      transform: [
                        {
                          rotate: `${cell.rotation}deg`
                        }
                      ]
                    }}
                  >
                    <Polygon
                      points={`0,0 ${shapeDimens * cell.scale},0 0,${shapeDimens * cell.scale}`}
                      fill={userAnswer[rowIndex][colIndex].value ? colors.burntSienna : 'white'}
                      stroke="black"
                      strokeWidth="3"
                    />
                  </Svg>
                ) : (
                  <View
                    style={{
                      backgroundColor: userAnswer[rowIndex][colIndex].value
                        ? colors.burntSienna
                        : 'white',
                      borderColor: 'black',
                      borderWidth: 3,
                      width: shapeDimens * cell.scale,
                      height: shapeDimens * cell.scale,
                      transform: [
                        {
                          rotate: `${cell.rotation}deg`
                        }
                      ]
                    }}
                  />
                )}
              </View>
            </Pressable>
          ) : (
            <></>
          )
        )
      )}
    />
  );
};

export const SelectRandomShapesWithState = withStateHOC(SelectRandomShapes, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',

  defaults: props => {
    const arrangementWithShapes = props.arrangement.map(row => {
      return row.map(cell => {
        if (cell) {
          return { shape: cell.shape, value: false };
        }
        return { value: false };
      });
    });
    return {
      defaultState: arrangementWithShapes
    };
  }
});
