import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { ScientificNotation } from '../../../../utils/math';
import QF15CreateBaseTenNumber from '../../../../components/question/questionFormats/QF15CreateBaseTenNumber';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { Dimens } from '../../../../theme/scaling';
import { countRange, filledArray } from '../../../../utils/collections';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bda',
  description: 'bda',
  keywords: ['Tens and ones'],
  schema: z.object({
    numberOfOnes: z.number().int().min(1).max(9),
    numberOfTens: z.number().int().min(2).max(4),
    subQuestion: z.discriminatedUnion('variant', [
      z.object({
        variant: z.enum(['Straws', 'tenFrame']),
        isJumbled: z.boolean(),
        randomSeed: z.number().int().min(1).max(1000)
      }),
      z.object({
        variant: z.literal('Cubes'),
        onesPosition: z.enum(['bottom', 'leftBottom', 'leftTop', 'top'])
      }),
      z.object({
        variant: z.literal('rekenrek')
      })
    ])
  }),
  simpleGenerator: () => {
    const numberOfOnes = randomIntegerInclusive(1, 9);
    const numberOfTens = randomIntegerInclusive(2, 4);
    const variant = getRandomFromArray(['Straws', 'Cubes', 'rekenrek', 'tenFrame'] as const);

    const isJumbled = getRandomFromArrayWithWeights([false, true], [3, 1]);

    const randomSeed = randomIntegerInclusive(1, 100);

    const onesPosition = getRandomFromArray(['bottom', 'leftBottom', 'leftTop', 'top'] as const);

    const subQuestion =
      variant === 'Cubes'
        ? { variant, onesPosition }
        : variant === 'rekenrek'
        ? { variant }
        : { variant, isJumbled, randomSeed };

    return {
      numberOfOnes,
      numberOfTens,
      subQuestion
    };
  },
  Component: props => {
    const {
      question: { numberOfOnes, numberOfTens, subQuestion },
      translate
    } = props;

    const { variant } = subQuestion;

    const total = numberOfTens * 10 + numberOfOnes;

    const getBase10Representations = (dimens: Dimens) => {
      if (subQuestion.variant === 'Straws' && subQuestion.isJumbled) {
        const scale = BaseTenRepCalcGridsAndScale(
          dimens.width,
          dimens.height,
          { ones: numberOfOnes, tens: numberOfTens },
          'Straws'
        ).scale;

        const total = numberOfTens + numberOfOnes;

        const onesBase = countRange(numberOfOnes).map((_, index) => (
          <BaseTenRepresentation
            key={`ones_${index}`}
            b10Rep={{
              variant: 'Straws',
              numbers: { ones: 1 },
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width / total}
            usableHeight={dimens.height}
            align="center"
            scale={scale}
          />
        ));

        const tensBase = countRange(numberOfTens).map((_, index) => (
          <BaseTenRepresentation
            key={`tens${index}`}
            b10Rep={{
              variant: 'Straws',
              numbers: { tens: 1 },
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width / total}
            usableHeight={dimens.height}
            align="center"
            scale={scale}
          />
        ));

        const reps = shuffle([...onesBase, ...tensBase], {
          random: seededRandom(subQuestion.randomSeed)
        });

        return (
          <View>
            <View
              style={{
                flexDirection: 'row',
                height: dimens.height,
                columnGap: 16,
                alignSelf: 'center'
              }}
            >
              {reps}
            </View>
          </View>
        );
      } else
        return (
          <BaseTenRepresentation
            b10Rep={{
              variant: 'Straws',
              numbers: { tens: numberOfTens, ones: numberOfOnes },
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        );
    };

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSentences()}
        Content={({ dimens }) =>
          variant === 'rekenrek' ? (
            <Rekenrek dimens={dimens} rows={10} numberShown={total} />
          ) : variant === 'Straws' ? (
            getBase10Representations(dimens)
          ) : variant === 'Cubes' ? (
            <SimpleBaseTenWithCrossOut
              dimens={dimens}
              tens={numberOfTens}
              ones={numberOfOnes}
              onesPosition={subQuestion.onesPosition}
            />
          ) : (
            <View style={{ flexDirection: 'row', gap: 20 }}>
              {subQuestion.isJumbled
                ? shuffle(
                    countRange(Math.ceil(total / 10)).map(i => (
                      <TenFrameLayout
                        items={filledArray('red', i === Math.floor(total / 10) ? total % 10 : 10)}
                        orientation="vertical"
                        size="xsmall"
                        key={i}
                      />
                    )),
                    { random: seededRandom(subQuestion.randomSeed) }
                  )
                : countRange(Math.ceil(total / 10)).map(i => (
                    <TenFrameLayout
                      items={filledArray('red', i === Math.floor(total / 10) ? total % 10 : 10)}
                      orientation="vertical"
                      size="xsmall"
                      key={i}
                    />
                  ))}
            </View>
          )
        }
        sentence={translate.ks1AnswerSentences.thereAreAnsTensAndAnsOnesTheNumberIsAns(
          numberOfTens,
          numberOfOnes
        )}
        testCorrect={[numberOfTens.toString(), numberOfOnes.toString(), total.toString()]}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bdb',
  description: 'bdb',
  keywords: ['Tens and ones', 'Base 10'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(number => number % 10 !== 0)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });

    return { number };
  },

  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF15CreateBaseTenNumber
        title={translate.ks1Instructions.dragTheBase10ToMakeTheNumberX(number)}
        pdfTitle={translate.ks1PDFInstructions.drawBase10ToShowTheNumberX(number)}
        number={ScientificNotation.fromNumber(number)}
        draggablesToShow={[1, 0]}
        questionHeight={900}
        showBase10ToUsePdf={false}
        customMarkSchemeAnswer={translate.markScheme.acceptTheBase10BlocksInAnyOrder()}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bdc',
  description: 'bdc',
  keywords: ['Tens and ones'],
  schema: z
    .object({
      numberOfTens: z.number().int().min(2).max(5),
      numberOfOnes: z.number().int().min(0).max(9),
      isTensFirst: z.boolean()
    })
    .refine(val => val.numberOfTens * 10 + val.numberOfOnes <= 50),
  questionHeight: 500,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(20, 50);

    const numberOfTens = Math.floor(number / 10);
    const numberOfOnes = number % 10;

    const isTensFirst = getRandomFromArrayWithWeights([true, false], [4, 1]);

    return { numberOfTens, numberOfOnes, isTensFirst };
  },
  Component: props => {
    const {
      question: { numberOfTens, numberOfOnes, isTensFirst },
      translate,
      displayMode
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={
          isTensFirst
            ? translate.ks1AnswerSentences.ansHasNumTensAndNumOnes(numberOfTens, numberOfOnes)
            : translate.ks1AnswerSentences.ansHasNumOnesAndNumTens(numberOfOnes, numberOfTens)
        }
        testCorrect={[(numberOfTens * 10 + numberOfOnes).toString()]}
        mainPanelContainerStyle={{ alignItems: 'flex-start' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        questionHeight={500}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'GroupsOfTensAndOnes',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
