import { StyleProp, ViewStyle, TextStyle } from 'react-native';
import { ElementOrRenderFunction, SetState } from '../../utils/react';
import { RequireKeys } from '../../utils/types';
import DragAndDropSource from './DragAndDropSource';
import DragAndDropZoneSingle from './DragAndDropZoneSingle';
import { Theme } from '../../theme';
import { ComponentProps, createContext, ReactNode, useContext, useRef } from 'react';
import { noop } from '../../utils/flowControl';
import { withStateHOC } from '../../stateTree';
import { DraggableVariant, getValuesTransformer } from './utils';
import DragAndDropZoneMultiple from './DragAndDropZoneMultiple';
import { readonlyArrayEntry } from '../../utils/collections';

type Config<DragValue> = {
  /**
   * The items to use for this drag and drop. Should not change. Wherever the component is given as strings, the text
   * is controlled by the text props below.
   */
  items: { component: string | JSX.Element; value: DragValue }[];

  /**
   * Whether each draggable should be moved from its starting location (one-to-one), or copied (many-to-one).
   * Default: move
   */
  moveOrCopy?: 'move' | 'copy';
  /** Defaults to 'draganddrop'. Used by StateTree and also for auto-scaling. */
  id?: string;

  /** Style variant to use as a basis for this drag and drop. Affects all draggables. Default: square. */
  variant?: DraggableVariant;
  hideBackground?: boolean;
  /** Additional style to override all draggables. */
  draggableStyle?: StyleProp<ViewStyle>;

  ////
  // Props for styling text, for all items where `component` is a a string. Affects all draggables.
  ////

  /** Text variant to base the text off of. */
  textVariant?: keyof Theme['fonts'];
  /** Additional text style to apply. */
  textStyle?: StyleProp<TextStyle>;
  /**
   * (Text auto-scaling.) Whether to auto scale all text (fontSize and lineHeight) to fit its containers.
   * - true (default): auto-scale all draggables together, using the id as a group.
   * - false: don't auto-scale
   * - [number]: auto-scale text to the size that would fit a string of this length (useful for ensuring consistent
   *   scaling with another component).
   * - [string]: alternative auto-scale group (useful for ensuring consistent scaling with another component)
   */
  textAutoScale?: boolean | number | string;
  /**
   * (Text auto-scaling.) Tune the text scaling. How much of an "em" is the average character in your text.
   * (Should be <1, smaller numbers means text gets scaled to be larger.)
   */
  textLetterEmWidth?: number;
  /** Max lines. (Affects text auto-scaling calculations too.) Default: 2. */
  maxLines?: number;
};

/**
 * User answer used in EasyDragAndDrop questions.
 * This is fundamentally `number[][]`, where the outer array lists the drop zones, and each inner array is the drop
 * zone's contents. Each number is the ID of the draggable item, which corresponds to its index in the "items" array,
 * which is passed into the provider.
 *
 * Note 1: Some users of EasyDragAndDrop not provide a default to the user answer state, in which case it becomes [].
 * This means that `state[0]` returns `undefined` instead of `number[]`, so we add `undefined` to the type
 * in order to prompt us to catch this case.
 *
 * Note 2: If state is `[]` and someone drags item 7 into drop zone 2, then the new state is `[absent, absent, [7]]`.
 *
 * Note 3: When the user answer state is serialized and then deserialized (with JSON.stringify and JSON.parse), any
 * `undefined` or absent elements are converted to `null`. Hence we add `null` to the type too.
 */
export type EasyDragAndDropUserAnswer = Array<Array<number> | undefined | null>;

const EasyDragAndDropContext = createContext<
  {
    state: EasyDragAndDropUserAnswer;
    setState: SetState<EasyDragAndDropUserAnswer>;
  } & RequireKeys<Config<unknown>, 'moveOrCopy' | 'id' | 'variant' | 'textAutoScale'>
>({
  state: [],
  setState: noop,
  items: [],
  variant: 'square',
  moveOrCopy: 'move',
  id: 'draganddrop',
  textAutoScale: true,
  hideBackground: false
});

/** Context provider to link together {@link Source} and {@link ZoneSingle}. */
function Provider<DragValue>({
  state,
  setState = noop,
  children,
  items,
  moveOrCopy = 'move',
  id = 'draganddrop',
  variant = 'square',
  draggableStyle,
  hideBackground = false,
  textVariant,
  textStyle,
  textAutoScale = true,
  textLetterEmWidth,
  maxLines = 2
}: {
  state: EasyDragAndDropUserAnswer;
  setState?: SetState<EasyDragAndDropUserAnswer>;
  children: ReactNode;
} & Config<DragValue>) {
  return (
    <EasyDragAndDropContext.Provider
      value={{
        state,
        setState,
        items,
        variant,
        textVariant,
        moveOrCopy,
        id,
        hideBackground,
        draggableStyle,
        textStyle,
        textAutoScale,
        textLetterEmWidth,
        maxLines
      }}
    >
      {children}
    </EasyDragAndDropContext.Provider>
  );
}

/** Like {@link Provider}, but easier to use within a `StateTree`. */
const ProviderWithStateWithIds = withStateHOC(Provider, {
  // This defaultState catches the case of when the Provider is being used in a PDF where we don't bother to pass a default state.
  // Otherwise, you _should_ pass a default state.
  defaults: { defaultState: [] }
});

/**
 * Like {@link Provider}, but easier to use within a `StateTree`.
 *
 * The testComplete, testCorrect and defaultState props have been modified to accept the draggable values, rather
 * than the IDs of the draggables.
 */
function ProviderWithState<DragValue>({
  testCorrect: testCorrectProp,
  testComplete: testCompleteProp,
  defaultState: defaultStateProp,
  ...props
}: Omit<
  ComponentProps<typeof ProviderWithStateWithIds>,
  'testCorrect' | 'testComplete' | 'defaultState'
> & {
  testCorrect?: (state: DragValue[][]) => boolean;
  testComplete?: (state: DragValue[][]) => boolean;
  defaultState?: DragValue[][];
}) {
  const valuesTransformer = getValuesTransformer(
    props.items as { component: string | JSX.Element; value: DragValue }[],
    props.moveOrCopy ?? 'move'
  );

  const testCorrect =
    testCorrectProp === undefined
      ? undefined
      : (state: EasyDragAndDropUserAnswer) => testCorrectProp(valuesTransformer.transform(state));
  const testComplete =
    testCompleteProp === undefined
      ? undefined
      : (state: EasyDragAndDropUserAnswer) => testCompleteProp(valuesTransformer.transform(state));
  const defaultState =
    defaultStateProp === undefined ? undefined : valuesTransformer.untransform(defaultStateProp);

  return (
    <ProviderWithStateWithIds
      testCorrect={testCorrect}
      testComplete={testComplete}
      defaultState={defaultState}
      {...props}
    />
  );
}

/** When dragging from a drop zone, a draggable carries its drop zone's index as its payload. */
type Payload =
  | undefined
  | {
      from: {
        /** Drop zone the draggable came from. */
        id: number;
        /** Index in that drop zone the draggable came from. */
        index: number;
      };
    };

/** Where each draggable starts. */
function Source({
  id,
  style,
  widthOverride
}: {
  id: number;
  style?: StyleProp<ViewStyle>;
  widthOverride?: number;
}) {
  const { state, items, variant, moveOrCopy, textAutoScale, hideBackground, ...context } =
    useContext(EasyDragAndDropContext);

  const item = items[id]!;

  // Use scaling config from context
  let textSizeAutoScaleGroup: string | undefined = undefined;
  let textScaleToLongestLength: number | undefined = undefined;
  if (textAutoScale === true) {
    textSizeAutoScaleGroup = context.id;
  } else if (typeof textAutoScale === 'string') {
    textSizeAutoScaleGroup = textAutoScale;
  } else if (typeof textAutoScale === 'number') {
    textScaleToLongestLength = textAutoScale;
  }

  return (
    <DragAndDropSource<Payload>
      payload={undefined}
      id={id}
      variant={variant}
      style={style}
      widthOverride={widthOverride}
      hideBackground={hideBackground}
      moveOrCopy={moveOrCopy}
      draggableIsPresent={
        moveOrCopy === 'copy' || !state.some(container => container?.includes(id))
      }
      channel={context.id}
      draggableStyle={context.draggableStyle}
      textVariant={context.textVariant}
      textStyle={context.textStyle}
      textSizeAutoScaleGroup={textSizeAutoScaleGroup}
      textScaleToLongestLength={textScaleToLongestLength}
      textLetterEmWidth={context.textLetterEmWidth}
      maxLines={context.maxLines}
    >
      {item.component}
    </DragAndDropSource>
  );
}

/** A drop zone for a draggable. */
function ZoneSingle({
  id,
  placeholder,
  style
}: {
  id: number;
  placeholder?: ElementOrRenderFunction;
  style?: StyleProp<ViewStyle>;
}) {
  const { state, setState, items, variant, textAutoScale, ...context } =
    useContext(EasyDragAndDropContext);

  const draggable = (state[id] ?? [])[0] as number | undefined;

  // Use scaling config from context
  let textSizeAutoScaleGroup: string | undefined = undefined;
  let textScaleToLongestLength: number | undefined = undefined;
  if (textAutoScale === true) {
    textSizeAutoScaleGroup = context.id;
  } else if (typeof textAutoScale === 'string') {
    textSizeAutoScaleGroup = textAutoScale;
  } else if (typeof textAutoScale === 'number') {
    textScaleToLongestLength = textAutoScale;
  }

  return (
    <DragAndDropZoneSingle<Payload>
      variant={variant}
      placeholder={placeholder}
      style={style}
      draggable={
        draggable === undefined
          ? undefined
          : {
              payload: { from: { id, index: 0 } },
              id: draggable,
              element: items[draggable]!.component
            }
      }
      onDropInto={({ draggable: newDraggable }) => {
        setState(state => {
          // 1. At the index of this droppable, put the new one in
          state = readonlyArrayEntry(state, id, () => [newDraggable.id as number]);

          const from = (newDraggable.payload as Payload)?.from;
          if (from !== undefined) {
            // 2. At the index the draggable came from (if any), , *swap* the current contents (if any) to that one
            state = readonlyArrayEntry(state, from.id, dropZone =>
              readonlyArrayEntry(dropZone ?? [], from.index, () => draggable)
            );
          }

          return state;
        });
      }}
      onDraggedToNowhere={() => {
        if (draggable !== undefined) {
          // Draggable was dropped nowhere in particular.
          setState(state => readonlyArrayEntry(state, id, () => []));
        }
      }}
      channel={context.id}
      draggableStyle={context.draggableStyle}
      textVariant={context.textVariant}
      textStyle={context.textStyle}
      textSizeAutoScaleGroup={textSizeAutoScaleGroup}
      textScaleToLongestLength={textScaleToLongestLength}
      textLetterEmWidth={context.textLetterEmWidth}
      maxLines={context.maxLines}
    />
  );
}

/** A drop zone for several draggables. */
function ZoneMultiple({
  id,
  capacity = 9,
  style,
  droppedStyle,
  wrapperStyle,
  children
}: {
  id: number;
  /** How many draggables it can hold before dragging a new one in starts kicking out an old one. */
  capacity?: number;
  style?: StyleProp<ViewStyle>;
  droppedStyle?: StyleProp<ViewStyle>;
  /** Style for the draggable container. */
  wrapperStyle?: StyleProp<ViewStyle>;
  /** Other, non-interactive items to go before the interactive ones. */
  children?: ReactNode;
}) {
  const { state, setState, items, variant, textAutoScale, ...context } =
    useContext(EasyDragAndDropContext);

  // Some items may have the same ID, as they're from the same draggable. However, we need to give them different
  // keys and track those keys, in order for the animations to look right.
  // We use a lazy way to get locally-unique keys at runtime - just increment a value forever. We're never going be
  // running long enough to hit the floating point limit.
  const currentKey = useRef<number>(0);
  const itemKeys = useRef<number[]>((state[id] ?? []).map(_ => currentKey.current++));

  // Use scaling config from context
  let textSizeAutoScaleGroup: string | undefined = undefined;
  let textScaleToLongestLength: number | undefined = undefined;
  if (textAutoScale === true) {
    textSizeAutoScaleGroup = context.id;
  } else if (typeof textAutoScale === 'string') {
    textSizeAutoScaleGroup = textAutoScale;
  } else if (typeof textAutoScale === 'number') {
    textScaleToLongestLength = textAutoScale;
  }

  return (
    <DragAndDropZoneMultiple<Payload>
      variant={variant}
      style={style}
      draggables={(state[id] ?? []).map((draggableId, index) => ({
        payload: { from: { id, index } },
        id: draggableId,
        key: itemKeys.current[index],
        element: items[draggableId]!.component
      }))}
      channel={context.id}
      onDropInto={({ draggable: newDraggable }) => {
        const from = (newDraggable.payload as Payload)?.from;

        // New draggable was dropped into this container
        setState(state => {
          // Add new draggable to this container
          state = readonlyArrayEntry(state, id, dropZone => {
            dropZone = dropZone ?? [];
            let index;
            if (dropZone.length === capacity) {
              // Was full. New draggable replaces last one.
              index = dropZone.length - 1;
            } else {
              // Was not full. New draggable goes on the end.
              index = dropZone.length;
            }
            itemKeys.current[index] = currentKey.current++;
            return readonlyArrayEntry(dropZone, index, () => newDraggable.id as number);
          });

          if (from !== undefined) {
            // Remove new draggable from its previous container
            state = readonlyArrayEntry(state, from.id, dropZone =>
              readonlyArrayEntry(dropZone ?? [], from.index, () => undefined)
            );
          }

          return state;
        });
      }}
      onDraggedToNowhere={({ draggable: movedDraggable }) => {
        // Draggable was dropped nowhere in particular.
        const from = (movedDraggable.payload as Payload)?.from;

        setState(state => {
          if (from !== undefined) {
            // Remove new draggable from its previous container
            itemKeys.current.splice(from.index, 1);
            state = readonlyArrayEntry(state, id, dropZone =>
              readonlyArrayEntry(dropZone ?? [], from.index, () => undefined)
            );
          }
          return state;
        });
      }}
      draggableStyle={[context.draggableStyle, droppedStyle]}
      wrapperStyle={wrapperStyle}
      textVariant={context.textVariant}
      textStyle={context.textStyle}
      textSizeAutoScaleGroup={textSizeAutoScaleGroup}
      textScaleToLongestLength={textScaleToLongestLength}
      textLetterEmWidth={context.textLetterEmWidth}
      maxLines={context.maxLines}
    >
      {children}
    </DragAndDropZoneMultiple>
  );
}

/**
 * This is an extremely specialized pair of drag and drop components, specifically for question formats like
 * QF4, QF5, QF6, QF35, QF36 and QF37, and possibly more in the future.
 *
 * ## What
 *
 * This is simply a convenient way to use {@link DragAndDropSource} and {@link DragAndDropZoneSingle}. Those
 * components both still require you to provide the logic of what happens as items are dragged around, and you need
 * to update your underlying state yourself. These components do this for you.
 *
 * In exchange, these components assume a few things:
 *
 * - There is an array of all draggable items, which doesn't change. Position in that array gives each draggable a
 * unique ID
 * - The userAnswer state is an array of draggable ID (the index of the above array). You may need to map your user
 * answer to this shape with e.g. `transformSetState`.
 * - Dragging an item from one drop zone to another should swap the drop zone's contents.
 *
 * ## Usage
 *
 * Wrap everything in the {@link Provider} or {@link ProviderWithState} in order for {@link Source} and
 * {@link ZoneSingle} to work properly.
 *
 * {@link ProviderWithState} is easier to use, and must have a `StateTree` as an ancestor (this will always be the
 * case when used in questions). The state will be managed in the state tree under the id provided. The easiest way
 * to use this is to provide the `defaultState`, `testComplete` and `testCorrect` props.
 *
 * {@link Provider} can be used instead, but this requires you to manage the state elsewhere.
 *
 * Can be used for many-to-one (copy) or one-to-one (move) via the moveOrCopy prop.
 */
export default {
  ProviderWithState,
  Provider,
  Source,
  ZoneSingle,
  ZoneMultiple
};
