import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomBooleanArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom
} from '../../../../utils/random';
import QF67SelectingRandomlyArrangedShapes from '../../../../components/question/questionFormats/QF67SelectingRandomlyArrangedShapes';
import { filledArray } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bkg',
  description: 'bkg',
  keywords: ['Whole', 'Half', 'Group'],
  schema: z.object({
    numberOfCircles: z.number().int().min(2).max(10).step(2),
    randomSeed: z.number().int().min(1).max(1000),
    isRandom: z.boolean()
  }),
  simpleGenerator: () => {
    const numberOfCircles = randomIntegerInclusiveStep(2, 10, 2);
    const randomSeed = randomIntegerInclusive(1, 1000);
    const isRandom = getRandomBoolean();

    return { numberOfCircles, randomSeed, isRandom };
  },
  Component: props => {
    const {
      question: { numberOfCircles, randomSeed, isRandom },
      translate
    } = props;

    const randomArrangement = getRandomBooleanArray(
      4,
      4,
      numberOfCircles,
      seededRandom({ randomSeed })
    );

    return (
      <QF67SelectingRandomlyArrangedShapes
        title={translate.ks1Instructions.tapToShadeHalfOfTheCircles()}
        pdfTitle={translate.ks1PDFInstructions.shadeHalfOfTheCircles()}
        shapesArray={filledArray({ shape: 'Circle' }, numberOfCircles)}
        testCorrect={filledArray('Circle', numberOfCircles / 2)}
        arrangement={
          isRandom ? randomArrangement : filledArray(filledArray(true, numberOfCircles / 2), 2)
        }
        customMarkSchemeAnswer={{ answerText: translate.markScheme.acceptAnyValidAnswer() }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAHalf',
  questionTypes: [Question1],
  unpublishedQuestionTypes: [Question1]
});
export default SmallStep;
