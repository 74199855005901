import { Pressable } from 'react-native';
import { colors } from '../../../../theme/colors';
import { useCallback } from 'react';
import { withStateHOC } from '../../../../stateTree';
import { filledArray } from '../../../../utils/collections';
import { SetState } from '../../../../utils/react';
import { Dimens } from '../../../../theme/scaling';
import Grid from './Grid';

export type Props = {
  dimens: Dimens;
  /**
   * Number of rows
   */
  numberOfRows: number;
  /**
   * Number of columns per row
   */
  numberOfCols: number;
  /**
   * The user answer, i.e. which cells are shaded in.
   */
  userAnswer: boolean[][];
  /**
   * Set user answer
   */
  setUserAnswer?: SetState<boolean[][]>;
  /**
   * The label and colour of each column
   */
  columnProps: { color: string; label: string }[];
  /**
   * The labels that along the Y Axis
   */
  yAxisLabels: string[];
  /**
   * The main label on the Y Axis
   */
  yAxisLabel: string;
  /**
   * The main label on the X Axis
   */
  xAxisLabel: string;
  /**
   * A list of indices that shouldn't be interactive. Useful for when some columns need to be pre-filled and non-interactive.
   */
  nonInteractiveIndices?: number[];
  gridWidth?: number;
  gridHeight?: number;
};

export const BlockBarChart = ({
  dimens,
  numberOfRows,
  numberOfCols,
  userAnswer,
  setUserAnswer,
  columnProps,
  yAxisLabels,
  xAxisLabel,
  yAxisLabel,
  nonInteractiveIndices,
  gridWidth = dimens.width,
  gridHeight = dimens.height
}: Props) => {
  const isInteractive = setUserAnswer !== undefined;

  if (numberOfRows === 0) {
    throw Error('Number of rows must be greater than 0');
  }

  if (numberOfCols === 0) {
    throw Error('Number of columns must be greater than 0');
  }

  const handleOnPress = useCallback(
    (colIdx: number, rowIdx: number) => {
      if (setUserAnswer) {
        const updatedUserAnswer = userAnswer.map((column, index) =>
          index !== colIdx ? column : column.map((value, idx) => (idx === rowIdx ? !value : value))
        );
        setUserAnswer(updatedUserAnswer);
      }
    },
    [setUserAnswer, userAnswer]
  );

  const gridLineWidth = 2;

  return (
    <Grid
      width={gridWidth}
      height={gridHeight}
      xMax={numberOfCols}
      yMax={numberOfRows}
      xLabels={columnProps.map(column => column.label)}
      yLabels={yAxisLabels}
      xAxisLabel={xAxisLabel}
      yAxisLabel={yAxisLabel}
      xAxisArrowLabel={null}
      yAxisArrowLabel={null}
      xLabelCenteredInMiddle
      hideContinuationLines
      gridLineWidth={gridLineWidth}
    >
      {({ mathToSvgX, mathToSvgY, axisLineWidth }) => {
        const cellWidth = mathToSvgX(1) - mathToSvgX(0);
        const cellHeight = mathToSvgY(0) - mathToSvgY(1);
        return userAnswer.map((column, colIdx) =>
          column.map((cell, rowIdx) => (
            <Pressable
              key={rowIdx}
              onPress={() => handleOnPress(colIdx, rowIdx)}
              disabled={
                !isInteractive || (nonInteractiveIndices && nonInteractiveIndices.includes(colIdx))
              }
              style={{
                width:
                  colIdx === 0
                    ? cellWidth - axisLineWidth / 2 - gridLineWidth / 2
                    : cellWidth - gridLineWidth,
                height:
                  rowIdx === 0
                    ? cellHeight - axisLineWidth / 2 - gridLineWidth / 2
                    : cellHeight - gridLineWidth,
                backgroundColor: cell
                  ? columnProps[colIdx % columnProps.length].color
                  : colors.white,
                position: 'absolute',
                top:
                  rowIdx === 0
                    ? mathToSvgY(rowIdx + 1) + gridLineWidth / 2
                    : mathToSvgY(rowIdx + 1) + gridLineWidth / 2,
                left:
                  colIdx === 0
                    ? mathToSvgX(colIdx) + axisLineWidth / 2
                    : mathToSvgX(colIdx) + gridLineWidth / 2
              }}
            />
          ))
        );
      }}
    </Grid>
  );
};

export const BlockBarChartWithState = withStateHOC(BlockBarChart, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: filledArray(filledArray(false, props.numberOfRows), props.numberOfCols)
  })
});
