import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBooleanArray,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { countRange, filledArray } from '../../../../utils/collections';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'baj',
  description: 'baj',
  keywords: ['Ten frames', 'Match', 'Represent'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(2).max(10)).length(3),
      icons: z.array(
        z.object({
          key: z.string(),
          number: z.number().int().min(2).max(10),
          value: z.enum([
            'Sweets/SweetBlue',
            'Sweets/SweetGreen',
            'Sweets/SweetPink',
            'Sweets/SweetRed',
            'Car',
            'Array_objects/AppleRed',
            'Array_objects/Banana',
            'Array_objects/Orange'
          ])
        })
      ),
      counterColor: z.enum(['red', 'yellow', 'blue', 'green', 'grey']),
      itemOrdering: z.enum(['row', 'column']),
      tenFrameOrPictures: z.enum(['ten frame', 'pictures'])
    })
    .refine(
      val => val.numbers.every(num => num % 2 === 0 || num === 5),
      'numbers array should always return even numbers unless one of the numbers is a 5'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(2, 10, 3, {
      constraint: x => x % 2 === 0 || x === 5
    });

    const icons = shuffle([
      {
        key: 'sweets',
        value: getRandomFromArray([
          'Sweets/SweetBlue',
          'Sweets/SweetGreen',
          'Sweets/SweetPink',
          'Sweets/SweetRed'
        ] as const),
        number: numbers[0]
      },
      {
        key: 'car',
        value: 'Car' as const,
        number: numbers[1]
      },
      {
        key: 'fruits',
        value: getRandomFromArray([
          'Array_objects/AppleRed',
          'Array_objects/Banana',
          'Array_objects/Orange'
        ] as const),
        number: numbers[2]
      }
    ]);

    const counterColor = getRandomFromArray(['red', 'yellow', 'blue', 'green', 'grey'] as const);
    const itemOrdering = getRandomFromArray(['row', 'column'] as const);

    const tenFrameOrPictures = getRandomFromArray(['ten frame', 'pictures'] as const);

    return {
      numbers: numbers,
      icons: icons,
      counterColor,
      itemOrdering,
      tenFrameOrPictures
    };
  },
  Component: props => {
    const {
      question: { numbers, icons, counterColor, itemOrdering, tenFrameOrPictures },
      translate,
      displayMode
    } = props;

    const statements =
      tenFrameOrPictures === 'pictures'
        ? icons.map(icon => {
            return {
              lhsComponent: (
                <View
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: 16,
                    width: displayMode === 'digital' ? 400 : 600
                  }}
                >
                  {countRange(icon.number).map(idx => (
                    <AssetSvg
                      key={idx}
                      name={icon.value}
                      width={displayMode === 'digital' ? 60 : 80}
                      height={displayMode === 'digital' ? 60 : 80}
                    />
                  ))}
                </View>
              ),
              correctAnswer: icon.number
            };
          })
        : numbers.map(number => {
            return {
              lhsComponent: (
                <TenFrameLayout
                  items={filledArray(counterColor, number)}
                  itemOrdering={itemOrdering === 'row' ? 'rowFirst' : 'columnFirst'}
                  size="xsmall"
                />
              ),
              correctAnswer: number
            };
          });

    const items =
      tenFrameOrPictures === 'ten frame'
        ? icons.map(icon => {
            return {
              component: (
                <View
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    paddingLeft: 12,
                    gap: 8
                  }}
                >
                  {countRange(icon.number).map(idx => (
                    <AssetSvg key={idx} name={icon.value} width={50} />
                  ))}
                </View>
              ),
              value: icon.number
            };
          })
        : numbers.map(number => {
            return {
              component: (
                <TenFrameLayout
                  items={filledArray(counterColor, number)}
                  itemOrdering={itemOrdering === 'row' ? 'rowFirst' : 'columnFirst'}
                  size="xsmall"
                />
              ),
              value: number
            };
          });

    return (
      <QF6DragMatchStatements
        title={
          tenFrameOrPictures === 'pictures'
            ? translate.ks1Instructions.dragCardsToMatchTheXObjectToTheYObject(
                translate.ks1MiscStrings['ten frames'](),
                translate.ks1MiscStrings.pictures()
              )
            : translate.ks1Instructions.dragCardsToMatchTheXObjectToTheYObject(
                translate.ks1MiscStrings.pictures(),
                translate.ks1MiscStrings['ten frames']()
              )
        }
        pdfTitle={
          tenFrameOrPictures === 'pictures'
            ? translate.ks1PDFInstructions.matchTheXObjectToTheYObject(
                translate.ks1MiscStrings['ten frames'](),
                translate.ks1MiscStrings.pictures()
              )
            : translate.ks1PDFInstructions.matchTheXObjectToTheYObject(
                translate.ks1MiscStrings.pictures(),
                translate.ks1MiscStrings['ten frames']()
              )
        }
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        mainPanelStyle={{ rowGap: 16 }}
        itemVariant="tallRectangle"
        items={items}
        actionPanelVariant="endWide"
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bak',
  description: 'bak',
  keywords: ['Counters', 'Ten frame'],
  schema: z
    .object({
      animal: z.enum(['Sheep', 'Horse', 'Cow']),
      answer: z.number().int().min(3).max(10),
      incorrectA: z.number().int().min(2).max(10),
      arrangementSeed: z.number().int().min(1).max(1000)
    })
    .refine(
      ({ answer, incorrectA }) => answer !== incorrectA,
      'Answer and incorrectA cannot be the same'
    ),
  simpleGenerator: () => {
    const animal = getRandomFromArray(['Sheep', 'Horse', 'Cow'] as const);
    const arrangementSeed = randomIntegerInclusive(1, 1000);
    const answer = randomIntegerInclusive(3, 10);
    const incorrectA = answer === 10 ? 9 : getRandomFromArray([answer - 1, answer + 1]);
    return { animal, answer, incorrectA, arrangementSeed };
  },
  Component: ({
    question: { animal, answer, incorrectA, arrangementSeed },
    translate,
    displayMode
  }) => {
    // TODO add pigs, goats, cats, and dogs when we have the artwork
    const animalSvgPaths = {
      Sheep: 'Sheep' as const,
      Horse: 'Horse' as const,
      Cow: 'Cow' as const
    };

    const rows = 2;
    const cols = 6;

    const random = seededRandom({ arrangementSeed });
    const arrangementA = getRandomBooleanArray(rows, cols, answer, random);

    const items = shuffle(
      [
        {
          value: answer,
          component: (
            <TenFrameLayout
              items={filledArray('red', answer)}
              size={displayMode === 'digital' ? 'small' : 'large'}
            />
          )
        },
        {
          value: incorrectA,
          component: (
            <TenFrameLayout
              items={filledArray('red', incorrectA)}
              size={displayMode === 'digital' ? 'small' : 'large'}
            />
          )
        }
      ],
      { random }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.selectTheTenFrameThatMatchesThePicture()}
        pdfTitle={translate.ks1PDFInstructions.whichTenFrameMatchesThePictureSelectYourAnswer()}
        testCorrect={[answer]}
        numItems={2}
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            {arrangementA.map((row, rowIndex) => (
              <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                {row.map((cell, colIndex) => (
                  <View
                    key={`cell-${rowIndex}-${colIndex}`}
                    style={{
                      width: dimens.width / cols,
                      height: dimens.height / rows,
                      padding: 8
                    }}
                  >
                    {cell ? (
                      <AssetSvg
                        name={animalSvgPaths[animal]}
                        height={dimens.height / rows - 16}
                        width={dimens.width / cols - 16}
                      />
                    ) : (
                      <></>
                    )}
                  </View>
                ))}
              </View>
            ))}
          </View>
        )}
        renderItems={items}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RepresentObjects',
  questionTypes: [Question1, Question2],
  unpublishedQuestionTypes: [Question1, Question2]
});
export default SmallStep;
