import { View, type StyleProp, type ViewStyle, StyleSheet } from 'react-native';
import { Circle, Path, Svg } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../../../utils/react';
import { GridContext, type GridContextType } from './Grid';
import { countRange } from '../../../../utils/collections';
import Text from '../../../typography/Text';
import { useContext } from 'react';
import { DisplayMode } from '../../../../contexts/displayMode';

const DEFAULT_DOT_RADIUS = 12.24;

type SquareDottedPaperProps = {
  ////
  // Provide exactly two of these props.
  ////
  dimens?: { width: number; height: number };
  mathDimens?: { xLength: number; yLength: number };
  cellSize?: number;

  /** Default: {@link DEFAULT_DOT_RADIUS} */
  dotRadius?: number;
  /** Default: {@link colors.pdfShading} */
  dotColor?: string;
  cellSizeLabel?: string;
  style?: StyleProp<ViewStyle>;

  /**
   * Components to place within the dotty paper's bounds.
   *
   * Children can access useful functions (like mathToSvgX) by either:
   *
   * - Provide the children as a function that returns a JSX Element. The argument of this function contains the
   *   functions and numbers.
   *
   * - In the children's components, they have access to the {@link GridContext} context, via {@link useContext}.
   *
   * Note: SVG subcomponents (e.g. Rect, Path) should be placed in the {@link GridSvgChildren} wrapper.
   * Note: This component re-uses the {@link GridContext} from `Grid` despite not calling into `Grid`.
   */
  children?: ElementOrRenderFunction<GridContextType>;
};

export default function SquareDottedPaper({
  dimens: dimensProp,
  mathDimens: mathDimensProp,
  cellSize: cellSizeProp,
  dotRadius = DEFAULT_DOT_RADIUS,
  dotColor = colors.pdfShading,
  cellSizeLabel,
  style,
  children
}: SquareDottedPaperProps) {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  let mathDimens: { xLength: number; yLength: number };
  let cellSize: number;

  if (dimensProp !== undefined && mathDimensProp !== undefined && cellSizeProp === undefined) {
    // cellSize must ensure that 2*dotRadius + mathDimens.xLength * cellSize <= dimens.width, and same for y
    const xCellSize = (dimensProp.width - 2 * dotRadius) / mathDimensProp.xLength;
    const yCellSize = (dimensProp.height - 2 * dotRadius) / mathDimensProp.yLength;

    cellSize = Math.min(xCellSize, yCellSize);
    mathDimens = mathDimensProp;
  } else if (
    dimensProp !== undefined &&
    mathDimensProp === undefined &&
    cellSizeProp !== undefined
  ) {
    cellSize = cellSizeProp;
    mathDimens = {
      xLength: Math.floor((dimensProp.width - 2 * dotRadius) / cellSize),
      yLength: Math.floor((dimensProp.height - 2 * dotRadius) / cellSize)
    };
  } else if (
    dimensProp === undefined &&
    mathDimensProp !== undefined &&
    cellSizeProp !== undefined
  ) {
    cellSize = cellSizeProp;
    mathDimens = mathDimensProp;
  } else {
    throw new Error('Must provide exactly 2 of the following props: dimens, mathDimens, cellSize');
  }

  // dimens is the dimensions available, whereas this is the dimensions we actually use
  const actualDimens: { width: number; height: number } = {
    width: mathDimens.xLength * cellSize + 2 * dotRadius,
    height: mathDimens.yLength * cellSize + 2 * dotRadius
  };

  const { width: svgWidth, height: svgHeight } = actualDimens;
  const context: GridContextType = {
    mathToSvgX: x => {
      'worklet';
      return dotRadius + x * cellSize!;
    },
    mathToSvgY: y => {
      'worklet';
      return svgHeight - dotRadius - y * cellSize!;
    },
    svgToMathX: xMapped => {
      'worklet';
      return (xMapped - dotRadius) / cellSize!;
    },
    svgToMathY: yMapped => {
      'worklet';
      return (svgHeight - yMapped + -dotRadius) / cellSize!;
    },
    xMin: 0,
    xMax: mathDimens.xLength,
    xStepSize: 1,
    yMin: 0,
    yMax: mathDimens.yLength,
    yStepSize: 1,
    svgWidth,
    svgHeight,
    gridLineWidth: 1,
    axisLineWidth: 2
  };

  return (
    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
      <View style={[actualDimens, style]} pointerEvents="box-none">
        {cellSizeLabel && (
          <View
            style={{
              alignSelf: 'flex-end',
              alignItems: 'flex-end',
              right: dotRadius,
              top: dotRadius * -4
            }}
          >
            <Text
              style={{
                fontSize: displayMode === 'digital' ? 20 : 40,
                alignSelf: 'center',
                lineHeight: displayMode === 'digital' ? 20 : 40
              }}
            >
              {cellSizeLabel}
            </Text>
            <Svg width={cellSize} height={20}>
              <Path d={'M0,10 L10,5 L10,15 Z'} fill={isPdf ? colors.black : colors.prussianBlue} />
              <Path
                d={`M5,10 L${cellSize - 5},10`}
                stroke={isPdf ? colors.black : colors.prussianBlue}
                strokeWidth={isPdf ? 4 : 2}
              />
              <Path
                d={`M${cellSize},10
                  L${cellSize - 10},5
                  L${cellSize - 10},15
                  Z`}
                fill={isPdf ? colors.black : colors.prussianBlue}
              />
            </Svg>
          </View>
        )}
        <Svg
          width={svgWidth}
          height={svgHeight}
          viewBox={`0 0 ${svgWidth} ${svgHeight}`}
          style={styles.svg}
          pointerEvents="box-none"
        >
          {countRange(mathDimens!.xLength + 1).map(x =>
            countRange(mathDimens!.yLength + 1).map(y => (
              <Circle
                key={`${x},${y}`}
                cx={context.mathToSvgX(x)}
                cy={context.mathToSvgY(y)}
                r={dotRadius}
                fill={dotColor}
              />
            ))
          )}
        </Svg>
        <View style={StyleSheet.absoluteFill} pointerEvents="box-none">
          <GridContext.Provider value={context}>
            {resolveElementOrRenderFunction(children, context)}
          </GridContext.Provider>
        </View>
      </View>
      {cellSizeLabel && (
        <View
          style={{
            flexDirection: 'row',
            alignSelf: 'flex-start',
            right: isPdf ? dotRadius * -4 : dotRadius * -0.75,
            top: isPdf ? dotRadius * 13 : dotRadius
          }}
        >
          <Svg width={20} height={cellSize}>
            <Path d={'M10,0 L5,10 L15,10 Z'} fill={isPdf ? colors.black : colors.prussianBlue} />
            <Path
              d={`M10,5 L10,${cellSize - 5}`}
              stroke={isPdf ? colors.black : colors.prussianBlue}
              strokeWidth={isPdf ? 4 : 2}
            />
            <Path
              d={`M10,${cellSize}
                  L5,${cellSize - 10}
                  L15,${cellSize - 10}
                  Z`}
              fill={isPdf ? colors.black : colors.prussianBlue}
            />
          </Svg>
          <Text
            style={{
              fontSize: displayMode === 'digital' ? 20 : 40,
              alignSelf: 'center',
              lineHeight: displayMode === 'digital' ? 20 : 40
            }}
          >
            {cellSizeLabel}
          </Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  svg: { flexShrink: 0 }
});
