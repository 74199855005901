import { View, StyleSheet, Pressable } from 'react-native';
import { Dimens } from '../../../../theme/scaling';
import { countRange } from '../../../../utils/collections';
import { BaseTenImages } from './BaseTenImages';
import { projectSetState, type SetState } from 'common/src/utils/react';
import { Line, Svg } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import { withStateHOC } from '../../../../stateTree';
import { noop } from '../../../../utils/flowControl';
import { useContext } from 'react';
import { DisplayMode } from '../../../../contexts/displayMode';

type Props = {
  /**
   * Number of tens to show. Default 0
   */
  tens?: number;
  /**
   * Number of ones to show. Default 0
   */
  ones?: number;
  /**
   * Default crossed out values
   */
  crossedOutIndices?: { tens: number[]; ones: number[] };
  /**
   * When provided the blocks will be interactive
   */
  setCrossedOutIndices?: SetState<{ tens: number[]; ones: number[] }>;
  /**
   * For interactive, this will be overridden.
   * If no value provided for none interactive, then this will default to BaseTenRepCalcGridsAndScale.
   * This works well for most values but not well for numbers less than 10
   */
  scale?: number;
  /**
   * Rotate the representation (can be extended in future if required to varying degrees)
   */
  rotate?: '90deg' | '180deg' | '270deg';
  dimens: Dimens;
  /** Position to render the ones inside the main container. Default: 'bottom' */
  onesPosition?: 'top' | 'bottom' | 'leftTop' | 'leftBottom';
};

/**
 * Representation showing base 10 cubes up to tens with the ability to cross through cubes.
 * The ones are organised in columns of 5
 */
export function SimpleBaseTenWithCrossOut({
  tens = 0,
  ones = 0,
  crossedOutIndices = { tens: [], ones: [] },
  setCrossedOutIndices,
  scale,
  dimens,
  rotate,
  onesPosition = 'bottom'
}: Props) {
  const isInteractive = setCrossedOutIndices !== undefined;

  const onesAlignment = onesPosition.includes('top') ? 'flex-start' : 'flex-end';
  const styles = getStyles(dimens, isInteractive, onesAlignment, rotate);

  const onesObject = BaseTenImages['Cubes'].ONES;
  const tensObject = BaseTenImages['Cubes'].TENS;

  const layoutDef = Scale(dimens.width, dimens.height, { ones, tens });

  const imageScale = scale !== undefined ? scale : layoutDef;

  const interactiveSpan = 271 / 4.5;

  // When interactive we have fixed values as per figma. Otherwise we scale as per the base 10 scale
  const scaledOnesWidth = isInteractive ? 27 : Math.floor(onesObject.width * imageScale);
  const scaledTensWidth = isInteractive ? 70 : Math.floor(tensObject.width * imageScale);
  const scaledOnesHeight = isInteractive ? 27 : Math.floor(onesObject.height * imageScale);
  const scaledTensHeight = isInteractive ? 271 : Math.floor(tensObject.height * imageScale);

  const onesMargin = isInteractive ? 0 : (scaledTensHeight - (scaledOnesHeight + 4) * 5) / 4;

  const onesRows = ones === 0 ? 0 : Math.ceil(ones / 5);

  const displayMode = useContext(DisplayMode);

  const crossThrough = ({ width, height }: Dimens) => {
    const strokeWidth = displayMode === 'digital' ? 4 : 8;
    return (
      <Svg width={width} height={height}>
        <Line
          x1={strokeWidth / 2}
          y1={height}
          x2={width - strokeWidth / 2}
          y2={0}
          stroke={colors.prussianBlue}
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
        />
      </Svg>
    );
  };

  const tensComponents = tens > 0 && (
    <View key="tens" style={{ flexDirection: 'row' }}>
      {countRange(tens).map(ten => {
        const setTensState = setCrossedOutIndices
          ? projectSetState(setCrossedOutIndices, 'tens')
          : noop;

        return (
          <Pressable
            key={`ten_${ten}`}
            disabled={!isInteractive}
            onPress={() => {
              if (!crossedOutIndices.tens.includes(ten)) {
                const newArray = crossedOutIndices.tens;
                newArray.push(ten);
                setTensState(newArray);
              } else {
                const newArray =
                  crossedOutIndices.tens.length === 1
                    ? []
                    : crossedOutIndices.tens.filter(index => index !== ten);
                setTensState(newArray);
              }
            }}
          >
            <View
              style={{
                height: scaledTensHeight + 5,
                width: scaledTensWidth + 5,
                justifyContent: 'flex-end'
              }}
            >
              <View
                style={{
                  height: scaledTensHeight,
                  width: scaledTensWidth
                }}
              >
                {tensObject.component}
              </View>
              {crossedOutIndices.tens.includes(ten) && (
                <View style={{ position: 'absolute' }}>
                  {crossThrough({
                    height: scaledTensHeight,
                    width: scaledTensWidth
                  })}
                </View>
              )}
            </View>
          </Pressable>
        );
      })}
    </View>
  );

  const onesComponents = ones > 0 && (
    <View
      key="ones"
      style={{ flexDirection: 'row', gap: 5, bottom: isInteractive ? -16 : undefined }}
    >
      {countRange(onesRows).map(rowIdx => (
        <View key={`row_${rowIdx}`} style={{ flexDirection: 'column', justifyContent: 'flex-end' }}>
          {countRange(Math.min(ones - rowIdx * 5, 5)).map(one => {
            const setOnesState = setCrossedOutIndices
              ? projectSetState(setCrossedOutIndices, 'ones')
              : noop;
            return (
              <Pressable
                key={`row_${rowIdx}_one_${one}`}
                disabled={!isInteractive}
                style={
                  isInteractive && {
                    height: interactiveSpan,
                    width: interactiveSpan,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }
                }
                onPress={() => {
                  if (!crossedOutIndices.ones.includes(one + rowIdx * 5)) {
                    const newArray = crossedOutIndices.ones;
                    newArray.push(one + rowIdx * 5);
                    setOnesState(newArray);
                  } else {
                    const newArray =
                      crossedOutIndices.ones.length === 1
                        ? []
                        : crossedOutIndices.ones.filter(index => index !== one + rowIdx * 5);
                    setOnesState(newArray);
                  }
                }}
              >
                <View
                  style={{
                    height: scaledOnesHeight + 5,
                    width: scaledOnesWidth + 5,
                    marginTop: one === 0 ? 0 : onesMargin,
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <View
                    style={{
                      height: scaledOnesHeight,
                      width: scaledOnesWidth
                    }}
                  >
                    {onesObject.component}
                  </View>
                  {crossedOutIndices.ones.includes(one + rowIdx * 5) && (
                    <View style={{ position: 'absolute' }}>
                      {crossThrough({
                        height: scaledOnesHeight + 5,
                        width: scaledOnesWidth + 5
                      })}
                    </View>
                  )}
                </View>
              </Pressable>
            );
          })}
        </View>
      ))}
    </View>
  );

  const baseTenComponents = onesPosition.includes('left')
    ? [onesComponents, tensComponents]
    : [tensComponents, onesComponents];

  return <View style={styles.mainContainer}>{baseTenComponents}</View>;
}

/** See {@link SimpleBaseTenWithCrossOut} */
export const SimpleBaseTenWithCrossOutWithState = withStateHOC(SimpleBaseTenWithCrossOut, {
  stateProp: 'crossedOutIndices',
  setStateProp: 'setCrossedOutIndices',
  defaults: {
    defaultState: {
      tens: [],
      ones: []
    }
  }
});

/**
 * Handles interactive {@link SimpleBaseTenWithCrossOut} and pdf view
 * Due to hardcoded values for interactive questions we can just use 'dimens' prop to scale in pdf
 */
export function CrossOutBaseTen({
  tens = 0,
  ones = 0,
  crossedOutIndices = { tens: [], ones: [] },
  scale,
  dimens
}: Props) {
  const displayMode = useContext(DisplayMode);

  if (displayMode === 'digital') {
    return (
      <SimpleBaseTenWithCrossOutWithState
        dimens={dimens}
        id={'SimpleBaseTenWithCrossOutWithState'}
        tens={tens}
        ones={ones}
        defaultState={crossedOutIndices}
      />
    );
  } else
    return (
      <SimpleBaseTenWithCrossOut
        dimens={dimens}
        tens={tens}
        ones={ones}
        scale={scale}
        crossedOutIndices={crossedOutIndices}
      />
    );
}

const getStyles = (
  dimens: Dimens,
  isInteractive: boolean,
  onesAlignment: 'flex-start' | 'flex-end',
  rotate?: '90deg' | '180deg' | '270deg'
) =>
  StyleSheet.create({
    mainContainer: {
      alignItems: onesAlignment,
      justifyContent: 'center',
      flexDirection: 'row',
      gap: isInteractive ? 20 : 10,
      maxHeight: dimens.height,
      maxWidth: dimens.width,
      transform: rotate ? [{ rotate }] : undefined
    }
  });

export const Scale = (
  usableWidth: number,
  usableHeight: number,
  numbers: {
    ones?: number;
    tens?: number;
  }
): number => {
  const margin = 4;
  const questionMargin = 20;
  // Set numbers
  const numOnes = numbers.ones ?? 0;
  const numTens = numbers.tens ?? 0;

  // Get images
  const onesObject = BaseTenImages['Cubes'].ONES;
  const tensObject = BaseTenImages['Cubes'].TENS;

  const onesColCount = Math.ceil(numOnes / 5);
  const onesRowCount = Math.min(numOnes, 5);
  const tensColCount = numTens;
  // only ever have one row of tens
  const tensRowCount = 1;

  // Calculate scale amount
  // Calculate total width
  const widthOnes = onesObject.width * onesColCount;
  const widthTens = tensObject.width * tensColCount;
  const totalImageWidth = widthOnes + widthTens;
  const totalAmountWidth = onesColCount + tensColCount;

  // Calculate total height
  // There can be one, two or three rows of images per unit type (or one or two rows for tens and ones 'Cubes')
  const heightOnes = onesObject.height * onesRowCount;
  const heightTens = tensObject.height * tensRowCount;
  const totalImageHeight = Math.max(heightOnes, heightTens);
  const totalAmountHeight = Math.max(onesRowCount, tensRowCount);

  // Calculate smallest scale
  const widthScale =
    (usableWidth - questionMargin - margin * (totalAmountWidth - 1)) / Math.ceil(totalImageWidth);
  const heightScale =
    (usableHeight - questionMargin - margin * (totalAmountHeight - 1)) /
    Math.ceil(totalImageHeight);
  return Math.min(widthScale, heightScale);
};
