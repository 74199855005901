import { ALGEBRAIC_X, ALGEBRAIC_Y, SUB } from '../../constants';

export default {
  acceptAddendsInEitherOrder: 'Accept addends in either order.',
  acceptAnyAmountBelowHalfwayOfTheXContainer:
    'Accept any amount below halfway of the {0:string|lower}.',
  acceptAnyAmountOverHalfwayOfTheXContainer:
    'Accept any amount over halfway of the {0:string|lower}.',
  acceptAnyAmountXMoreOrLessThanYContainer: 'Accept any amount {0:string} than {1:string}.',
  acceptAnyArrayOfNumCountersDifferentToGivenArrayAbove:
    'Accept any array of {0:number|numberFormat} counters that is different to the given array above.',
  acceptAnyCardPairThatSumToX: 'Accept any card pair that sum to {0:number|numberFormat}',
  acceptAnyCombinationThatAddUpToX: 'Accept any combination that adds up to {0}.',
  acceptAnyCommonFactor: 'Accept any common factor.',
  acceptAnyCoordinateThatSatisfiesLine: `Accept any coordinates that satisfies line; ${ALGEBRAIC_Y} = {m}${ALGEBRAIC_X} + ({c}).`,
  acceptAnyCoordinateThatSatisfiesYEqualsN: `Accept any coordinates that satisfies line; ${ALGEBRAIC_Y} = {0}.`,
  acceptAnyCoordinateThatSatisfiesXEqualsN: `Accept any coordinates that satisfies line; ${ALGEBRAIC_X} = {0}.`,
  acceptAnyCoordinateThatMakeAShape: `Accept any coordinates that make a {0}.`,
  acceptAnyEvenNumber: 'Accept any even number.',
  acceptAnyNumberGreaterThanX: 'Accept any number greater than {0}',
  acceptAnyNumberLessThanX: 'Accept any number less than {0}',
  acceptNumberEqualToX: 'Accept number equal to {0}',
  acceptAnyNumberWithXInTheYPlace:
    'Accept any number with {0:number|numberFormat} in the {1} place.',
  acceptAnyOddNumber: 'Accept any odd number.',
  acceptAnyOrder: 'Accept any order.',
  acceptAnyValidAnswer: 'Accept any valid answer.',
  acceptAnyValidAnswerInRangeInclusive:
    'Accept any valid answer within range of {0} and {1} (inclusive).',
  acceptAnyValidAnswerInRangeExclusive:
    'Accept any valid answer within range of {0} and {1} (exclusive).',
  acceptAnyValidCoordinate: 'Accept any valid coordinate.',
  acceptAnyValidEquivalentFractions: 'Accept any valid equivalent fractions.',
  acceptReversedMultiplication: 'Accept reversed multiplication.',
  acceptBarModelWithAnyXShadedCellsCrossedOut:
    'Accept bar model with any {0:number|numberFormat} shaded cells crossed out.',
  acceptEquivalentDecimals: 'Accept equivalent decimals.',
  acceptEquivalentDecimalsAndFractions: 'Accept equivalent decimals and fractions.',
  acceptEquivalentFractions: 'Accept equivalent fractions.',
  acceptEquivalentFractionsBarModelsDoNotNeedToBeShaded:
    'Accept equivalent fractions. Bar models do not need to be shaded.',
  acceptEquivalentFractionsRatios: 'Accept equivalent fractions and ratios.',
  acceptEquivalentTime: 'Accept equivalent time.',
  acceptEquivalentFractionsDecimals: 'Accept equivalent fractions and decimals.',
  acceptEquivalentRatios: 'Accept equivalent ratios.',
  acceptAnyCorrectAdditions: 'Accept any other correct additions.',
  acceptAnyOtherCorrectConditionsThatMatchEachGroup:
    'Accept any other correct conditions that match each group.',
  acceptAnyParallelLines: 'Accept any pair of parallel lines.',
  acceptAnyPerpendicularLines: 'Accept any pair of perpendicular lines.',
  acceptAnyShadedArrayWithXRowsAndYColumns:
    'Accept any shaded array with {0:number|numberFormat} rows and {1:number|numberFormat} columns.',
  acceptAnyTwoFactors: 'Accept any two factors.',
  acceptTheBase10BlocksInAnyOrder: 'Accept the base 10 blocks in any order.',
  acceptThreeDifferentPairsOfValuesThatAddUpToNum:
    'Accept three different pairs of values that add up to {0:number|numberFormat}',
  acceptBothTwelveHourAndTwentyFourHourTimes: 'Accept both 12 hour and 24 hour times.',
  acceptEitherColourForEitherPart: 'Accept either colour for either part.',
  acceptThreeDifferentPairsOfValuesWhereXSubYEqualsNumEachValLessThanZ: `Accept three different pairs of values where {0} ${SUB} {1} = {2:number|numberFormat}, and where all values are less than {3:number|numberFormat}`,
  acceptValidAnswersForContent: 'Accept valid answers that fit the sentence.',
  acceptValidFractionsThatTotalXFromAvailCards:
    'Accept valid fractions that total {0:number|numberFormat} from available cards.',
  acceptValidVerticesForShape: 'Accept any valid vertices that create required shape.',
  acceptValidVerticesForIrregularPolygon:
    'Accept any valid vertices that create an irregular polygon.',
  allNumberSentencesMustBeCorrect: 'All number sentences must be correct.',
  allowClockwiseOrAntiClockwise: 'Allow clockwise or anti-clockwise.',
  allowXMmMarginOfError: 'Allow a {0:number|numberFormat} mm margin of error.',
  allowMarginOfErrorOfX: 'Allow a margin of error of {0:number|numberFormat}',
  allowMarginOfErrorOfXDegrees: 'Allow a margin of error of {0:number|numberFormat}°.',
  allowMarginOfErrorOfXWithoutCrossingATen:
    'Allow a margin of error of {0:number|numberFormat} without crossing a ten.',
  allowMarginOfErrorOfXWithoutCrossingATick:
    'Allow a margin of error of {0:number|numberFormat} without crossing a tick.',
  alsoAcceptX: 'Also accept {0}',
  answerWithinRange: 'Answer is within range ±{0:number|numberFormat}',
  answersInAnyOrder: 'Answers can be in any order.',
  answersMayVaryTheyAreEstimates: 'Answers may vary slightly as they are estimates.',
  answersMustMakeFractionsInAscendingOrder:
    'Answers must make proper fractions in ascending order.',
  answersMustMakeFractionsInDescendingOrder:
    'Answers must make proper fractions in descending order.',
  answersThatAreCloseToX: 'Accept answers that are close to {0:number|numberFormat}',
  answersThatAreXFromYAccepted:
    'Accept answers within the range {1:number|numberFormat} ±{0:number|numberFormat}',
  anyAcuteAngle: 'Any acute angle.',
  anyAngleWithinPieChartXDegrees: 'Any angle within pie chart that is {0:number|numberFormat}°.',
  anyCorrectAdditionWithNoZeros: 'Any correct addition with no zeros.',
  anyCorrectAnswersThatSatisfyInequalities: 'Any correct answers that satisfy the inequalities.',
  anyCorrectAnswersThatSubtractToMakeX:
    'Any correct answers that subtract to equal {0:number|numberFormat}',
  anyGreaterAngle: 'Any greater angle.',
  anyNumberGreaterThanX: 'Accept any number greater than {0}',
  anyNumberLessThanX: 'Accept any number less than {0}',
  anyNumberEqualToX: 'Accept any number equal to {0}',
  anyMultipleOfXAndYUsingAvailCards:
    'Any multiple of <b>{0:number|numberFormat}</b> and <b>{1:number|numberFormat}</b> using the available cards. First digit cannot be 0',
  anyMultipleOfXAndNotYUsingAvailCards:
    'Any multiple of <b>{0:number|numberFormat}</b> and not <b>{1:number|numberFormat}</b> using the available cards. First digit cannot be 0',
  anyMultipleOfXUsingAvailCards:
    'Any multiple of <b>{0:number|numberFormat}</b> using the available cards. First digit cannot be 0',
  anyNumPartsOfTheBarModelCanBeShaded:
    'Any {0:number|numberFormat} parts of the bar model can be shaded.',
  anyNumPartsOfTheBarModelCanBeShadedToMakeNumPercent:
    'Any {0:number|numberFormat} parts of the bar model can be shaded to make {1:number|numberFormat}%.',
  anyObtuseAngle: 'Any obtuse angle.',
  anyOfTheFollowingXYZ: 'Any of the following {acceptableAnswers}',
  anyPossibleCombinationOfCoinsThatSumsToX:
    'Any possible combination of coins that sums to: {0:number|numberFormat}p',
  anyPossibleCombinationThatMultiplyToX:
    'Any possible combination of numbers that multiply to: {0:number|numberFormat}',
  anyPossibleCombinationsThatSumToTotal: 'Any possible combinations that sum to total.',
  anyPossibleCombinationThatSumsToX:
    'Any possible combination of numbers that sum to: {0:number|numberFormat}',
  anyPossibleCombinationOfNumeratorsThatSumToX:
    'Any possible combination of numerators that sum to: {0:number|numberFormat}',
  anyPossibleCombinationOfNumeratorsThatSumToXAndY:
    'Any possible combination of numerators that sum to: {0:number|numberFormat} and {1:number|numberFormat}',
  anyRectangleWithAreaOfX: 'Any rectangle with area of {0}',
  anyRectangleWithAreaOfNumSquares:
    'Any rectangle with an area of {0:number|numberFormat} squares.',
  anyRectilinearShapeWithAreaOfXAndPerimeterGreaterThanY:
    'Any rectangle with area of {0:number|numberFormat} and perimeter greater than {1:number|numberFormat}',
  anyRectilinearShapeWithAreaOfXAndPerimeterSmallerThanY:
    'Any rectangle with area of {0:number|numberFormat} and perimeter smaller than {1:number|numberFormat}',
  anyRectangleWithPerimeterOfX: 'Any rectangle with perimeter of {0}',
  anyRectilinearShapeWithPerimeterOfXAndAreaGreaterThanY:
    'Any rectangle with perimeter of {0:number|numberFormat} and area greater than {1:number|numberFormat}',
  anyRectilinearShapeWithPerimeterOfXAndAreaSmallerThanY:
    'Any rectangle with perimeter of {0:number|numberFormat} and area smaller than {1:number|numberFormat}',
  anyRectilinearShapeWithAreaOfX: 'Any rectilinear shape with area of {0}',
  anyShapeWithAreaX: 'Any shape with an area of {0:number|numberFormat} squares.',
  anyShapeWithAreaGreaterThanX:
    'Any shape with an area greater than {0:number|numberFormat} squares.',
  anyShapeWithAreaLessThanX: 'Any shape with an area less than {0:number|numberFormat} squares.',
  anyShapeWithPerimeterOfX: 'Any shape with perimeter of {0}',
  anySmallerAngle: 'Any smaller angle.',
  anySquare: 'Any square.',
  anySquareWithAreaOfX: 'Any square with area of {0}',
  anySquareWithPerimeterOfX: 'Any square with perimeter of {0}',
  anyTriangleWithAreaofX: 'Any triangle with area of {0}',
  anyValidAdditionUsingAvailCards: 'Any valid addition using the available cards.',
  anyValidMultiplicationUsingAvailCards: 'Any valid multiplication using the available cards.',
  anyValidNumberSentenceUsingAvailCards: 'Any valid number sentence using the available cards.',
  anyValidPartition: 'Any valid partition.',
  anyValidPartitionWithAvailCards: 'Any valid partition with available cards.',
  anyValidPartitions: 'Any valid partitions.',
  anyValidPartitionWithMatchingAdditionSentence:
    'Any valid partition with matching addition sentence.',
  anyValidShapeWithNumVertices: 'Any valid shape with {0:number|numberFormat} vertices.',
  anyValidSubtractionUsingAvailCards: 'Any valid subtraction using the available cards.',
  anyValidXWithNumVertices: 'Any valid {0:string|lower} with {1:number|numberFormat} vertices.',
  anyXRectilinearShapeWithAreaOfY: 'Any {0} rectilinear shapes with areas of {1}',
  arrayDimensCanBeFlipped:
    'Array dimensions can be flipped (width and height the other way round).',
  barModelDoesNotNeedToBeShaded: 'Bar model does not need to be shaded.',
  barModelsDoNotNeedShading: 'Bar models do not need to be shaded.',
  canBeSortedByColourOrShape: 'Can be sorted by colour or shape.',
  correctAnswerIsX: 'Correct answer is: {0}',
  denominatorMustBeGreaterThanX: 'Denominator must be greater than {0:number|numberFormat}',
  doNotAcceptDuplicateSentences: 'Do not accept duplicate sentences.',
  doNotAcceptEquivalentFractions: 'Do not accept equivalent fractions.',
  exchangeBoxesAreUnmarked: 'Exchanges are unmarked.',
  factorsInAnyOrder: 'Factors in any order.',
  fractionMustBeInSimplestForm: 'Fraction must be in its simplest form.',
  fractionsMustBeInSimplestForm: 'Fractions must be in their simplest forms.',
  lengthAndWidthCanBeFlipped: 'Length and width can be flipped.',
  numberGridDoesNotNeedToBeShaded: 'Number grid does not need to be shaded.',
  numberSentenceMustBeCorrect: 'Number sentence must be correct.',
  numeratorMustBeLessThanX: 'Numerator must be less than {0:number|numberFormat}',
  orAnyOtherValidAnswer: 'Or any other valid answer.',
  orAnyOtherValidAnswerExcludingFactorsOfOneAndItself:
    'Or any other valid answer excluding factors excluding 1 and the number itself.',
  orAnyOtherValidAnswerFromAvailCards: 'Or any other valid answer from available cards.',
  orAnyOtherCommonMultiples: 'Or any other common multiples.',
  orAnyOtherValidRectilinearShapeWithXSquares:
    'Or any other valid rectilinear shape with {0:number|numberFormat} squares.',
  otherCommonDenominatorsAreAlsoAcceptable: 'Other common denominators are also acceptable.',
  partsCanBeInAnyOrder: 'Parts can be in any order.',
  segmentsCanBeInAnyOrder: 'Segments can be shaded in any order.',
  shadingCanTakePlaceAnywhereOnGrid:
    'Shading can take place anywhere on the grid, as long as the shaded shape matches this enlargement.',
  shapesCanBeSortedByEitherShapeOrColour: 'Shapes can be sorted by either shape or colour.',
  theRemainingShapesAreShape: 'The remaining shapes are {0:string|lower}.',
  theXContainerShouldBeFull: 'The {0:string|lower} should be full.',
  useTheseBase10Pieces: 'Use these base 10 pieces:',
  validDivisionWithAnsX: 'Valid division with following answer: {0:number|numberFormat}',
  validDivisionWithRemainderGreaterThanX:
    'Valid division with remainder greater than {0:number|numberFormat}',
  validDivisionWithRemainderLessThanX:
    'Valid division with remainder less than {0:number|numberFormat}',
  validMultiplications: 'Valid multiplications.',
  validSentenceMatchingContent: 'Valid sentence matching content.',
  validSentencesMatchingContent: 'Valid sentences matching content.',
  withValidDivision: 'With valid division.',
  xAndYCanBeInEitherOrder:
    '{0:number|numberFormat} and {1:number|numberFormat} can be in either order.',
  xAndYCanBeInEitherOrderInTheFinalSentence:
    '{0:number|numberFormat} and {1:number|numberFormat} can be in either order in the final sentence.'
};
