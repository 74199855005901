import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import QF21DraggableTenFrames, {
  totalCountersIs
} from '../../../../components/question/questionFormats/QF21DraggableTenFrames';
import { NumberGridInteractiveWithState } from '../../../../components/question/representations/NumberGridInteractive';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bas',
  description: 'bas',
  keywords: ['Count', 'Ten frame', 'More'],
  schema: z.object({
    num: z.number().int().min(1).max(9),
    ordering: z.enum(['rowFirst', 'columnFirst'])
  }),
  simpleGenerator: () => {
    const num = randomIntegerInclusive(1, 9);
    const ordering = getRandomFromArray(['rowFirst', 'columnFirst'] as const);
    return { num, ordering };
  },
  Component: props => {
    const {
      question: { num, ordering },
      translate
    } = props;

    const correctAnswer = num + 1;

    return (
      <QF21DraggableTenFrames
        title={translate.ks1Instructions.drag1MoreCounterOnToTheTenFrame()}
        pdfTitle={translate.ks1PDFInstructions.draw1MoreThanNum(num)}
        numberOfTenFrames={1}
        tenFrameFlexDirection="row"
        items={['yellow']}
        itemOrdering={ordering}
        interactiveCells="notInitial"
        initialState={(tenFrameIndex, index) => {
          if (tenFrameIndex === 0 && index < num) return 'red';
        }}
        exampleCorrectAnswer={(tenFrameIndex, index) => {
          if (tenFrameIndex === 0 && index < correctAnswer) return 'red';
        }}
        testCorrect={totalCountersIs(correctAnswer)}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'bat',
  description: 'bat',
  keywords: ['Table', 'Addition', 'More'],
  schema: z.object({
    cells: z.number().int().min(6).max(8),
    number: z.number().int().min(1).max(10),
    increment: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const increment = randomIntegerInclusive(0, 2);
    const cells = randomIntegerInclusive(6, 8);
    const number = randomIntegerInclusive(1, 10, {
      constraint: x => x > 1 + increment && x < cells + increment
    });

    return { cells, number, increment };
  },
  Component: props => {
    const {
      question: { number, cells, increment },
      translate,
      displayMode
    } = props;

    return (
      <QF3Content
        title={translate.ks1Instructions.tapXMoreThanY(1, number)}
        pdfTitle={translate.ks1PDFInstructions.circleXMoreThanY(1, number)}
        Content={({ dimens }) => (
          <NumberGridInteractiveWithState
            startNumber={1 + increment}
            finishNumber={cells + increment}
            testCorrect={userAnswer => userAnswer[0] === number + 1}
            dimens={{
              width: dimens.width,
              height: dimens.height
            }}
            id={'number grid interactive'}
            defaultState={displayMode === 'markscheme' ? [number + 1] : []}
            maxSelectableNumbers={1}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bau',
  description: 'bau',
  keywords: ['Addition'],
  schema: z.object({
    number: z.number().int().min(0).max(10),
    variant: z.boolean()
  }),
  simpleGenerator: () => {
    const variant = getRandomBoolean();
    const number = randomIntegerInclusive(0, variant ? 10 : 9);
    return { number, variant };
  },

  Component: props => {
    const {
      question: { number, variant },
      translate
    } = props;

    const answer = variant ? number - 1 : number + 1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={
          variant
            ? translate.ks1AnswerSentences.oneMoreThanAnsIsX(number)
            : translate.ks1AnswerSentences.oneMoreThanXIsAns(number)
        }
        testCorrect={[answer.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OneMore',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
