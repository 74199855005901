import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import { Dimens } from '../../../../theme/scaling';
import { CubeTower } from '../../../../components/question/representations/CubeTower';
import { bfO } from '../../../Year 2/Autumn/PlaceValue/1NumbersTo20';
import { DescriptionKey } from '../../../../i18n/custom-types';

////
// Shared Questions
////

export const createShownNumberQuestion = (
  numberRange: { min: number; max: number },
  uid: DescriptionKey
) =>
  newQuestionContent({
    uid,
    description: uid,
    keywords: ['Ten frames', 'Counters', 'Rekenrek', 'Base 10'],
    schema: z.object({
      number: z.number().int().min(numberRange.min).max(numberRange.max),
      representation: z.discriminatedUnion('name', [
        z.object({
          name: z.literal('TenFrames'),
          color: z.enum(['red', 'yellow', 'blue', 'green']),
          orientation: z.enum(['horizontal', 'vertical']),
          ordering: z.enum(['fivewise', 'pairwise'])
        }),
        z.object({
          name: z.literal('Rekenrek')
        }),
        z.object({
          name: z.literal('Base10'),
          onesPosition: z.enum(['top', 'bottom', 'leftTop', 'leftBottom'])
        }),
        z.object({
          name: z.literal('Tower'),
          color: z.enum(['red', 'green', 'blue', 'yellow', 'purple', 'orange'])
        })
      ])
    }),
    simpleGenerator: () => {
      const number = randomIntegerInclusive(numberRange.min, numberRange.max);
      const representationName = getRandomFromArray([
        'TenFrames',
        'Rekenrek',
        'Base10',
        'Tower'
      ] as const);

      const representation = (() => {
        switch (representationName) {
          case 'TenFrames':
            return {
              name: representationName,
              color: getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const),
              orientation: getRandomFromArray(['horizontal', 'vertical'] as const),
              ordering: getRandomFromArray(['fivewise', 'pairwise'] as const)
            };
          case 'Rekenrek':
            return { name: representationName };
          case 'Base10':
            return {
              name: representationName,
              onesPosition: getRandomFromArray(['top', 'bottom', 'leftTop', 'leftBottom'] as const)
            };
          case 'Tower':
            return {
              name: representationName,
              color: getRandomFromArray([
                'red',
                'green',
                'blue',
                'yellow',
                'purple',
                'orange'
              ] as const)
            };
        }
      })();

      return { number, representation };
    },
    Component: props => {
      const {
        question: { number, representation },
        translate,
        displayMode
      } = props;

      const component = (dimens: Dimens) => {
        switch (representation.name) {
          case 'TenFrames': {
            return (
              <>
                <TenFrameLayout
                  items={new Array(10).fill(representation.color)}
                  itemOrdering={representation.ordering === 'pairwise' ? 'columnFirst' : 'rowFirst'}
                  size={displayMode === 'digital' ? 'xsmall' : 'medium'}
                  orientation={representation.orientation}
                />
                <TenFrameLayout
                  items={new Array(number - 10).fill(representation.color)}
                  itemOrdering={representation.ordering === 'pairwise' ? 'columnFirst' : 'rowFirst'}
                  size={displayMode === 'digital' ? 'xsmall' : 'medium'}
                  orientation={representation.orientation}
                />
              </>
            );
          }
          case 'Rekenrek':
            return <Rekenrek rows={2} numberShown={number} />;
          case 'Base10':
            return (
              <SimpleBaseTenWithCrossOut
                tens={parseFloat(number.toString().split('')[0])}
                ones={parseFloat(number.toString().split('')[1])}
                dimens={dimens}
                onesPosition={representation.onesPosition}
              />
            );
          case 'Tower':
            return (
              <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                <CubeTower
                  color={representation.color}
                  count={10}
                  dimens={{ height: dimens.height, width: dimens.width / 8 }}
                />
                <CubeTower
                  color={representation.color}
                  count={number - 10}
                  dimens={{ height: ((number % 10) * dimens.height) / 10, width: dimens.width / 8 }}
                />
              </View>
            );
        }
      };

      return (
        <QF1ContentAndSentence
          title={translate.ks1Instructions.whatNumberIsShown()}
          Content={({ dimens }) => (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: 40,
                rowGap: 25
              }}
            >
              {component(dimens)}
            </View>
          )}
          pdfDirection="column"
          sentence="<ans/>"
          sentenceStyle={{ justifyContent: 'flex-end' }}
          pdfSentenceStyle={{ justifyContent: 'flex-end' }}
          testCorrect={[number.toString()]}
          questionHeight={900}
        />
      );
    },
    questionHeight: 900
  });

export const createCompleteSentenceQuestion = (
  numberRange: { min: number; max: number },
  uid: DescriptionKey
) =>
  newQuestionContent({
    uid,
    description: uid,
    keywords: ['Ten frames', 'Counters', 'Rekenrek', 'Base 10'],
    schema: z.object({
      number: z.number().int().min(numberRange.min).max(numberRange.max),
      sentenceLayout: z.enum([
        'ansNumAnsTensAnsOnes',
        'ansNumTensOnes',
        'numTensAnsOnes',
        'numAnsTensOnes',
        'ansNumTensAnsOnes',
        'ansNumAnsTensOnes'
      ]),
      representation: z.discriminatedUnion('name', [
        z.object({
          name: z.literal('TenFrames'),
          color: z.enum(['red', 'yellow', 'blue', 'green']),
          orientation: z.enum(['horizontal', 'vertical']),
          ordering: z.enum(['fivewise', 'pairwise'])
        }),
        z.object({
          name: z.literal('Rekenrek')
        }),
        z.object({
          name: z.literal('Base10'),
          onesPosition: z.enum(['top', 'bottom', 'leftTop', 'leftBottom'])
        })
      ])
    }),
    simpleGenerator: () => {
      const number = randomIntegerInclusive(numberRange.min, numberRange.max);
      const representationName = getRandomFromArray(['TenFrames', 'Rekenrek', 'Base10'] as const);
      const sentenceLayout = getRandomFromArray([
        'ansNumAnsTensAnsOnes',
        'ansNumTensOnes',
        'numTensAnsOnes',
        'numAnsTensOnes',
        'ansNumTensAnsOnes',
        'ansNumAnsTensOnes'
      ] as const);

      const representation = (() => {
        switch (representationName) {
          case 'TenFrames':
            return {
              name: representationName,
              color: getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const),
              orientation: getRandomFromArray(['horizontal', 'vertical'] as const),
              ordering: getRandomFromArray(['fivewise', 'pairwise'] as const)
            };
          case 'Rekenrek':
            return { name: representationName };
          case 'Base10':
            return {
              name: representationName,
              onesPosition: getRandomFromArray(['top', 'bottom', 'leftTop', 'leftBottom'] as const)
            };
        }
      })();

      return { number, representation, sentenceLayout };
    },
    Component: props => {
      const {
        question: { number, representation, sentenceLayout },
        translate,
        displayMode
      } = props;

      const tens = parseFloat(number.toString().split('')[0]);
      const ones = parseFloat(number.toString().split('')[1]);

      const component = (dimens: Dimens) => {
        switch (representation.name) {
          case 'TenFrames': {
            return (
              <>
                <TenFrameLayout
                  items={new Array(10).fill(representation.color)}
                  itemOrdering={representation.ordering === 'pairwise' ? 'columnFirst' : 'rowFirst'}
                  size={displayMode === 'digital' ? 'xsmall' : 'medium'}
                  orientation={representation.orientation}
                />
                <TenFrameLayout
                  items={new Array(number - 10).fill(representation.color)}
                  itemOrdering={representation.ordering === 'pairwise' ? 'columnFirst' : 'rowFirst'}
                  size={displayMode === 'digital' ? 'xsmall' : 'medium'}
                  orientation={representation.orientation}
                />
              </>
            );
          }
          case 'Rekenrek':
            return <Rekenrek rows={2} numberShown={number} />;
          case 'Base10':
            return (
              <SimpleBaseTenWithCrossOut
                tens={parseFloat(number.toString().split('')[0])}
                ones={parseFloat(number.toString().split('')[1])}
                dimens={{ width: dimens.width, height: dimens.height }}
                onesPosition={representation.onesPosition}
              />
            );
        }
      };

      const [answer, sentence] = (() => {
        switch (sentenceLayout) {
          case 'ansNumAnsTensAnsOnes':
            return [
              [number, tens, ones],
              translate.ks1AnswerSentences.ansHasAnsTensAndAnsOnes(tens, ones)
            ];
          case 'ansNumTensOnes':
            return [[number], translate.ks1AnswerSentences.ansHasNumTensAndNumOnes(tens, ones)];
          case 'numTensAnsOnes':
            return [[ones], translate.ks1AnswerSentences.numHasXTensAndAnsOnes(number, tens, ones)];
          case 'numAnsTensOnes':
            return [[tens], translate.ks1AnswerSentences.numHasAnsTensAndXOnes(number, tens, ones)];
          case 'ansNumTensAnsOnes':
            return [[number, ones], translate.ks1AnswerSentences.ansHasXTensAndAnsOnes(tens, ones)];
          case 'ansNumAnsTensOnes':
            return [[number, tens], translate.ks1AnswerSentences.ansHasAnsTensAndXOnes(tens, ones)];
        }
      })();

      return (
        <QF1ContentAndSentence
          title={translate.ks1Instructions.completeTheSentence()}
          Content={({ dimens }) => (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: 40,
                rowGap: 25
              }}
            >
              {component(dimens)}
            </View>
          )}
          pdfDirection="column"
          sentence={sentence}
          testCorrect={answer.map(String)}
          questionHeight={900}
        />
      );
    },
    questionHeight: 900
  });

////
// Questions
////

const Question1 = createShownNumberQuestion({ min: 11, max: 13 }, 'bb3');

const Question2 = createCompleteSentenceQuestion({ min: 11, max: 13 }, 'bb4');

const Question3 = {
  ...bfO,
  uid: 'bb5',
  description: 'bb5' as const,
  schema: z.object({
    number: z.number().int().min(11).max(13),
    numOrWord: z.enum(['numeral', 'word']),
    isBase10: z.boolean(),
    counterVariant: z.enum(['red', 'blue', 'green', 'grey', 'yellow'])
  }),
  generator: () => {
    const number = getRandomFromArray([11, 12, 13] as const);

    const numOrWord = getRandomFromArray(['numeral', 'word'] as const);

    const isBase10 = getRandomBoolean();

    const counterVariant = getRandomFromArray(['red', 'blue', 'green', 'grey', 'yellow'] as const);

    return { number, numOrWord, isBase10, counterVariant };
  }
};

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Understand1112And13',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
