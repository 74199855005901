import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  rejectionSample,
  shuffle
} from 'common/src/utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { AssetSvg } from '../../../../assets/svg';
import { get3DShapeFullColorsSVGPath, shapeInfo3D } from '../../../../utils/threeDShapes';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import deepEqual from 'react-fast-compare';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';

////
// Questions
////

const curvedShapes3d = ['Cone', 'Cylinder', 'Sphere'] as const;

const nonCurvedShapes3d = [
  'Cube',
  'Cuboid',
  'Hexagonal_prism',
  'Pentagonal_prism',
  'Square_pyramid',
  'Triangle_pyramid',
  'Triangular_prism'
] as const;

const shapes3d = [...curvedShapes3d, ...nonCurvedShapes3d] as const;

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhX',
  description: 'bhX',
  keywords: ['3-D shape', 'Edges'],
  schema: z.object({
    shape: z.enum(nonCurvedShapes3d),
    rotation: numberEnum([0, 90, 270])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(nonCurvedShapes3d);

    const rotation = getRandomFromArray([0, 90, 270] as const);

    return { shape, rotation };
  },
  Component: ({ question: { shape, rotation }, translate }) => {
    const edges = shapeInfo3D[shape].edges;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyEdgesDoesTheShapeHave()}
        Content={({ dimens }) => (
          <View style={{ transform: [{ rotate: `${rotation}deg` }] }}>
            <AssetSvg
              name={get3DShapeFullColorsSVGPath('transparent', shape)}
              width={rotation === 90 || rotation === 270 ? dimens.height * 0.9 : dimens.width * 0.9}
              height={
                rotation === 90 || rotation === 270 ? dimens.width * 0.9 : dimens.height * 0.9
              }
            />
          </View>
        )}
        sentence="<ans/>"
        testCorrect={[edges.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const q2Colors = ['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'] as const;

const Question2 = newQuestionContent({
  uid: 'bhY',
  description: 'bhY',
  keywords: ['3-D shape', 'Edges'],
  schema: z
    .object({
      shape: z.enum(shapes3d),
      color: z.enum(q2Colors),
      items: z
        .array(z.number().int().min(0).max(200))
        .length(4)
        .refine(arr => arrayHasNoDuplicates(arr), 'items must have no duplicates.')
    })
    .refine(
      val => val.items.includes(shapeInfo3D[val.shape].edges),
      'items must include the correct number of edges for the selected shape.'
    ),
  simpleGenerator: () => {
    const shape = getRandomFromArray(shapes3d);

    const color = getRandomFromArray(q2Colors);

    const correctAnswer = shapeInfo3D[shape].edges;

    const incorrectAnswers = (() => {
      switch (shape) {
        case 'Cone':
          return [2, 0, 5]; // Correct: 1
        case 'Cylinder':
          return [4, 1, 6]; // Correct: 2
        case 'Sphere':
          return [1, 2, 3]; // Correct: 0
        case 'Cube':
          return [9, 4, 24]; // Correct: 12
        case 'Cuboid':
          return [9, 4, 24]; // Correct: 12
        case 'Hexagonal_prism':
          return [13, 6, 36]; // Correct: 18
        case 'Pentagonal_prism':
          return [12, 5, 30]; // Correct: 15
        case 'Square_pyramid':
          return [5, 4, 16]; // Correct: 8
        case 'Triangle_pyramid':
          return [5, 3, 12]; // Correct: 6
        case 'Triangular_prism':
          return [6, 3, 18]; // Correct: 9
      }
    })();

    const items = shuffle([correctAnswer, ...incorrectAnswers]);

    return { shape, color, items };
  },
  Component: ({ question: { shape, color, items }, translate }) => {
    const edges = shapeInfo3D[shape].edges;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.selectTheNumberOfEdges()}
        pdfTitle={translate.ks1PDFInstructions.tickTheNumberOfEdges()}
        testCorrect={[edges]}
        numItems={4}
        Content={({ dimens }) => (
          <AssetSvg
            name={get3DShapeFullColorsSVGPath(color, shape)}
            width={dimens.width * 0.9}
            height={dimens.height * 0.9}
          />
        )}
        renderItems={items.map(num => {
          return { component: <Text variant="WRN700">{num.toLocaleString()}</Text>, value: num };
        })}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bhZ',
  description: 'bhZ',
  keywords: ['Face', '3-D shape', 'Edges'],
  schema: z
    .object({
      selectedShape: z.enum(nonCurvedShapes3d),
      shapes: z
        .array(
          z.object({
            shape: z.enum(nonCurvedShapes3d),
            rotation: numberEnum([0, 90, 270]),
            scale: numberEnum([1, 0.85])
          })
        )
        .length(4)
        .refine(
          shapes => arrayHasNoDuplicates(shapes, deepEqual),
          'shapes must not have duplicates'
        )
    })
    .refine(
      val => val.shapes.some(shapeObj => shapeObj.shape === val.selectedShape),
      'shapes must include selectedShape.'
    )
    .refine(
      val =>
        val.shapes.filter(
          shape => shapeInfo3D[shape.shape].edges === shapeInfo3D[val.selectedShape].edges
        ).length === 1,
      'Only selectedShape can have the correct number of edges.'
    ),
  simpleGenerator: () => {
    const { selectedShape, otherShapeA, otherShapeB, otherShapeC } = rejectionSample(
      () => {
        const [selectedShape, otherShapeA, otherShapeB, otherShapeC] = getRandomSubArrayFromArray(
          nonCurvedShapes3d,
          4
        );
        return { selectedShape, otherShapeA, otherShapeB, otherShapeC };
      },
      ({ selectedShape, otherShapeA, otherShapeB, otherShapeC }) => {
        return [otherShapeA, otherShapeB, otherShapeC].every(
          shape => shapeInfo3D[shape].edges !== shapeInfo3D[selectedShape].edges
        );
      }
    );

    const shapes = shuffle([
      {
        shape: selectedShape,
        rotation: getRandomFromArray([0, 90, 270] as const),
        scale: getRandomFromArray([1, 0.85] as const)
      },
      {
        shape: otherShapeA,
        rotation: getRandomFromArray([0, 90, 270] as const),
        scale: getRandomFromArray([1, 0.85] as const)
      },
      {
        shape: otherShapeB,
        rotation: getRandomFromArray([0, 90, 270] as const),
        scale: getRandomFromArray([1, 0.85] as const)
      },
      {
        shape: otherShapeC,
        rotation: getRandomFromArray([0, 90, 270] as const),
        scale: getRandomFromArray([1, 0.85] as const)
      }
    ]);

    return { selectedShape, shapes };
  },
  Component: ({ question: { selectedShape, shapes }, translate }) => {
    const edges = shapeInfo3D[selectedShape].edges;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheShapeWithNumEdges(edges)}
        pdfTitle={translate.ks1PDFInstructions.circleTheShapeWithNumEdges(edges)}
        testCorrect={[selectedShape]}
        numItems={4}
        renderItems={({ dimens }) =>
          shapes.map(({ shape, rotation, scale }) => {
            return {
              component: (
                <View style={{ transform: [{ rotate: `${rotation}deg` }, { scale: scale }] }}>
                  <AssetSvg
                    name={get3DShapeFullColorsSVGPath('transparent', shape)}
                    width={
                      rotation === 90 || rotation === 270 ? dimens.height * 0.9 : dimens.width * 0.9
                    }
                    height={
                      rotation === 90 || rotation === 270 ? dimens.width * 0.9 : dimens.height * 0.9
                    }
                  />
                </View>
              ),
              value: shape
            };
          })
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountEdgesOn3DShapes',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
