import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomFromArray, getRandomSubArrayFromArray, shuffle } from 'common/src/utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { AssetSvg } from '../../../../assets/svg';
import { get3DShapeFullColorsSVGPath, shapeInfo3D } from '../../../../utils/threeDShapes';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import ContentBox from '../../../../components/molecules/ContentBox';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

const sphere = 'Sphere' as const;

const nonSphereCurvedShapes3d = ['Cone', 'Cylinder'] as const;

const curvedShapes3d = [sphere, ...nonSphereCurvedShapes3d] as const;

const nonCurvedShapes3d = [
  'Cube',
  'Cuboid',
  'Hexagonal_prism',
  'Pentagonal_prism',
  'Square_pyramid',
  'Triangle_pyramid',
  'Triangular_prism'
] as const;

const shapes3d = [...curvedShapes3d, ...nonCurvedShapes3d] as const;

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhU',
  description: 'bhU',
  keywords: ['3-D shape', 'Faces'],
  schema: z.object({
    shape: z.enum(nonCurvedShapes3d),
    rotation: numberEnum([0, 90, 270])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(nonCurvedShapes3d);

    const rotation = getRandomFromArray([0, 90, 270] as const);

    return { shape, rotation };
  },
  Component: ({ question: { shape, rotation }, translate }) => {
    const faces = shapeInfo3D[shape].faces;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyFacesDoesTheShapeHave()}
        Content={({ dimens }) => (
          <View style={{ transform: [{ rotate: `${rotation}deg` }] }}>
            <AssetSvg
              name={get3DShapeFullColorsSVGPath('transparent', shape)}
              width={rotation === 90 || rotation === 270 ? dimens.height * 0.9 : dimens.width * 0.9}
              height={
                rotation === 90 || rotation === 270 ? dimens.width * 0.9 : dimens.height * 0.9
              }
            />
          </View>
        )}
        sentence="<ans/>"
        testCorrect={[faces.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const q2Colors = ['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'] as const;

const Question2 = newQuestionContent({
  uid: 'bhV',
  description: 'bhV',
  keywords: ['Curved surface', 'Face', '3-D shape'],
  schema: z.object({
    shapes: z
      .array(
        z.object({
          shape: z.enum(shapes3d),
          color: z.enum(q2Colors),
          rotation: numberEnum([0, 90, 270]),
          scale: numberEnum([1, 0.85])
        })
      )
      .length(4)
      .refine(
        shapes => arrayHasNoDuplicates(shapes.map(shape => shape.shape)),
        'All shapes must be different.'
      )
      .refine(
        shapes =>
          shapes.filter(shapeObj => ['Cone', 'Cylinder', 'Sphere'].includes(shapeObj.shape))
            .length >= 1,
        'At least one shape must have a curved surface.'
      )
  }),
  simpleGenerator: () => {
    const curvedShape = getRandomFromArray(curvedShapes3d);

    const otherShapes = getRandomSubArrayFromArray(
      shapes3d.filter(shape => shape !== curvedShape),
      3
    );

    const colors = getRandomSubArrayFromArray(q2Colors, 4);

    const shapes = shuffle([
      {
        shape: curvedShape,
        color: colors[0],
        rotation: getRandomFromArray([0, 90, 270] as const),
        scale: getRandomFromArray([1, 0.85] as const)
      },
      {
        shape: otherShapes[0],
        color: colors[1],
        rotation: getRandomFromArray([0, 90, 270] as const),
        scale: getRandomFromArray([1, 0.85] as const)
      },
      {
        shape: otherShapes[1],
        color: colors[2],
        rotation: getRandomFromArray([0, 90, 270] as const),
        scale: getRandomFromArray([1, 0.85] as const)
      },
      {
        shape: otherShapes[2],
        color: colors[3],
        rotation: getRandomFromArray([0, 90, 270] as const),
        scale: getRandomFromArray([1, 0.85] as const)
      }
    ]);

    return { shapes };
  },
  Component: ({ question: { shapes }, translate }) => {
    const testCorrect = shapes
      .filter(shape => shapeInfo3D[shape.shape].curvedSurface)
      .map(shape => shape.shape);

    return (
      <QF11SelectImagesUpTo4
        title={
          testCorrect.length === 1
            ? translate.ks1Instructions.selectTheShapeWithACurvedSurface()
            : translate.ks1Instructions.selectTheShapesWithACurvedSurface()
        }
        pdfTitle={
          testCorrect.length === 1
            ? translate.ks1PDFInstructions.circleTheShapeWithACurvedSurface()
            : translate.ks1PDFInstructions.circleTheShapesWithACurvedSurface()
        }
        testCorrect={shapes
          .filter(shape => shapeInfo3D[shape.shape].curvedSurface)
          .map(shape => shape.shape)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) =>
          shapes.map(({ shape, color, rotation, scale }) => {
            return {
              component: (
                <View style={{ transform: [{ rotate: `${rotation}deg` }, { scale: scale }] }}>
                  <AssetSvg
                    name={get3DShapeFullColorsSVGPath(color, shape)}
                    width={
                      rotation === 90 || rotation === 270 ? dimens.height * 0.9 : dimens.width * 0.9
                    }
                    height={
                      rotation === 90 || rotation === 270 ? dimens.width * 0.9 : dimens.height * 0.9
                    }
                  />
                </View>
              ),
              value: shape
            };
          })
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const q3Shapes = [...nonSphereCurvedShapes3d, ...nonCurvedShapes3d] as const;

const Question3 = newQuestionContent({
  uid: 'bhW',
  description: 'bhW',
  keywords: ['3-D shape', 'Faces'],
  schema: z
    .object({
      selectedShape: z.enum(q3Shapes),
      shapes: z
        .array(z.enum(q3Shapes))
        .length(4)
        .refine(shapes => arrayHasNoDuplicates(shapes), 'shapes must not have duplicates.')
    })
    .refine(
      val => val.shapes.some(shape => shape === val.selectedShape),
      'shapes must include selectedShape.'
    )
    .refine(
      val =>
        val.selectedShape === 'Cube'
          ? !val.shapes.includes('Cuboid')
          : val.selectedShape === 'Cuboid'
          ? !val.shapes.includes('Cube')
          : true,
      'If selectedShape is Cube, shapes must not include Cuboid; if selectedShape is Cuboid, shapes must not include Cube.'
    ),
  simpleGenerator: () => {
    const selectedShape = getRandomFromArray(q3Shapes);

    const otherShapes = getRandomSubArrayFromArray(
      selectedShape === 'Cube' || selectedShape === 'Cuboid'
        ? q3Shapes.filter(shape => shape !== 'Cube' && shape !== 'Cuboid')
        : q3Shapes.filter(shape => shape !== selectedShape),
      3
    );

    const shapes = shuffle([selectedShape, ...otherShapes]);

    return { selectedShape, shapes };
  },
  Component: ({ question: { selectedShape, shapes }, translate }) => {
    const labelText = (() => {
      switch (selectedShape) {
        case 'Cube':
          return translate.shapes.numberSquareFaces(6);
        case 'Cuboid':
          return translate.shapes.numberRectangularFaces(6);
        case 'Cone':
          return translate.shapes.numberCircularFacesAndNumberCurvedSurfaces(1, 1);
        case 'Cylinder':
          return translate.shapes.numberCircularFacesAndNumberCurvedSurfaces(2, 1);
        case 'Hexagonal_prism':
          return translate.shapes.numberHexagonalFacesAndNumberRectangularFaces(2, 6);
        case 'Pentagonal_prism':
          return translate.shapes.numberPentagonalFacesAndNumberRectangularFaces(2, 5);
        case 'Square_pyramid':
          return translate.shapes.numberSquareFacesAndNumberTriangularFaces(1, 4);
        case 'Triangle_pyramid':
          return translate.shapes.numberTriangularFaces(4);
        case 'Triangular_prism':
          return translate.shapes.numberTriangularFacesAndNumberRectangularFaces(2, 3);
      }
    })();

    const shapeToString = (shape: (typeof q3Shapes)[number]) => {
      switch (shape) {
        case 'Cone':
          return translate.shapes.Cones(1);
        case 'Cylinder':
          return translate.shapes.Cylinders(1);
        case 'Cube':
          return translate.shapes.Cubes(1);
        case 'Cuboid':
          return translate.shapes.Cuboids(1);
        case 'Hexagonal_prism':
          return translate.shapes.HexagonalPrisms(1);
        case 'Pentagonal_prism':
          return translate.shapes.PentagonalPrisms(1);
        case 'Square_pyramid':
          return translate.shapes.SquareBasedPyramids(1);
        case 'Triangle_pyramid':
          return translate.shapes.TriangularBasedPyramids(1);
        case 'Triangular_prism':
          return translate.shapes.TriangularPrisms(1);
      }
    };

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.selectTheNameOfTheShapeDescribedInTheLabel()}
        pdfTitle={translate.ks1PDFInstructions.circleTheNameOfTheShapeDescribedInTheLabel()}
        testCorrect={[selectedShape]}
        numItems={4}
        renderItems={shapes.map(shape => {
          return {
            component: (
              <Text variant="WRN700" style={{ textAlign: 'center' }}>
                {shapeToString(shape)}
              </Text>
            ),
            value: shape
          };
        })}
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={[dimens, { alignItems: 'center', justifyContent: 'center' }]}>
            <ContentBox
              containerStyle={[
                {
                  width: dimens.width / 2.5
                }
              ]}
            >
              <TextStructure sentence={labelText} textStyle={{ textAlign: 'center' }} />
            </ContentBox>
          </View>
        )}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountFacesOn3DShapes',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
