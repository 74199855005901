import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  shuffle
} from 'common/src/utils/random';
import { AssetSvg } from '../../../../assets/svg';
import { get3DShapeFullColorsSVGPath, shapeInfo3D } from '../../../../utils/threeDShapes';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import deepEqual from 'react-fast-compare';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bh3',
  description: 'bh3',
  keywords: ['3-D shape'],
  schema: z
    .object({
      shapeA: z.enum(['Cylinder', 'Cube', 'Cone']),
      shapeB: z.enum(['Cuboid', 'Cylinder', 'Sphere', 'Triangle_pyramid', 'Square_pyramid']),
      oddOneOut: z.enum(['A', 'B']),
      color: z.enum(['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow']),
      itemOrder: z
        .array(z.enum(['A', 'B', 'C', 'D']))
        .length(4)
        .refine(
          val => arrayHasNoDuplicates([val]),
          'itemOrder must contain one each of A, B, C and D.'
        )
    })
    .refine(val => val.shapeA !== val.shapeB, 'shapeA and shapeB cannot be the same.'),
  simpleGenerator: () => {
    const [shapeA, shapeB] = getRandomFromArray([
      ['Cylinder', 'Cuboid'],
      ['Cube', 'Cuboid'],
      ['Cube', 'Cylinder'],
      ['Cylinder', 'Sphere'],
      ['Cone', 'Triangle_pyramid'],
      ['Cone', 'Square_pyramid']
    ] as const);

    const oddOneOut = getRandomFromArray(['A', 'B'] as const);

    const color = getRandomFromArray([
      'blue',
      'green',
      'orange',
      'pink',
      'purple',
      'red',
      'yellow'
    ] as const);

    const itemOrder = shuffle(['A', 'B', 'C', 'D'] as const);

    return { shapeA, shapeB, oddOneOut, color, itemOrder };
  },
  Component: ({ question, translate }) => {
    const { shapeA, shapeB, oddOneOut, color, itemOrder } = question;

    const shapeASvg = get3DShapeFullColorsSVGPath(color, shapeA);

    const shapeBSvg = get3DShapeFullColorsSVGPath(color, shapeB);

    const options = {
      A: {
        svg: oddOneOut === 'A' ? shapeBSvg : shapeASvg
      },
      B: {
        svg: oddOneOut === 'A' ? shapeASvg : shapeBSvg
      },
      C: {
        svg: oddOneOut === 'A' ? shapeASvg : shapeBSvg
      },
      D: {
        svg: oddOneOut === 'A' ? shapeASvg : shapeBSvg
      }
    } as const;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheShapeThatIsTheOddOneOut()}
        pdfTitle={translate.ks1PDFInstructions.circleTheOddOneOut()}
        numItems={4}
        renderItems={({ dimens }) =>
          itemOrder.map(x => ({
            value: x,
            component: (
              <AssetSvg
                name={options[x].svg}
                width={options[x].svg.includes('Cuboid') ? dimens.height * 0.8 : dimens.width * 0.8}
                height={
                  options[x].svg.includes('Cuboid') ? dimens.width * 0.8 : dimens.height * 0.8
                }
                style={options[x].svg.includes('Cuboid') && { transform: 'rotate(270deg)' }}
              />
            )
          }))
        }
        testCorrect={['A']}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const q2And3CurvedShapes = ['Cylinder', 'Sphere', 'Cone'] as const;

const q2And3NonCurvedShapes = [
  'Cube',
  'Cuboid',
  'Square_pyramid',
  'Triangle_pyramid',
  'Triangular_prism',
  'Pentagonal_prism',
  'Hexagonal_prism'
] as const;

const q2And3Shapes = [...q2And3CurvedShapes, ...q2And3NonCurvedShapes] as const;

const q2And3Colors = ['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'] as const;

const Question2 = newQuestionContent({
  uid: 'bh4',
  description: 'bh4',
  keywords: ['Sort', '3-D shapes'],
  schema: z.object({
    condition: z.enum([
      'curved surfaces',
      'odd vertices',
      'odd faces',
      'rectangular faces',
      'triangular faces',
      'circular faces',
      'fewer than 6 faces',
      'fewer than 6 edges'
    ]),
    items: z
      .array(
        z.object({
          shape: z.enum(q2And3Shapes),
          color: z.enum(q2And3Colors),
          scaleX: z.number().min(0.5).max(2).optional(),
          scaleY: z.number().min(0.5).max(2).optional(),
          rotation: z.number().int().min(90).max(270).multipleOf(90).optional()
        })
      )
      .length(9)
      .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
  }),
  simpleGenerator: () => {
    const condition = getRandomFromArray([
      'curved surfaces',
      'odd vertices',
      'odd faces',
      'rectangular faces',
      'triangular faces',
      'circular faces',
      'fewer than 6 faces',
      'fewer than 6 edges'
    ] as const);

    const scalingAndRotationChoices = [
      {},
      { scaleX: 0.7, scaleY: 0.7 },
      { scaleX: 0.85, scaleY: 0.85 },
      { rotation: randomIntegerInclusiveStep(90, 270, 90) }
    ];

    // If the condition asks for odd and even vertices/faces, we cannot allow shapes with curved surfaces to be items:
    const shapeChoices =
      condition === 'odd vertices' || condition === 'odd faces'
        ? q2And3NonCurvedShapes
        : q2And3Shapes;

    const validShapes = shapeChoices.filter(shape => {
      switch (condition) {
        case 'curved surfaces':
          return shapeInfo3D[shape].curvedSurface;
        case 'odd vertices':
          return shapeInfo3D[shape].vertices % 2 !== 0;
        case 'odd faces':
          return shapeInfo3D[shape].faces % 2 !== 0;
        case 'rectangular faces':
          return shapeInfo3D[shape].rectangularFaces;
        case 'triangular faces':
          return shapeInfo3D[shape].triangularFaces;
        case 'circular faces':
          return shapeInfo3D[shape].circularFaces;
        case 'fewer than 6 faces':
          return shapeInfo3D[shape].faces < 6;
        case 'fewer than 6 edges':
          return shapeInfo3D[shape].edges < 6;
        default:
          return false;
      }
    });

    const invalidShapes = shapeChoices.filter(shape => !validShapes.includes(shape));

    // Force at least two shapes to always match the condition:
    const shapeA = {
      shape: getRandomFromArray(validShapes)!,
      color: getRandomFromArray(q2And3Colors),
      ...getRandomFromArray(scalingAndRotationChoices)
    };

    const shapeB = {
      shape: getRandomFromArray(validShapes)!,
      color: getRandomFromArray(q2And3Colors.filter(color => color !== shapeA.color)) as
        | 'blue'
        | 'green'
        | 'orange'
        | 'pink'
        | 'purple'
        | 'red'
        | 'yellow',
      ...getRandomFromArray(scalingAndRotationChoices)
    };

    // Force at least two shapes to never match the condition:
    const shapeC = {
      shape: getRandomFromArray(invalidShapes)!,
      color: getRandomFromArray(q2And3Colors),
      ...getRandomFromArray(scalingAndRotationChoices)
    };

    const shapeD = {
      shape: getRandomFromArray(invalidShapes)!,
      color: getRandomFromArray(q2And3Colors.filter(color => color !== shapeC.color)) as
        | 'blue'
        | 'green'
        | 'orange'
        | 'pink'
        | 'purple'
        | 'red'
        | 'yellow',
      ...getRandomFromArray(scalingAndRotationChoices)
    };

    const shapes = [shapeA, shapeB, shapeC, shapeD];

    const shapeCount = {
      Cube: 0,
      Cuboid: 0,
      Cylinder: 0,
      Cone: 0,
      Sphere: 0,
      Triangle_pyramid: 0,
      Square_pyramid: 0,
      Triangular_prism: 0,
      Pentagonal_prism: 0,
      Hexagonal_prism: 0
    };

    shapeCount[shapeA.shape] += 1;
    shapeCount[shapeB.shape] += 1;
    shapeCount[shapeC.shape] += 1;
    shapeCount[shapeD.shape] += 1;

    // We need 9 shapes in total:
    while (shapes.length < 9) {
      const randomShape = getRandomFromArray(shapeChoices);

      // To ensure all shapes look different, no two shapes of the same type can have the same color:
      const usedColorsForShape = shapes
        .filter(shape => shape.shape === randomShape)
        .map(shape => shape.color); // Extract the colors already used for the current shape

      const availableColors = q2And3Colors.filter(color => !usedColorsForShape.includes(color));

      // Add the shape only if it has been used fewer than 3 times:
      if (shapeCount[randomShape] < 3) {
        shapes.push({
          shape: randomShape,
          color: getRandomFromArray(availableColors)!,
          ...getRandomFromArray(scalingAndRotationChoices)
        });

        // Increment the count for this shape:
        shapeCount[randomShape] += 1;
      }
    }

    const items = shuffle(shapes);

    return { condition, items };
  },
  Component: props => {
    const {
      question: { condition, items },
      translate
    } = props;

    const validShapes = q2And3Shapes.filter(shape => {
      switch (condition) {
        case 'curved surfaces':
          return shapeInfo3D[shape].curvedSurface;
        case 'odd vertices':
          return shapeInfo3D[shape].vertices % 2 !== 0;
        case 'odd faces':
          return shapeInfo3D[shape].faces % 2 !== 0;
        case 'rectangular faces':
          return shapeInfo3D[shape].rectangularFaces;
        case 'triangular faces':
          return shapeInfo3D[shape].triangularFaces;
        case 'circular faces':
          return shapeInfo3D[shape].circularFaces;
        case 'fewer than 6 faces':
          return shapeInfo3D[shape].faces < 6;
        case 'fewer than 6 edges':
          return shapeInfo3D[shape].edges < 6;
        default:
          return false;
      }
    });

    const zoneNames = (() => {
      switch (condition) {
        case 'curved surfaces':
          return [translate.tableHeaders.curvedSurface(), translate.tableHeaders.noCurvedSurface()];
        case 'odd vertices':
          return [
            translate.tableHeaders.oddNumberOfVertices(),
            translate.tableHeaders.evenNumberOfVertices()
          ];
        case 'odd faces':
          return [
            translate.tableHeaders.oddNumberOfFaces(),
            translate.tableHeaders.evenNumberOfFaces()
          ];
        case 'rectangular faces':
          return [
            translate.tableHeaders.rectangularFaces(),
            translate.tableHeaders.noRectangularFaces()
          ];
        case 'triangular faces':
          return [
            translate.tableHeaders.triangularFaces(),
            translate.tableHeaders.noTriangularFaces()
          ];
        case 'circular faces':
          return [translate.tableHeaders.circularFaces(), translate.tableHeaders.noCircularFaces()];
        case 'fewer than 6 faces':
          return [
            translate.tableHeaders.fewerThan6Faces(),
            translate.tableHeaders.sixOrMoreFaces()
          ];
        case 'fewer than 6 edges':
          return [
            translate.tableHeaders.fewerThan6Edges(),
            translate.tableHeaders.sixOrMoreEdges()
          ];
      }
    })();

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragTheCardsToSortTheShapes()}
        pdfTitle={translate.ks1PDFInstructions.drawLinesToSortTheShapesIntoTwoGroups()}
        zoneNames={zoneNames}
        testCorrect={[
          items.filter(shape => validShapes.includes(shape.shape)),
          items.filter(shape => !validShapes.includes(shape.shape))
        ]}
        items={items.map((shape, index) => {
          return {
            value: shape,
            component: (
              <MeasureView
                key={index}
                style={
                  shape.scaleX || shape.scaleY || shape.rotation
                    ? {
                        transform: [
                          ...(shape.scaleX ? [{ scaleX: shape.scaleX }] : []),
                          ...(shape.scaleY ? [{ scaleY: shape.scaleY }] : []),
                          ...(shape.rotation ? [{ rotate: `${shape.rotation}deg` }] : [])
                        ]
                      }
                    : undefined
                }
              >
                {dimens => (
                  <AssetSvg
                    name={get3DShapeFullColorsSVGPath(shape.color, shape.shape)}
                    width={dimens.width * 0.9}
                    height={dimens.height * 0.9}
                  />
                )}
              </MeasureView>
            )
          };
        })}
        pdfItemVariant="pdfSquare"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const q3Conditions = [
  'curved surface',
  'no curved surface',
  'odd vertices',
  'even vertices',
  'odd faces',
  'even faces',
  'rectangular faces',
  'no rectangular faces',
  'triangular faces',
  'no triangular faces',
  'circular faces',
  'no circular faces',
  'fewer than 6 faces',
  '6 or more faces',
  'fewer than 6 edges',
  '6 or more edges'
] as const;

const Question3 = newQuestionContent({
  uid: 'bh5',
  description: 'bh5',
  keywords: ['Sort', '3-D shapes'],
  schema: z.object({
    items: z
      .array(z.enum(q3Conditions))
      .length(4)
      .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates'),
    groupAShapes: z
      .array(
        z.object({
          shape: z.enum(q2And3Shapes),
          color: z.enum(q2And3Colors),
          scaleX: z.number().min(0.5).max(2).optional(),
          scaleY: z.number().min(0.5).max(2).optional(),
          rotation: z.number().int().min(90).max(270).multipleOf(90).optional()
        })
      )
      .refine(
        shapes => arrayHasNoDuplicates(shapes, deepEqual),
        'groupAShapes must not have duplicates'
      ),
    groupBShapes: z
      .array(
        z.object({
          shape: z.enum(q2And3Shapes),
          color: z.enum(q2And3Colors),
          scaleX: z.number().min(0.5).max(2).optional(),
          scaleY: z.number().min(0.5).max(2).optional(),
          rotation: z.number().int().min(90).max(270).multipleOf(90).optional()
        })
      )
      .refine(
        shapes => arrayHasNoDuplicates(shapes, deepEqual),
        'groupBShapes must not have duplicates'
      )
  }),
  simpleGenerator: () => {
    const possibleConditionPairs = [
      ['curved surface', 'no curved surface'],
      ['odd vertices', 'even vertices'],
      ['odd faces', 'even faces'],
      ['rectangular faces', 'no rectangular faces'],
      ['triangular faces', 'no triangular faces'],
      ['circular faces', 'no circular faces'],
      ['fewer than 6 faces', '6 or more faces'],
      ['fewer than 6 edges', '6 or more edges']
    ] as const;

    const [[conditionA, conditionB], [conditionC, conditionD]] = getRandomSubArrayFromArray(
      possibleConditionPairs,
      2
    );

    const groupAAmount = randomIntegerInclusive(3, 6);

    const groupBAmount = randomIntegerInclusive(3, 6);

    const scalingAndRotationChoices = [
      {},
      { scaleX: 0.85, scaleY: 0.85 },
      { rotation: randomIntegerInclusiveStep(90, 270, 90) }
    ];

    // If the condition asks for odd and even vertices/faces, we cannot allow shapes with curved surfaces to be items:
    const shapeChoices =
      conditionA === 'odd vertices' || conditionA === 'odd faces'
        ? q2And3NonCurvedShapes
        : q2And3Shapes;

    const groupAShapeChoices = shapeChoices.filter(shape => {
      switch (conditionA) {
        case 'curved surface':
          return shapeInfo3D[shape].curvedSurface;
        case 'odd vertices':
          return shapeInfo3D[shape].vertices % 2 !== 0;
        case 'odd faces':
          return shapeInfo3D[shape].faces % 2 !== 0;
        case 'rectangular faces':
          return shapeInfo3D[shape].rectangularFaces;
        case 'triangular faces':
          return shapeInfo3D[shape].triangularFaces;
        case 'circular faces':
          return shapeInfo3D[shape].circularFaces;
        case 'fewer than 6 faces':
          return shapeInfo3D[shape].faces < 6;
        case 'fewer than 6 edges':
          return shapeInfo3D[shape].edges < 6;
        default:
          return false;
      }
    });

    const items = shuffle([conditionA, conditionB, conditionC, conditionD]);

    const groupBShapeChoices = shapeChoices.filter(shape => !groupAShapeChoices.includes(shape));

    const groupAShapesArray: {
      shape: (typeof q2And3Shapes)[number];
      color: (typeof q2And3Colors)[number];
      scaleX?: number;
      scaleY?: number;
      rotation?: number;
    }[] = [];

    while (groupAShapesArray.length < groupAAmount) {
      const randomShape = getRandomFromArray(groupAShapeChoices)!;

      // To ensure all shapes look different, no two shapes of the same type can have the same color:
      const usedColorsForShape = groupAShapesArray
        .filter(shape => shape.shape === randomShape)
        .map(shape => shape.color); // Extract the colors already used for the current shape

      const availableColors = q2And3Colors.filter(color => !usedColorsForShape.includes(color));

      groupAShapesArray.push({
        shape: randomShape,
        color: getRandomFromArray(availableColors)!,
        ...getRandomFromArray(scalingAndRotationChoices)
      });
    }

    const groupAShapes = shuffle(groupAShapesArray);

    const groupBShapesArray: {
      shape: (typeof q2And3Shapes)[number];
      color: (typeof q2And3Colors)[number];
      scaleX?: number;
      scaleY?: number;
      rotation?: number;
    }[] = [];

    while (groupBShapesArray.length < groupBAmount) {
      const randomShape = getRandomFromArray(groupBShapeChoices)!;

      // To ensure all shapes look different, no two shapes of the same type can have the same color:
      const usedColorsForShape = groupBShapesArray
        .filter(shape => shape.shape === randomShape)
        .map(shape => shape.color); // Extract the colors already used for the current shape

      const availableColors = q2And3Colors.filter(color => !usedColorsForShape.includes(color));

      groupBShapesArray.push({
        shape: randomShape,
        color: getRandomFromArray(availableColors)!,
        ...getRandomFromArray(scalingAndRotationChoices)
      });
    }

    const groupBShapes = shuffle(groupBShapesArray);

    return { items, groupAShapes, groupBShapes };
  },
  Component: props => {
    const {
      question: { items, groupAShapes, groupBShapes },
      translate,
      displayMode
    } = props;

    const conditionToString = (condition: (typeof q3Conditions)[number]) => {
      switch (condition) {
        case 'curved surface':
          return translate.tableHeaders.curvedSurface();
        case 'no curved surface':
          return translate.tableHeaders.noCurvedSurface();
        case 'odd vertices':
          return translate.tableHeaders.oddNumberOfVertices();
        case 'even vertices':
          return translate.tableHeaders.evenNumberOfVertices();
        case 'odd faces':
          return translate.tableHeaders.oddNumberOfFaces();
        case 'even faces':
          return translate.tableHeaders.evenNumberOfFaces();
        case 'rectangular faces':
          return translate.tableHeaders.rectangularFaces();
        case 'no rectangular faces':
          return translate.tableHeaders.noRectangularFaces();
        case 'triangular faces':
          return translate.tableHeaders.triangularFaces();
        case 'no triangular faces':
          return translate.tableHeaders.noTriangularFaces();
        case 'circular faces':
          return translate.tableHeaders.circularFaces();
        case 'no circular faces':
          return translate.tableHeaders.noCircularFaces();
        case 'fewer than 6 faces':
          return translate.tableHeaders.fewerThan6Faces();
        case '6 or more faces':
          return translate.tableHeaders.sixOrMoreFaces();
        case 'fewer than 6 edges':
          return translate.tableHeaders.fewerThan6Edges();
        case '6 or more edges':
          return translate.tableHeaders.sixOrMoreEdges();
      }
    };

    // Potentially there could be multiple possible correct answers for each group of shapes,
    // so we need to check every shape in each group against the answer the user selects:
    const testCorrect = (
      condition: (typeof q3Conditions)[number] | undefined,
      groupOfShapes: {
        shape: (typeof q2And3Shapes)[number];
        color: (typeof q2And3Colors)[number];
        scaleX?: number;
        scaleY?: number;
        rotation?: number;
      }[]
    ) => {
      switch (condition) {
        case 'curved surface':
          return groupOfShapes.every(({ shape }) => shapeInfo3D[shape].curvedSurface);
        case 'no curved surface':
          return groupOfShapes.every(({ shape }) => !shapeInfo3D[shape].curvedSurface);
        case 'odd vertices':
          return groupOfShapes.every(({ shape }) => shapeInfo3D[shape].vertices % 2 !== 0);
        case 'even vertices':
          return groupOfShapes.every(({ shape }) => shapeInfo3D[shape].vertices % 2 === 0);
        case 'odd faces':
          return groupOfShapes.every(({ shape }) => shapeInfo3D[shape].faces % 2 !== 0);
        case 'even faces':
          return groupOfShapes.every(({ shape }) => shapeInfo3D[shape].faces % 2 === 0);
        case 'rectangular faces':
          return groupOfShapes.every(({ shape }) => shapeInfo3D[shape].rectangularFaces);
        case 'no rectangular faces':
          return groupOfShapes.every(({ shape }) => !shapeInfo3D[shape].rectangularFaces);
        case 'triangular faces':
          return groupOfShapes.every(({ shape }) => shapeInfo3D[shape].triangularFaces);
        case 'no triangular faces':
          return groupOfShapes.every(({ shape }) => !shapeInfo3D[shape].triangularFaces);
        case 'circular faces':
          return groupOfShapes.every(({ shape }) => shapeInfo3D[shape].circularFaces);
        case 'no circular faces':
          return groupOfShapes.every(({ shape }) => !shapeInfo3D[shape].circularFaces);
        case 'fewer than 6 faces':
          return groupOfShapes.every(({ shape }) => shapeInfo3D[shape].faces < 6);
        case '6 or more faces':
          return groupOfShapes.every(({ shape }) => shapeInfo3D[shape].faces >= 6);
        case 'fewer than 6 edges':
          return groupOfShapes.every(({ shape }) => shapeInfo3D[shape].edges < 6);
        case '6 or more edges':
          return groupOfShapes.every(({ shape }) => shapeInfo3D[shape].edges >= 6);
        default:
          return false;
      }
    };

    const conditionA = items.find(item =>
      testCorrect(item, groupAShapes)
    ) as (typeof q3Conditions)[number];

    const conditionB = items.find(item =>
      testCorrect(item, groupBShapes)
    ) as (typeof q3Conditions)[number];

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragTheCardsToLabelTheGroups()}
        pdfTitle={translate.ks1PDFInstructions.writeHowTheShapesHaveBeenSortedChooseFromTheseCards()}
        items={items.map(item => {
          return { component: conditionToString(item), value: item };
        })}
        sentence="<ans/> <ans/>"
        sentenceStyle={{ justifyContent: 'space-evenly', width: '100%' }}
        mainPanelStyle={{ flexDirection: 'column-reverse' }} // Needed to have the dropzones above the content, not below.
        testCorrect={userAnswer =>
          testCorrect(userAnswer[0], groupAShapes) && testCorrect(userAnswer[1], groupBShapes)
        }
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        itemMaxLines={displayMode === 'digital' ? 1 : 2}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-around' }}>
            <View
              style={{
                borderWidth: 2,
                borderColor: 'black',
                borderRadius: 12,
                width: dimens.width * 0.45,
                height: dimens.height,
                justifyContent: 'center'
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  rowGap: 4
                }}
              >
                {groupAShapes.map((shape, index) => (
                  <View
                    key={index}
                    style={[
                      { justifyContent: 'center', alignItems: 'center' },
                      shape.scaleX || shape.scaleY || shape.rotation
                        ? {
                            transform: [
                              ...(shape.scaleX ? [{ scaleX: shape.scaleX }] : []),
                              ...(shape.scaleY ? [{ scaleY: shape.scaleY }] : []),
                              ...(shape.rotation ? [{ rotate: `${shape.rotation}deg` }] : [])
                            ]
                          }
                        : undefined
                    ]}
                  >
                    <AssetSvg
                      name={get3DShapeFullColorsSVGPath(shape.color, shape.shape)}
                      width={dimens.width * 0.14}
                      height={dimens.width * 0.14}
                    />
                  </View>
                ))}
              </View>
            </View>
            <View
              style={{
                borderWidth: 2,
                borderColor: 'black',
                borderRadius: 12,
                width: dimens.width * 0.45,
                height: dimens.height,
                justifyContent: 'center'
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  rowGap: 4
                }}
              >
                {groupBShapes.map((shape, index) => (
                  <View
                    key={index}
                    style={[
                      { justifyContent: 'center', alignItems: 'center' },
                      shape.scaleX || shape.scaleY || shape.rotation
                        ? {
                            transform: [
                              ...(shape.scaleX ? [{ scaleX: shape.scaleX }] : []),
                              ...(shape.scaleY ? [{ scaleY: shape.scaleY }] : []),
                              ...(shape.rotation ? [{ rotate: `${shape.rotation}deg` }] : [])
                            ]
                          }
                        : undefined
                    ]}
                  >
                    <AssetSvg
                      name={get3DShapeFullColorsSVGPath(shape.color, shape.shape)}
                      width={dimens.width * 0.14}
                      height={dimens.width * 0.14}
                    />
                  </View>
                ))}
              </View>
            </View>
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [[conditionA, conditionB]],
          answerText: translate.markScheme.acceptAnyOtherCorrectConditionsThatMatchEachGroup()
        }}
        questionHeight={1400}
      />
    );
  },
  questionHeight: 1400
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Sort3DShapes',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
