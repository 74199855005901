import { GREATER_THAN, LESS_THAN, MULT } from '../../constants';

export default {
  aPatternGoesXYThenItRepeatsDrawTheShapes:
    'A pattern goes: {0:string|lower}, {1:string|lower}, then it repeats.<br/>Draw the shapes to make the pattern.',
  aPatternGoesXYZThenItRepeatsDrawTheShapes:
    'A pattern goes: {0:string|lower}, {1:string|lower}, {2:string|lower}, then it repeats.<br/>Draw the shapes to make the pattern.',
  canEachChildHave1AppleTickYourAnswer: 'Can each child have 1 apple?<br/>Tick your answer.',
  canEachChildHave1CakeTickYourAnswer: 'Can each child have 1 cake?<br/>Tick your answer.',
  canEachChildHave1CookieTickYourAnswer: 'Can each child have 1 cookie?<br/>Tick your answer.',
  canEachChildHave1PieceOfFruitTickYourAnswer:
    'Can each child have 1 piece of fruit?<br/>Tick your answer.',
  canEachDogHave1BallTickYourAnswer: 'Can each dog have 1 ball?<br/>Tick your answer.',
  canEachDogHave1BoneTickYourAnswer: 'Can each dog have 1 bone?<br/>Tick your answer.',
  canEachHorseHave1BaleOfHayTickYourAnswer:
    'Can each horse have 1 bale of hay?<br/>Tick your answer.',
  canEachHorseHave1CarrotTickYourAnswer: 'Can each horse have 1 carrot?<br/>Tick your answer.',
  canEachSheepHave1BaleOfHayTickYourAnswer:
    'Can each sheep have 1 bale of hay?<br/>Tick your answer.',
  charHasPoundsXHeSheBuysAComicForYMoneyTickTheBoxThatShowsTheChangeCharGets:
    '{0} has £{1:number|numberFormat}<br/>{2} buys a comic for {3}.<br/>Tick the box that shows the change {0} gets.',
  characterIsCountingBackwardsFromXToYTickTheNumbersCharacterWillSay:
    '{character} is counting backwards from {numberA:number|numberFormat} to {numberB:number|numberFormat}<br/>Tick the numbers {character} will say.',
  characterIsCountingForwardsFromXToYTickTheNumbersCharacterWillSay:
    '{character} is counting forwards from {numberA:number|numberFormat} to {numberB:number|numberFormat}<br/>Tick the numbers {character} will say.',
  characterIsCountingForwardsInXFromYTickNumbers:
    '{0} is counting forwards in {1}s from {2}<br/>Tick the numbers that {0} will say.',
  characterIsCountingBackwardsInXFromYTickNumbers:
    '{0} is counting backwards in {1}s from {2}<br/>Tick the numbers that {0} will say.',
  circleAllOfTheShapesWithLessThanNumberVertices:
    'Circle all of the shapes with less than {0:number|numberFormat} vertices.',
  circleAllOfTheShapesWhatIsTheNamesOfTheShapesThatAreLeft:
    'Circle all of the {0:string|lower}.<br/>What is the name of the shapes that are left?',
  circleAllTheEvenNumbers: 'Circle all the even numbers.',
  circleAllTheOddNumbers: 'Circle all the odd numbers.',
  circleTheCoinsToMakeXP: 'Circle the coins to make {0:number|numberFormat}p.',
  circleTheCoinsToMakePoundX: 'Circle the coins to make £{0: number}',
  circleTheDiagramsThatShowAHalf: 'Circle the diagram{{s}} that show a half.',
  circleTheDiagramsThatShowAQuarter: 'Circle the diagram{{s}} that show a quarter.',
  circleGreaterThanNumberAsWord: 'Circle a number that is greater than {0: string|lower}.',
  circleGreaterThanNumberAsNumber: 'Circle a number that is greater than {0: number}',
  circleLessThanNumberAsWord: 'Circle a number that is less than {0: string|lower}.',
  circleLessThanNumberAsNumber: 'Circle a number that is less than {0: number}',
  circleNumberAsWord: 'Circle {0: string|lower}.',
  circleNumberObject: 'Circle {0:number|numberFormat} {1:string|lower}.',
  circleOneLessThanNumberAsWord: 'Circle one less than {0: string|lower}.',
  circle1LessThanNumberAsNumber: 'Circle 1 less than {0: number}',
  circleOneMoreThanNumberAsWord: 'Circle one more than {0: string|lower}.',
  circle1MoreThanNumberAsNumber: 'Circle 1 more than {0: number}',
  circleTheGroupThatHasAXShaded: 'Circle the group that has a {0:string|lower} shaded.',
  circleTheGroupsThatHaveAXShaded: 'Circle the groups that have a {0:string|lower} shaded.',
  circleTheGroupThatShowsX: 'Circle the group that shows {0:string|lower}.',
  circleTheGroupsThatShowsX: 'Circle the groups that shows {0:string|lower}.',
  circleTheNameOfTheShapeDescribedInTheLabel:
    'Circle the name of the shape described in the label.',
  circleTheNumber1LessThanNum: 'Circle the number 1 less than {numberA:number|numberFormat}',
  circleTheNumber1MoreThanNum: 'Circle the number 1 more than {numberA:number|numberFormat}',
  circleTheNumberNum: 'Circle the number {numberA:number|numberFormat}',
  circleTheOddOneOut: 'Circle the odd one out.',
  circleTheShapeWithACurvedSurface: 'Circle the shape with a curved surface.',
  circleTheShapeWithNumEdges: 'Circle the shape with {0:number|numberFormat} edges.',
  circleTheShapeWithNumVertices: 'Circle the shape with {0:number|numberFormat} vertices.',
  circleTheShapesThatRoll: 'Circle the shapes that roll.',
  circleTheShapesWithACurvedSurface: 'Circle the shapes with a curved surface.',
  circleTheX: 'Circle the {0:string|lower}.',
  circleTheXThatAreCutInHalf: 'Circle the {0:string|lower} that are cut in half.',
  circleTheXThatIsCutInHalf: 'Circle the {0:string|lower} that is cut in half.',
  circleTheXThatAreCutIntoYEqualParts:
    'Circle the {0:string|lower} that are cut into {1:number|numberFormat} equal parts.',
  circleTheXThatIsCutIntoYEqualParts:
    'Circle the {0:string|lower} that is cut into {1:number|numberFormat} equal parts.',
  circleXLessThanY: 'Circle {0:number|numberFormat} less than {1:number|numberFormat}',
  circleXMoreThanY: 'Circle {0:number|numberFormat} more than {1:number|numberFormat}',
  circleXPoundsAndYPence: 'Circle £{0:number|numberFormat} and {1:number|numberFormat}p.',
  colourXCircles: 'Colour {0:number|numberFormat} circle{{s}}.',
  colourXSquares: 'Colour {0:number|numberFormat} square{{s}}.',
  completePictogram: 'Complete the pictogram.',
  completeTallyChart: 'Complete the tally chart.',
  completeTheBlockDiagram: 'Complete the block diagram.',
  completeTheNumberLine: 'Complete the number line.',
  completeTheSentence: 'Complete the sentence.',
  completeTheString: 'Complete the {0:string|lower}.',
  crossOutToShowTheBirdsThatFlewAway: 'Cross out to show the birds that flew away.',
  crossOutToShowCarsThatDriveAway: 'Cross out to show the cars that drive away.',
  crossOutToShowTheXFoodObjectThatWereEaten:
    'Cross out to show the {0:string|lower} that were eaten.',
  draw1AppleForEachChildIntoTheBox: 'Draw 1 apple for each child into the box.',
  draw1MoreThanNum: 'Draw 1 more than {0:number|numberFormat}',
  draw1LessThanNum: 'Draw 1 less than {0:number|numberFormat}',
  drawACircleAboveTheShape: 'Draw a circle <b>above</b> the {0:string|lower}.',
  drawACircleBelowTheShape: 'Draw a circle <b>below</b> the {0:string|lower}.',
  drawACircleBetweenTheShapeAAndTheShapeB:
    'Draw a circle <b>between</b> the {0:string|lower} and the {1:string|lower}.',
  drawACircleDyTheShape: 'Draw a circle <b>{0:string|lower}</b> the {1:string|lower}.',
  drawACircleToTheDxTheShape: 'Draw a circle to the <b>{0:string|lower}</b> the {1:string|lower}.',
  drawAClosedShapeWithNumVertices: 'Draw a closed shape with {0:number|numberFormat} vertices.',
  drawALineToShowWhereTheWaterReaches: 'Draw a line to show where the water reaches.',
  drawAnArrayToShowXMultY: `Draw an array to show {0:number|numberFormat} ${MULT} {1:number|numberFormat}`,
  drawAnArrowToShowWhereNumBelongsOnTheNumberLine:
    'Draw an arrow to show where {0:number|numberFormat} belongs on the number line.',
  drawArrowEstimateNumberIs: 'Draw an arrow to estimate where {0} is.',
  drawArrowEstimateNumberIsOnNumberLine:
    'Draw an arrow to estimate where {0} is on the number line.',
  drawAnArrowToEstimateWhereNumBelongsOnTheNumberLine:
    'Draw an arrow to estimate where {0:number|numberFormat} belongs on the number line.',
  drawArrowToShowTheMassOfXG: 'Draw an arrow to show the mass of {0:number|numberFormat} g.',
  drawArrowToShowTheMassOfXKg: 'Draw an arrow to show the mass of {0:number|numberFormat} kg.',
  drawArrowToShowTheTotalMass: 'Draw an arrow to show the total mass.',
  drawBase10ToShowTheNumberX: 'Draw base 10 to show the number {0:number|numberFormat}',
  drawBase10ToShowX: 'Draw base 10 to show {0}',
  drawCountersToAddXMore: 'Draw counters to add {0:number|numberFormat} more.',
  drawCountersToMakeXEqualGroupsOfY:
    'Draw counters to make {0:number|numberFormat} equal groups of {1:number|numberFormat}',
  drawCountersToShowX: 'Draw counters to show {0}',
  drawFourCrossesToSplitTheShapeIntoQuarters: 'Draw 4 crosses to split the shape into quarters.',
  drawLineThatIsXCmLong: 'Draw a line that is {0:number|numberFormat} cm long.',
  drawTwoCrossesToSplitTheShapeInHalf: 'Draw 2 crosses to split the shape in half.',
  drawCountersToMakeX: 'Draw counters to make {0}',
  drawBase10ToShowXMoreThanY:
    'Draw base 10 to make {0:number|numberFormat} more than {1:number|numberFormat}',
  drawBase10ToMakeXLessThanY:
    'Draw base 10 to make {0:number|numberFormat} less than {1:number|numberFormat}',
  drawBase10ToShowXLess:
    'Draw base 10 to show {0:number|numberFormat} less than {1:number|numberFormat}',
  drawBase10ToShowXMore:
    'Draw base 10 to show {0:number|numberFormat} more than {1:number|numberFormat}',
  drawHandsOnClockToShowX: 'Draw hands on the clock to show {0}',
  drawLinesToSortTheObjectsIntoTwoGroups: 'Draw lines to sort the objects into two groups.',
  drawLinesToSortTheShapesIntoTwoGroups: 'Draw lines to sort the shapes into two groups.',
  drawNumCounters: 'Draw {0:string|lower} counter{{1:s}}.',
  drawNumXForEachY: 'Draw {0:number|numberFormat} {1:string|lower} for each {2:string|lower}.',
  drawTheNextTwoShapesToCompleteThePattern: 'Draw the next two shapes to complete the pattern.',
  drawX: 'Draw {0:string|lower}.',
  estimateTheNumberArrowPointingToTickAnswer:
    'Estimate the number the arrow is pointing to.<br/>Tick your answer.',
  hereAreSomeNumberBondsToX: 'Here are some number bonds to {0:number|numberFormat}',
  howMuchDoesCharHaveLeftTickYourAnswer: 'How much does {0} have left?<br/>Tick your answer.',
  howManyXAreThereTickYourAnswer: 'How many {0} are there?<br/>Tick your answer.',
  matchTheAdditionsToMultiplications: 'Match the additions to the multiplications.',
  matchTheAmounts: 'Match the amounts.',
  matchTheCorrectAnswersToTheCalculations: 'Match the correct answers to the calculations.',
  matchTheCoinsToTheCorrectValues: 'Match the coins to the correct values.',
  matchTheDoublesToAdditions: 'Match the doubles to the additions.',
  matchTheNotesToTheCorrectValues: 'Match the notes to the correct values.',
  matchTheNumbersToTheBaseTen: 'Match the numbers to the base 10',
  matchTheNumeralsToTheWords: 'Match the numerals to the words',
  matchTheNumbersToTheTallies: 'Match the numbers to the tallies.',
  matchThePicturesToTheStatements: 'Match the pictures to the statements.',
  matchTheShapesToTheTurn: 'Match the shapes to the turn.',
  matchTheTalliesToTheNumbers: 'Match the tallies to the numbers.',
  matchTheTenFramesToTheNumberBonds: 'Match the ten frames to the number bonds.',
  matchTheXObjectToTheYObject: 'Match the {0:string|lower} to the {1:string|lower}.',
  matchTheTenFramesToTheSubtractions: 'Match the ten frames to the subtractions.',
  matchTheTimesToTheClocks: 'Match the times to the clocks.',
  measureTheLengthOfTheXObjectUsingARuler:
    'Measure the length of the {0:string|lower} using a ruler.',
  name1HasNumCountersName2HasFewerCountersThanName1DrawCountersToShowHowManyCountersName1CouldHave:
    '{name1} has {numberA:number|numberFormat} counters.<br/>{name2} has fewer counters than {name1}.</br>Draw counters to show how many counters {name2} could have.',
  orderGlassesStartWithTheGreatestAmountVolume:
    'Order the glasses.<br/>Start with the greatest volume.',
  orderGlassesStartWithTheSmallestAmountVolume:
    'Order the glasses.<br/>Start with the smallest volume.',
  orderObjectsStartWithGreatestCapacity: 'Order the objects.<br/>Start with the greatest capacity.',
  orderObjectsStartWithSmallestCapacity: 'Order the objects.<br/>Start with the smallest capacity.',
  orderTheNumbersFromSmallestToGreatest: 'Order the numbers from smallest to greatest.',
  orderTheNumbersFromGreatestToSmallest: 'Order the numbers from greatest to smallest.',
  nameAHasNCountersNameBHasFewerCountersThanNameADrawCounters:
    '{nameA} has {numA:number|numberFormat} counter{{s}}.<br/>{nameB} has fewer counters than {nameA}.<br/>Draw counters to show how many counters {nameB} <b>could</b> have.',
  nameAHasNCountersNameBHasMoreCountersThanNameADrawCounters:
    '{nameA} has {numA:number|numberFormat} counter{{s}}.<br/>{nameB} has more counters than {nameA}.<br/>Draw counters to show how many counters {nameB} <b>could</b> have.',
  shadeAQuarterOfTheCircles: 'Shade a quarter of the circles.',
  shadeHalfOfTheCircles: 'Shade half of the circles.',
  shadeTheCirclesToMakeArrayOfXRowsAndYColumns:
    'Shade the circles to make an array with {0:number|numberFormat} rows and {1:number|numberFormat} columns.',
  shareNumCountersEquallyIntoNumBoxes:
    'Share {0:number|numberFormat} counters equally into {1:number|numberFormat} boxes.',
  shadeTheCountersToShowTheMissingBondInThePattern:
    'Shade the counters to show the missing bond in the pattern.',
  sortTheActivitiesIntoTheTable: 'Sort the activities into the table.',
  sortTheCardsIntoTheTable: 'Sort the cards into the table.',
  sortTheFruitIntoGroups: 'Sort the fruit into groups.',
  sortTheNumbersIntoTheTable: 'Sort the numbers into the table.',
  sortTheObjectsIntoGroups: 'Sort the objects into groups.',
  sortTheObjectsIntoTwoGroups: 'Sort the objects into two groups.',
  sortTheShapesInOrderOfTheNumberOfSidesStartWithTheFewestNumberOfSides:
    'Sort the shapes in order of the number of sides.<br/>Start with the fewest number of sides.',
  sortTheShapesInOrderOfTheNumberOfSidesStartWithTheMostNumberOfSides:
    'Sort the shapes in order of the number of sides.<br/>Start with the most number of sides.',
  sortTheShapesInOrderOfTheNumberOfVerticesStartWithTheFewestNumberOfVertices:
    'Sort the shapes in order of the number of vertices.<br/>Start with the fewest number of vertices.',
  sortTheShapesInOrderOfTheNumberOfVerticesStartWithTheMostNumberOfVertices:
    'Sort the shapes in order of the number of vertices.<br/>Start with the most number of vertices.',
  theCapacityOfOneJugIsXCupsOfSandTickTheCapacityOfTwoJugs:
    'The capacity of 1 jug is {0:number|numberFormat} cups of sand. Tick the capacity of 2 jugs.',
  theDaysAreInOrderTickTheMissingDayOfTheWeek:
    'The days are in order.<br/>Tick the missing day of the week.',
  theDiagramShowsHalfAShapeAndALineOfSymmetryCompleteTheShape:
    'The diagram shows half a shape and a line of symmetry.<br/>Complete the shape.',
  theMassOfTheXObjectIsYAmountThanZCubesTickThePictureThatShowsThis:
    'The mass of the {0:string|lower} is {1:string|lower} {2:number|numberFormat} cube{{s}}.<br/>Tick the picture that shows this.',
  theNumbersAreAllInXTimesTableTickNumbersAlsoInYTimesTable:
    'The numbers are all in the {0:number|numberFormat} times-table.<br/>Tick the numbers that are also in the {1:number|numberFormat} times-table.',
  theItemMovesXSpacesDyDrawACrossOnTheTrack:
    'The {0:string|lower} moves {1:number|numberFormat} space{{s}} <b>{2:string|lower}</b>.<br/>Draw a cross to show where the {0:string|lower} is now.',
  theItemMovesXSpacesToTheDxDrawItOnTheTrack:
    'The {0:string|lower} moves {1:number|numberFormat} space{{s}} to the <b>{2:string|lower}</b>.<br/>Where is the {0:string|lower} now?<br/>Draw it on the track.',
  theItemMovesYSpacesDyAndXSpacesDxDrawACrossOnTheTrack:
    'The {item:string|lower} moves {dy:number|numberFormat} space{{s}} <b>{yDirection:string|lower}</b> and {dx:number|numberFormat} space{{s}} <b>{xDirection:string|lower}</b>.<br/>Draw a cross to show where the {item:string|lower} is now.',
  theToyCarIsHeavierThenXCubesButLighterThanYCubesTickAllTheMassesThatTheCarCouldBe:
    'The toy car is heavier than {0:number|numberFormat} cubes, but lighter than {1:number|numberFormat} cubes.<br/>Tick all the masses that the car could be.',
  theTimeIsNumOClockDrawHandsOnTheClockToShowTimeInNumHours:
    "The time is {0} o'clock.<br/>Draw hands on the clock to show what time it will be in {1:number|numberFormat} hour{{s}}.",
  theXContainerIsYAmountDrawHowMuchJuiceCouldBeInTheXContainer:
    'The {0:string|lower} is {1:string|lower}.<br/>Draw how much juice could be in the {0:string|lower}.',
  theXObjectIsYWeightThanTheZObjectTickThePicture:
    'The {0:string|lower} is {1:string|lower} than the {2:string|lower}.<br/>Tick the picture that shows this.',
  threeChildrenRunARaceTickTheChildWhoComesFirst:
    'Three children run a race.<br/>Tick the child who comes first.',
  tickAllTheNumberBondsToNum: 'Tick <b>all</b> the number bonds to {0:number|numberFormat}',
  tickACardToCompleteTheComparison: 'Tick a card to complete the comparison.',
  tickDayOnWhichFewestXSold: 'Tick the day on which the <b>fewest</b> {0:string|lower} were sold.',
  tickDayOnWhichMostXSold: 'Tick the day on which the <b>most</b> {0:string|lower} were sold.',
  tickHowManyChildrenHaveNumPets: 'Tick how many children have {0:number|numberFormat} pet{{s}}.',
  tickHowManyFewerCarsAreXThanY:
    'Tick how many <b>fewer</b> cars are {0:string|lower} than {1:string|lower}.',
  tickHowManyMoreCarsAreXThanY:
    'Tick how many <b>more</b> cars are {0:string|lower} than {1:string|lower}.',
  tickTheAdditionsThatAreEqualToNum: 'Tick the additions that are equal to {0:number|numberFormat}',
  tickTheAdditionThatMatchTheBarModel: 'Tick the addition that matches the bar model.',
  tickTheAdditionThatMatchTheCounters: 'Tick the addition that matches the counters.',
  tickTheAdditionsThatMatchTheBarModel: 'Tick the additions that match the bar model.',
  tickTheAdditionsThatMatchTheCounters: 'Tick the additions that match the counters.',
  tickTheBase10ThatShowsX: 'Tick the base 10 that shows {0}',
  tickTheBoxesThatDoNotShowPoundXAndYPence:
    'Tick the boxes that <b> do not</b> show £{0:number|numberFormat} and {1:number|numberFormat}p.',
  tickTheBoxThatShowsPoundXAndYPence:
    'Tick the box that does shows £{0:number|numberFormat} and {1:number|numberFormat}p.',
  tickTheBoxesThatShowTheSameAmount: 'Tick the boxes that show the same amount.',
  tickTheBoxesThatShowX: 'Tick the boxes that show {0:number|numberFormat}',
  tickTheBoxThatShowsX: 'Tick the box that shows {0:number|numberFormat}',
  tickTheContainerWithTheGreatestCapacity: 'Tick the container with the greatest capacity.',
  tickTheContainerWithTheSmallestCapacity: 'Tick the container with the smallest capacity.',
  tickTheCorrectAnswer: 'Tick the correct answer.',
  tickTheCorrectNumberSentence: 'Tick the correct number sentence.',
  tickTheCorrectNumberSentences: 'Tick the correct number sentences.',
  tickTheGreatestAmountOfMoney: 'Tick the greatest amount of money.',
  tickTheGreatestNumber: 'Tick the greatest number.',
  tickTheGroupThatMatchesTheNumberOfChildren: 'Tick the group that matches the number of children.',
  tickTheGroupWithFewerObject: 'Tick the group with fewer {0:string|lower}.',
  tickTheGroupWithMoreObject: 'Tick the group with more {0:string|lower}.',
  tickTheHeavierObject: 'Tick the heavier object.',
  tickTheLighterObject: 'Tick the lighter object.',
  tickTheLongerObject: 'Tick the longer object.',
  tickTheLongerXObject: 'Tick the longer {0:string|lower}.',
  tickTheLongestPencil: 'Tick the longest pencil.',
  tickTheLongestRibbon: 'Tick the longest ribbon.',
  tickTheLongestTrain: 'Tick the longest train.',
  tickTheMissingNumberBondToX: 'Tick the <b>missing</b> number bond to {0:number|numberFormat}',
  tickTheNameOfTheShape: 'Tick the name of the shape.',
  tickTheNumberBondsToNum: 'Tick the number bonds to {0:number|numberFormat}',
  tickTheNumberOfEdges: 'Tick the number of edges.',
  tickTheNumberSentenceThatMatchesPicture: 'Tick the number sentence that matches the picture.',
  tickTheOrdinalShape: 'Tick the {0:string|lower} shape.',
  tickThePopularPetLeast: 'Tick the least popular pet.',
  tickThePopularPetMost: 'Tick the most popular pet.',
  tickThePictureThatShowsEqualGroups: 'Tick the picture that shows <b>equal</b> groups.',
  tickThePictureThatShowsUnequalGroups: 'Tick the picture that shows <b>unequal</b> groups.',
  tickThePictureThatShowsXEqualGroupsOfY:
    'Tick the picture that shows {0:number|numberFormat} equal groups of {1:number|numberFormat}',
  tickThePicturesThatShowEqualGroups: 'Tick the pictures that show <b>equal</b> groups.',
  tickThePicturesThatShowUnequalGroups: 'Tick the pictures that show <b>unequal</b> groups.',
  tickTheSetsOfCountersThatCanBeSharedIntoTwoEqualGroups:
    'Tick the sets of counters that can be shared into 2 equal groups.',
  tickTheShortestFlower: 'Tick the shortest flower.',
  tickTheShorterObject: 'Tick the shorter object.',
  tickTheShorterXObject: 'Tick the shorter {0:string|lower}.',
  tickTheShortestPencil: 'Tick the shortest pencil.',
  tickTheShortestRibbon: 'Tick the shortest ribbon.',
  tickTheShortestTowerOfCubes: 'Tick the shortest tower of cubes.',
  tickTheShortestTrain: 'Tick the shortest train.',
  tickTheShortestTree: 'Tick the shortest tree.',
  tickTheSmallestAmountOfMoney: 'Tick the smallest amount of money.',
  tickTheSmallestNumber: 'Tick the smallest number.',
  tickTheStatementsThatAreTrue: 'Tick the statements that are true.',
  tickTheStatementsThatIsTrue: 'Tick the statement that is true.',
  tickTheTallerObject: 'Tick the taller object.',
  tickTheTallerXObject: 'Tick the taller {0:string|lower}.',
  tickTheTallestFlower: 'Tick the tallest flower.',
  tickTheTallestTowerOfCubes: 'Tick the tallest tower of cubes.',
  tickTheTallestTree: 'Tick the tallest tree.',
  tickTheXWeightObject: 'Tick the {0:string|lower} object.',
  tickTheMonthsThatHaveExactlyXDays:
    'Tick the months that have exactly {0:number|numberFormat} days.',
  tickTheShapesThatFitThePattern: 'Tick the shapes that fit the pattern.',
  tickTheTimeShown: 'Tick the time shown.',
  tickTheX: 'Tick the {0:string|lower}.',
  tickTheXObjectThereIsFewestOf: 'Tick the {0:string|lower} there is the fewest of.',
  tickTheXObjectThereIsMostOf: 'Tick the {0:string|lower} there is the most of.',
  tickWhatDayOfTheWeekTheXDateIsOn: 'Tick what day of the week the {0:string|lower} is on.',
  tickWhatTheShapeWillLookLikeAfterAXTurn:
    'Tick what the shape will look like after a <b>{0:string|lower}</b> turn.',
  tickWhatTheShapeWillLookLikeAfterAXTurnAntiClockwise:
    'Tick what the shape will look like after a <b>{0:string|lower}</b> turn anti-clockwise',
  tickWhatTheShapeWillLookLikeAfterAXTurnClockwise:
    'Tick what the shape will look like after a <b>{0:string|lower}</b> turn clockwise.',
  tickWhatTheShapeWillLookLikeAfterAFullTurn:
    'Tick what the shape will look like after a <b>full</b> turn.',
  tickWhatTheShapeWillLookLikeAfterAHalfTurn:
    'Tick what the shape will look like after a <b>half</b> turn.',
  tickWhatTheShapeWillLookLikeAfterAQuarterTurn:
    'Tick what the shape will look like after a <b>quarter</b> turn.',
  tickWhatTheShapeWillLookLikeAfterAThreeQuarterTurn:
    'Tick what the shape will look like after a <b>three-quarter</b> turn.',
  tickYourAnswer: 'Tick your answer.',
  useACardToCompleteTheNumberSentence: 'Use a card to complete the number sentence.',
  useACardToCompleteSentence: 'Use a card to complete the sentence.',
  useCardsCompleteAddition: 'Use the cards to complete the addition.',
  useCardsCompleteMultiplication: 'Use the cards to complete the multiplication.',
  useCardsCompleteSentence: 'Use the cards to complete the sentence.',
  useCardsCompleteSentences: 'Use the cards to complete the sentences.',
  useCardsToCompleteNumberSentence: 'Use the cards to complete the number sentence.',
  useCardsToCompleteTheCalculations: 'Use the cards to complete the calculations.',
  useTheCardsToCompleteTheCount: 'Use the cards to complete the count.',
  useTheCardsToCompleteTheNumberBondToX:
    'Use the cards to complete the number bond to {0:number|numberFormat}',
  useTheCardsToCompleteTheMultiplication: 'Use the cards to complete the multiplication.',
  useTheCardsToCompleteTheNumberSentence: 'Use the cards to complete the number sentence.',
  useTheCardsToCompleteTheNumberSentences: 'Use the cards to complete the number sentences.',
  useTheCardsToCompleteTheSentence: 'Use the cards to complete the sentence.',
  useTheCardsToCompleteTheSentences: 'Use the cards to complete the sentences.',
  useTheCardsToDescribeTheMovement: 'Use the cards to describe the movement.',
  useCardsToCompleteTheNumberTrack: 'Use the cards to complete the number track.',
  useTheCardsToMakeA2DigitEvenNumber: 'Use the cards to make a 2-digit <b>even</b> number.',
  useTheCardsToMakeA2DigitOddNumber: 'Use the cards to make a 2-digit <b>odd</b> number.',
  useTheCardsToMakeTheAdditionCorrect: 'Use the cards to make the addition correct.',
  useTheCardsToOrderTheNumbers: 'Use the cards to order the numbers.',
  useTheCardsToOrderTheHeights: 'Use the cards to order the heights.',
  useTheCardsToOrderTheLengths: 'Use the cards to order the lengths.',
  useTheCardsToPutThemInOrder: 'Use the cards to put them in order.',
  useTheCardsToSortTheShapes: 'Use the cards to sort the shapes.',
  useTheWordsToCompleteTheSentence: 'Use the words to complete the sentence.',
  whatIsTheNumberAsANumeralTickYourAnswer: 'What is the word as a numeral?<br/>Tick your answer.',
  whatIsTheNumeralAsANumberTickYourAnswer: 'What is the numeral as a word?<br/>Tick your answer.',
  whatIsTheWholeWriteTheNumber: 'What is the whole?<br/>Write the number.',
  whatIsXTickCorrectAnswer: 'What is {0}?<br/>Tick the correct answer.',
  whatSubtractionIsShownOnTheNumberLineUseTheCardsToCompleteTheNumberSentence:
    'What subtraction is shown on the number line?<br/>Use the cards to complete the number sentence.',
  whichTenFrameMatchesThePictureSelectYourAnswer:
    'Which ten frame matches the picture?<br/>Tick your answer.',
  writeHowTheShapesHaveBeenSortedChooseFromTheseCards:
    'Write how the shapes have been sorted.<br/>Choose from these cards.',
  writeLessThanGreaterThanOrEqualSymbolsToCompareTheCapacityOfTheContainers: `Write ${LESS_THAN}, ${GREATER_THAN} or = to compare the capacity of the containers.`,
  writeLessThanGreaterThanOrEqualSymbolsToCompare: `Write ${LESS_THAN}, ${GREATER_THAN} or = to compare.`,
  writeLessThanGreaterThanOrEqualSymbolsToCompareTheAmounts: `Write ${LESS_THAN}, ${GREATER_THAN} or = to compare the amounts.`,
  writeLessThanGreaterThanOrEqualSymbolsToCompareTheNumbers: `Write ${LESS_THAN}, ${GREATER_THAN} or = to compare the numbers.`,
  writeLessThanGreaterThanOrEqualSymbolsToCompareTheObjects: `Write ${LESS_THAN}, ${GREATER_THAN} or = to compare the objects.`,
  writeLessThanGreaterThanOrEqualSymbolsToCompareTheVolumes: `Write ${LESS_THAN}, ${GREATER_THAN} or = to compare the volumes.`,
  writeLessThanGreaterThanOrEqualSymbolsToMakeTheStatementCorrect: `Write ${LESS_THAN}, ${GREATER_THAN} or = to make the statement correct.`,
  writeMissingNumber: 'Write the missing number.',
  writeTheMissingPhrase: 'Write the missing phrase.',
  writeTheNameOfTheShape: 'Write the name of the shape.',
  writeTheNameOfTheShapeToCompleteTheSentence:
    'Write the name of the shape to complete the sentence.',
  writeNumbersInOrder: 'Write the numbers in order.',
  writeTwoDifferentMultiplicationsToMatchArray:
    'Write two different multiplications to match the array.',
  writeWhetherTheGroupsAreEqualOrUnequal: 'Write whether the groups are equal or unequal.',
  xContainerHasYMoreOrLessJuiceThanZContainerDrawHowMuchJuiceCouldBeInXContainer:
    '{0:string} {1:string} has {2:string|lower} juice than {0:string|lower} {3:string}.<br/>Draw how much juice could be in {0:string|lower} {3:string}.'
};
