import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { type ComponentType } from 'react';

type SchoolInfo = {
  code: string;
  name: string;
  hasInfinityPlus: boolean;
};

type AuthTokens = {
  token: string;
  refreshToken: string;
};

type Profile = {
  firstName: string;
  lastName: string;
  studentId: number;
};

type LoggedInUser = {
  authTokens: AuthTokens;
  profile: Profile;
};

type State = {
  school: SchoolInfo | undefined;
  loggedInUser: LoggedInUser | undefined;
  lastActive: number | undefined;
};

type Actions = {
  setSchool: (school: SchoolInfo) => void;
  clearSchool: () => void;
  setLoggedInUser: (loggedInUser: LoggedInUser) => void;
  clearLoggedInUser: () => void;
  setActivity: () => void;
  clear: () => void;
};

const defaultState: State = {
  school: undefined,
  loggedInUser: undefined,
  lastActive: undefined
};

const useLoginStore = create<State & Actions>()(
  devtools(
    persist(
      set => ({
        ...defaultState,
        setSchool: (school: SchoolInfo) => set({ school }),
        clearSchool: () => set({ school: undefined }),
        setLoggedInUser: (loggedInUser: LoggedInUser) => {
          set({ loggedInUser, lastActive: Date.now() });
        },
        clearLoggedInUser: () => set({ lastActive: undefined, loggedInUser: undefined }),
        setActivity: () => set({ lastActive: Date.now() }),
        clear: () => set(defaultState)
      }),
      {
        name: 'login',
        storage: createJSONStorage(() => AsyncStorage)
      }
    )
  )
);

export default useLoginStore;

/** HOC which returns null when not logged in, so the wrapped component is only rendered when logged in. */
export function withLoggedIn<Props extends Record<string, unknown>>(
  Component: ComponentType<Props>
) {
  return (props: Props) => {
    const loggedInUser = useLoginStore(state => state.loggedInUser);

    if (!loggedInUser) {
      // Just clicked log out, so we're about to navigate away. Fine to show empty screen
      return null;
    }

    return <Component {...props} />;
  };
}
