import { z } from 'zod';
import { getRequestQueryWithAuth, isHttpSuccess } from './requests';
import Logger from '../utils/logger';
import { useCallback, useRef, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { Expand } from 'common/src/utils/types';

/** Schema for a Student Profile, according to the API. */
const studentProfileApiSchema = z.object({
  stars: z.number().int().min(0)
});

type StudentProfileApiEntity = Expand<z.infer<typeof studentProfileApiSchema>>;

/**
 * Hook for getting the latest student data when the screen is focused. Returns an object with different properties
 * in depending on the status (loading, success, error).
 */
export function useStudentData(studentId: number):
  | {
      status: 'loading';
      /** When loading new data, the old data might be here. */
      studentData?: StudentProfileApiEntity;
      errorString?: undefined;
    }
  | {
      status: 'error';
      studentData?: undefined;
      errorString: 'network error' | 'http error' | 'unknown error' | 'invalid response';
    }
  | { status: 'success'; studentData: StudentProfileApiEntity; errorString?: undefined } {
  const [error, setError] = useState<
    'network error' | 'http error' | 'unknown error' | 'invalid response' | undefined
  >(undefined);
  const [data, setData] = useState<
    { studentData: StudentProfileApiEntity; studentId: number } | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(false);

  // Make the request whenever we regain focus
  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (loadingRef.current) {
          // Already loading, just abort silently
          // Note: we use a ref for this, because this callback must not depend on the reactive variable
          // `loading`, or else it would be called in an infinite loop.
          return;
        }
        setLoading(true);
        loadingRef.current = true;
        const result = await getStudentData(studentId);
        setLoading(false);
        loadingRef.current = false;

        if (typeof result === 'string') {
          setError(result);
          setData(undefined);
        } else {
          setError(undefined);
          setData({ studentData: result, studentId });
        }
      })();
    }, [studentId])
  );

  if (loading) {
    return {
      status: 'loading',
      studentData: data?.studentId === studentId ? data.studentData : undefined
    };
  } else if (error) {
    return { status: 'error', errorString: error };
  } else if (data && data.studentId === studentId) {
    return { status: 'success', studentData: data.studentData };
  } else {
    // Shouldn't happen, but pretend we're loading
    return { status: 'loading' };
  }
}

/**
 * Get the current logged-in student's profile.
 *
 * Note that you need to provide the class's ID for that student, even though any other ID would result in a 403.
 *
 * Note: for an easy-to-use hook, see {@link useStudentData}.
 */
export const getStudentData = async (
  studentId: number
): Promise<
  StudentProfileApiEntity | 'network error' | 'http error' | 'unknown error' | 'invalid response'
> => {
  const logTag = 'getStudentData' as const;
  const studentProfileEndpoint = `/web/infinity/student/${studentId}`;
  const result = await getRequestQueryWithAuth(studentProfileEndpoint, 'json');

  if (!isHttpSuccess(result)) {
    // Error - return a string
    switch (result.errorKind) {
      case 'network':
        Logger.captureEvent('error', logTag, 'NETWORK_ERROR', { eventData: result });
        return 'network error';
      case 'http':
      case 'loggedOut':
        Logger.captureEvent('error', logTag, 'HTTP_ERROR', { eventData: result });
        return 'http error';
      case 'unknown':
        Logger.captureEvent('error', logTag, 'UNKNOWN_ERROR', { eventData: result });
        return 'unknown error';
      default:
        Logger.captureEvent('fatal', logTag, 'UNKNOWN_ERROR', {
          additionalMsg: `Logic error: Unreachable (${result satisfies never})`
        });
        // Produces TS error and throws runtime error if we missed a case
        throw new Error(`Logic error: unreachable (${result satisfies never})`);
    }
  }
  const data = result.response.data;

  // Success - Validate the response
  const parseResults = studentProfileApiSchema.safeParse(data);
  if (!parseResults.success) {
    // Response JSON was not in the form we expected
    Logger.captureEvent('error', logTag, 'PARSE_ERROR', { eventData: parseResults });
    return 'invalid response';
  }

  // Validation success
  return parseResults.data;
};
