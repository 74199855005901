import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { base10ObjectToNumber, numberToBase10Object } from '../../../../utils/math';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import { View } from 'react-native';
import { countRange, filledArray } from '../../../../utils/collections';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { Dimens } from '../../../../theme/scaling';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'be3',
  description: 'be3',
  keywords: ['Tens', 'Ones'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(21)
      .max(99)
      .refine(number => number % 10 !== 0, 'Number cannot be a multiple of 10'),
    variation: z.enum(['tenFrame', 'Cubes', 'Straws', 'rekenrek']),
    isBase10Jumbled: z.boolean()
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const variation = getRandomFromArray(['Cubes', 'Straws', 'tenFrame', 'rekenrek'] as const);
    // Can only fit 6 tenFrames on screen vertically
    const number = randomIntegerInclusive(21, variation === 'tenFrame' ? 59 : 99, {
      constraint: x => x % 10 !== 0
    });

    const isBase10Jumbled = getRandomFromArrayWithWeights([false, true], [3, 1]);

    return { number, variation, isBase10Jumbled };
  },
  Component: props => {
    const {
      question: { number, variation, isBase10Jumbled },
      translate
    } = props;

    const tens = Math.floor(number / 10);
    const ones = number % 10;

    const getBase10Representations = (variation: 'Cubes' | 'Straws', dimens: Dimens) => {
      if (isBase10Jumbled) {
        const scale = BaseTenRepCalcGridsAndScale(
          dimens.width,
          dimens.height,
          { ones, tens },
          variation
        ).scale;

        const total = tens + ones > 14 ? 14 : tens + ones;

        const onesBase = countRange(ones).map((_, index) => (
          <BaseTenRepresentation
            key={`ones_${index}`}
            b10Rep={{
              variant: variation,
              numbers: { ones: 1 },
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width / total}
            usableHeight={dimens.height * 0.9}
            scale={isInRange(0.38, 0.4)(scale) ? scale / 1.8 : scale}
          />
        ));

        const tensBase = countRange(tens).map((_, index) => (
          <BaseTenRepresentation
            key={`tens${index}`}
            b10Rep={{
              variant: variation,
              numbers: { tens: 1 },
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width / total}
            usableHeight={dimens.height * 0.9}
            scale={isInRange(0.38, 0.4)(scale) ? scale / 1.8 : scale}
          />
        ));

        const reps = shuffle([...onesBase, ...tensBase], { random: seededRandom(props.question) });

        return (
          <View>
            {reps.length >= 12 ? (
              <View>
                <View
                  style={{
                    flexDirection: 'row',
                    height: dimens.height / 2,
                    columnGap: 16
                  }}
                >
                  {countRange(Math.min(12, reps.length)).map((_, index) => {
                    return reps[index];
                  })}
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    height: dimens.height / 2,
                    columnGap: 16
                  }}
                >
                  {countRange(Math.max(0, reps.length - 12)).map((_, index) => {
                    return reps[index + 12];
                  })}
                </View>
              </View>
            ) : (
              <View
                style={{
                  flexDirection: 'row',
                  height: reps.length > 12 ? dimens.height / 2 : dimens.height,
                  columnGap: 16
                }}
              >
                {countRange(Math.min(12, reps.length)).map((_, index) => {
                  return reps[index];
                })}
              </View>
            )}
          </View>
        );
      } else
        return (
          <BaseTenRepresentation
            b10Rep={{
              variant: variation,
              numbers: number === 100 ? { tens: 10 } : numberToBase10Object(number),
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        );
    };

    return (
      <QF1ContentAndSentence
        questionHeight={800}
        pdfDirection="column"
        actionPanelVariant="endWide"
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={translate.ks1AnswerSentences.numHasAnsTensAndAnsOnes(number, tens, ones)}
        testCorrect={[tens.toString(), ones.toString()]}
        Content={({ dimens }) => {
          return variation === 'Cubes' || variation === 'Straws' ? (
            getBase10Representations(variation, dimens)
          ) : variation === 'rekenrek' ? (
            <Rekenrek dimens={dimens} rows={10} numberShown={number} />
          ) : (
            <View style={{ flexDirection: 'row', gap: 20 }}>
              <View style={{ flexDirection: 'row', gap: 20 }}>
                {shuffle(
                  countRange(Math.ceil(number / 10)).map(i => (
                    <TenFrameLayout
                      items={filledArray('red', i === Math.floor(number / 10) ? ones : 10)}
                      orientation="vertical"
                      size="xsmall"
                      key={i}
                    />
                  )),
                  { random: seededRandom(props.question) }
                )}
              </View>
            </View>
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'be4',
  description: 'be4',
  keywords: ['Partition', 'Part-whole', 'Tens', 'Ones'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(2).max(9),
    unitOfAnswer: z.enum(['ones', 'tens']),
    isTensLHS: z.boolean()
  }),
  simpleGenerator: () => {
    const unitOfAnswer = getRandomFromArray(['ones', 'tens'] as const);
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(2, 9);
    const isTensLHS = getRandomFromArrayWithWeights([true, false], [4, 1]);

    return { ones, tens, unitOfAnswer, isTensLHS };
  },
  Component: props => {
    const {
      question: { ones, tens, unitOfAnswer, isTensLHS },
      translate
    } = props;

    const number = base10ObjectToNumber({ ones, tens });

    let partition: (number | '$ans')[];
    let correctAnswer: number;

    if (unitOfAnswer === 'tens') {
      partition = isTensLHS ? ['$ans', ones] : [ones, '$ans'];
      correctAnswer = tens * 10;
    } else {
      partition = isTensLHS ? [tens * 10, '$ans'] : ['$ans', tens * 10];
      correctAnswer = ones;
    }

    return (
      <QF3InteractiveContent
        title={translate.ks1Instructions.completeThePartWholeModel()}
        inputType="numpad"
        initialState={['']}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            top={number}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        customMarkSchemeAnswer={{ answersToDisplay: [correctAnswer.toLocaleString()] }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'be5',
  description: 'be5',
  keywords: ['Tens', 'Ones'],
  schema: z.object({
    tens: z.number().int().min(5).max(9),
    ones: z.number().int().min(0).max(9),
    sentenceLayout: z.enum(['ansTensOnes', 'ansOnesTens', 'numAnsTensAnsOnes', 'numAnsOnesAnsTens'])
  }),
  simpleGenerator: () => {
    const tens = randomIntegerInclusive(5, 9);
    const ones = randomIntegerInclusive(0, 9);
    const sentenceLayout = getRandomFromArrayWithWeights(
      ['ansTensOnes', 'ansOnesTens', 'numAnsTensAnsOnes', 'numAnsOnesAnsTens'] as const,
      [3, 3, 2, 2]
    );
    return { tens, ones, sentenceLayout };
  },

  Component: props => {
    const {
      question: { tens, ones, sentenceLayout },
      translate
    } = props;

    const number = base10ObjectToNumber({ ones, tens });

    const [answer, sentence] = (() => {
      switch (sentenceLayout) {
        case 'ansOnesTens':
          return [[number], translate.ks1AnswerSentences.ansHasNumTensAndNumOnes(tens, ones)];
        case 'ansTensOnes':
          return [[number], translate.ks1AnswerSentences.ansHasNumOnesAndNumTens(ones, tens)];
        case 'numAnsOnesAnsTens':
          return [
            [ones, tens],
            translate.ks1AnswerSentences.numHasAnsOnesAndAnsTens(number, ones, tens)
          ];
        case 'numAnsTensAnsOnes':
          return [
            [tens, ones],
            translate.ks1AnswerSentences.numHasAnsTensAndAnsOnes(number, tens, ones)
          ];
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={sentence}
        testCorrect={answer.map(ans => ans.toString())}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PartitionIntoTensAndOnes',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
