import axios, {
  type AxiosRequestConfig,
  type ResponseType,
  type AxiosError,
  type AxiosResponse
} from 'axios';
import api from './axiosConfig';
import useLoginStore from '../storage/useLoginStore';
import { TokenState, parseAndDecodeTokens } from '../utils/parseAndDecodeTokens';

const getHeaders = (accessToken?: string) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/ld+json; charset=utf-8'
  };

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  return headers;
};

const getRequestInfo = (accessToken?: string, responseType = 'json' as ResponseType) => {
  const requestInfo: AxiosRequestConfig = {
    headers: getHeaders(accessToken),
    responseType
  };

  return requestInfo;
};

/** Error types that affect all HTTP requests */
type HttpError =
  | { errorKind: 'unknown'; error: unknown }
  | { errorKind: 'network'; error: AxiosError<unknown> }
  | {
      errorKind: 'http';
      error: AxiosError<unknown>;
      response: AxiosResponse<unknown>;
    };

/** Error types that only affect HTTP requests with auth */
type HttpAuthError = {
  errorKind: 'loggedOut';
};

/** Internal error type that should be handled within this file: access token expired */
type HttpExpiredToken = {
  errorKind: 'expiredToken';
  error: AxiosError<unknown>;
  response: AxiosResponse<unknown>;
};

/** Successful response from a HTTP request. `errorKind` prop _must_ be absent or undefined. */
export type HttpSuccess = { errorKind?: undefined; response: AxiosResponse<unknown> };

/** Check whether a Http result is an success. (Otherwise it's an error success.) */
export const isHttpSuccess = (
  result: HttpSuccess | HttpError | HttpAuthError | HttpExpiredToken
): result is HttpSuccess => result.errorKind === undefined;

/** Simply wrap a success response in a {@link HttpSuccess} */
const wrapResponse = (response: AxiosResponse<unknown>): HttpSuccess => ({
  response
});

/** Handle a request error by converting it to our custom error object */
const handleRequestError = (error: unknown): HttpError | HttpExpiredToken => {
  if (error === null || !axios.isAxiosError(error)) {
    return { errorKind: 'unknown', error };
  } else if (
    error.response &&
    error.response.status === 401 &&
    error.response.data.message === 'Expired JWT Token'
  ) {
    // Auth token has expired
    return { errorKind: 'expiredToken', error, response: error.response };
  } else if (error.response !== undefined) {
    // We got a HTTP response, but it was an error
    return { errorKind: 'http', error, response: error.response };
  } else if (error.code === 'ERR_NETWORK') {
    // Network error
    return { errorKind: 'network', error };
  } else {
    // TODO: Should we handle the others error codes explicitly, rather than just "unknown"? I think the full list is:
    // ERR_FR_TOO_MANY_REDIRECTS, ERR_BAD_OPTION_VALUE, ERR_BAD_OPTION, ERR_NETWORK, ERR_DEPRECATED, ERR_BAD_RESPONSE,
    // ERR_BAD_REQUEST, ERR_CANCELED, ECONNABORTED, ETIMEDOUT
    return { errorKind: 'unknown', error };
  }
};

/** Refresh the user's access token. */
const refreshAuthAccessToken = async (refreshToken: string): Promise<TokenState | 'failed'> => {
  const response = await api
    .post(
      '/token/refresh',
      {
        refresh_token: refreshToken
      },
      getRequestInfo()
    )
    .then(wrapResponse)
    .catch(handleRequestError);

  if (!isHttpSuccess(response)) {
    // we do not need to know why just that it failed
    return 'failed';
  }

  const tokenState = parseAndDecodeTokens(response.response.data);

  if (typeof tokenState !== 'string') {
    return tokenState;
  } else {
    // we do not need to know why just that it failed
    return 'failed';
  }
};

/**
 * Make a request, with _requiring_ authentication.
 *
 * If the access token has expired, try once to refresh it using the refresh token. If this fails, log out immediately.
 */
export const requestWithAuth = async (
  method: 'GET' | 'PUT' | 'POST' | 'DELETE',
  apiEndpoint: string,
  responseType = 'json' as ResponseType,
  data?: unknown
): Promise<HttpSuccess | HttpError | HttpAuthError> => {
  const config = {
    method,
    url: apiEndpoint,
    responseType: responseType,
    data
  };
  const { loggedInUser, clearLoggedInUser, setLoggedInUser } = useLoginStore.getState();

  if (!loggedInUser) {
    // Requested to use auth, but wasn't logged in. Don't bother making the request.
    return { errorKind: 'loggedOut' };
  }

  // Try first with the user's current access token
  const firstAttemptResponse: HttpSuccess | HttpError | HttpExpiredToken = await api
    .request({
      ...getRequestInfo(loggedInUser.authTokens.token),
      ...config
    })
    .then(wrapResponse)
    .catch(handleRequestError);

  if (firstAttemptResponse.errorKind !== 'expiredToken') {
    // First attempt didn't fail due to an expired token, so just return that.
    // Note: it may have failed for any assortment of other reasons, but this function only attempts to recover
    // automatically from expired access tokens.
    return firstAttemptResponse;
  }

  // First attempt failed. Recover from expired token errors by trying once to refresh the token.
  const refreshResponse = await refreshAuthAccessToken(loggedInUser.authTokens.refreshToken);

  if (typeof refreshResponse === 'string') {
    // Failed to refresh the token, for whatever reason. Log out.
    // note: when the logged in user is cleared, the LogoutWrapper handles this and navigates home
    clearLoggedInUser();
    return { errorKind: 'loggedOut' };
  }

  // Token refresh succeeded! Write the new tokens to the store,
  setLoggedInUser({
    authTokens: {
      token: refreshResponse.token,
      refreshToken: refreshResponse.refreshToken
    },
    profile: {
      firstName: refreshResponse.firstName,
      lastName: refreshResponse.lastName,
      studentId: refreshResponse.studentId
    }
  });

  // Retry the original call with the new access token
  const secondAttemptResponse = await api
    .request({
      ...getRequestInfo(refreshResponse.token),
      ...config
    })
    .then(wrapResponse)
    .catch(handleRequestError);

  if (secondAttemptResponse.errorKind === 'expiredToken') {
    // Token was still expired after the refresh was successful.
    // Something very weird has happened. Log out just to be safe.
    console.error('Recently refreshed access token is still expired');
    clearLoggedInUser();
    return { errorKind: 'loggedOut' };
  }

  return secondAttemptResponse;
};

/**
 * Make a request, without _requiring_ authentication.
 *
 * If we have a logged in user, attempt to use authentication anyway. But if this fails, fall back to not using
 * authentication.
 */
export const request = async (
  method: 'GET' | 'PUT' | 'POST' | 'DELETE',
  apiEndpoint: string,
  responseType = 'json' as ResponseType,
  data?: unknown
): Promise<HttpSuccess | HttpError> => {
  const config = {
    method,
    url: apiEndpoint,
    responseType: responseType,
    data
  };

  const { loggedInUser: currentLoggedInUser } = useLoginStore.getState();
  let response: HttpSuccess | HttpError | HttpAuthError | HttpExpiredToken | undefined;

  if (currentLoggedInUser) {
    // If we have credentials, may as well use auth anyway
    response = await requestWithAuth(method, apiEndpoint, responseType, data);
  }

  if (!response || response.errorKind === 'loggedOut') {
    // Fall back to not using auth
    response = await api
      .request({
        ...getRequestInfo(),
        ...config
      })
      .then(wrapResponse)
      .catch(handleRequestError);
  }

  if (response.errorKind === 'expiredToken') {
    // We shouldn't have to worry about an expired token if we didn't use auth. If it does somehow happen, just
    // treat it as an unknown error.
    console.error('Expired token error when making request without auth');
    return { errorKind: 'unknown', error: response.error };
  }

  return response;
};

/** DELETE request to an endpoint which doesn't require authentication. (Auth may be used anyway, if logged in.) */
export const deleteRequestQuery = async (apiEndpoint: string): Promise<HttpSuccess | HttpError> => {
  return request('DELETE', apiEndpoint, undefined);
};

/** DELETE request to an endpoint which requires authentication with a logged in user. */
export const deleteRequestQueryWithAuth = async (
  apiEndpoint: string
): Promise<HttpSuccess | HttpError | HttpAuthError> => {
  return requestWithAuth('DELETE', apiEndpoint, undefined);
};

/** GET request to an endpoint which doesn't require authentication. (Auth may be used anyway, if logged in.) */
export const getRequestQuery = async (
  apiEndpoint: string,
  responseType = 'json' as ResponseType
): Promise<HttpSuccess | HttpError> => {
  return request('GET', apiEndpoint, responseType);
};

/** GET request to an endpoint which requires authentication with a logged in user. */
export const getRequestQueryWithAuth = async (
  apiEndpoint: string,
  responseType = 'json' as ResponseType
): Promise<HttpSuccess | HttpError | HttpAuthError> => {
  return requestWithAuth('GET', apiEndpoint, responseType);
};

/** POST request to an endpoint which doesn't require authentication. (Auth may be used anyway, if logged in.) */
export const postRequestQuery = async (
  apiEndpoint: string,
  data: unknown
): Promise<HttpSuccess | HttpError> => {
  return request('POST', apiEndpoint, undefined, data);
};

/** POST request to an endpoint which requires authentication with a logged in user. */
export const postRequestQueryWithAuth = async (
  apiEndpoint: string,
  data: unknown
): Promise<HttpSuccess | HttpError | HttpAuthError> => {
  return requestWithAuth('POST', apiEndpoint, undefined, data);
};

/** PUT request to an endpoint which doesn't require authentication. (Auth may be used anyway, if logged in.) */
export const putRequestQuery = async (
  apiEndpoint: string,
  data: unknown
): Promise<HttpSuccess | HttpError> => {
  return request('PUT', apiEndpoint, undefined, data);
};

/** PUT request to an endpoint which requires authentication with a logged in user. */
export const putRequestQueryWithAuth = async (
  apiEndpoint: string,
  data: unknown
): Promise<HttpSuccess | HttpError | HttpAuthError> => {
  return requestWithAuth('PUT', apiEndpoint, undefined, data);
};
