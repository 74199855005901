import BaseLayout from 'common/src/components/molecules/BaseLayout';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { arraysHaveSameContents } from 'common/src/utils/collections';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { SelectRandomShapesWithState } from '../representations/SelectRandomShapes';

type Shape = 'Circle' | 'Square' | 'Rectangle' | 'Triangle' | 'RATriangle';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  /**
   * Title at the top of the question for PDF
   */
  pdfTitle?: string;
  /**
   * Array of shape objects that will render, with optional props rotation and scale - If no rotation or scale is passed it will be defaulted to 0 rotation and 1 scale
   */
  shapesArray: { shape: Shape; rotation?: number; scale?: number }[];
  /**
   * Array of shape names
   */
  testCorrect: Shape[];
  /**
   * optional prop to allow a comment to be passed on mark scheme
   */
  customMarkSchemeAnswer?: { answerText?: string };
  /** PDF Question Height */
  questionHeight?: number;
  /**
   * Array of boolean arrays to map the shapes to.
   */
  arrangement: boolean[][];
  /**
   * optional prop to show shape circled on mark scheme
   */
  pdfCircleAnswer?: boolean;
};

export default function QF67SelectingRandomlyArrangedShapes({
  title,
  pdfTitle,
  testCorrect,
  customMarkSchemeAnswer,
  questionHeight,
  shapesArray,
  arrangement,
  pdfCircleAnswer,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  // Set defaults to shapes if not passed
  const items = shapesArray.map(({ shape, rotation, scale }) => {
    return { shape, rotation: rotation ?? 0, scale: scale ?? 1 };
  });

  let count = 0;

  // Map shape to arrangement
  const arrangementWithShapes = arrangement.map(row => {
    return row.map(isTrue => {
      if (isTrue) {
        const item = items[count];
        count++;
        return item;
      }

      return isTrue;
    });
  });

  const filterSelectedCols = (array: { shape?: Shape; value: boolean }[][]) => {
    return array.map(row => row.filter(col => col.value === true)).filter(col => col.length > 0);
  };

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const correctShape = testCorrect[0];

    const amountOfCorrectAnswers = testCorrect.length;
    let correctAnswerCount = 0;

    const defaultState =
      displayMode === 'pdf'
        ? arrangementWithShapes.map(row => row.map(() => ({ value: false })))
        : arrangementWithShapes.map(row =>
            row.map(cell => {
              if (cell) {
                while (correctAnswerCount < amountOfCorrectAnswers) {
                  const value =
                    cell.shape === 'Triangle' && correctShape === 'RATriangle'
                      ? true
                      : cell.shape === 'RATriangle' && correctShape === 'Triangle'
                      ? true
                      : cell.shape === correctShape
                      ? true
                      : false;

                  if (value) correctAnswerCount++;

                  return {
                    shape: cell.shape,
                    value: value
                  };
                }
              }
              return { value: false };
            })
          );

    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <MeasureView>
            {dimens => {
              return (
                <>
                  <SelectRandomShapesWithState
                    id="array"
                    testComplete={answer => filterSelectedCols(answer).length > 0}
                    arrangement={arrangementWithShapes}
                    dimens={dimens}
                    shapesArray={shapesArray}
                    defaultState={defaultState}
                    pdfCircleAnswer={pdfCircleAnswer}
                  />

                  {displayMode === 'markscheme' &&
                    customMarkSchemeAnswer?.answerText &&
                    renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
                </>
              );
            }}
          </MeasureView>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <SelectRandomShapesWithState
              id="array"
              testComplete={answer => filterSelectedCols(answer).length > 0}
              testCorrect={userAnswer => {
                const filteredAnswers = userAnswer
                  .flat()
                  .filter(({ value }) => value)
                  .map(({ shape }) => (shape === 'RATriangle' ? 'Triangle' : shape));

                return arraysHaveSameContents(filteredAnswers, testCorrect);
              }}
              arrangement={arrangementWithShapes}
              dimens={dimens}
              shapesArray={shapesArray}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
